<div *ngIf="visible" class="overTheTop" [class.centerModal]="centerModal">
    <div class="card" [style.width]="modalWidth" [style.height]="modalHeight" role="document">
        <div class="card-header" [class.updating-modal]="updating || confirming">
            <div class="d-flex justify-content-between pb-1">
                <div class="fs-4 fs-bold text-secondary">
                    <p class="mb-0 mt-1">{{ header | translate }}</p>
                    <small *ngIf="headerText">
                        {{ headerText }}
                    </small>
                </div>
                <app-button
                    *ngIf="closeFunc ? true : false"
                    [iconConfig]="{ iconClasses: 'fa-solid fa-times border-0 m-0' }"
                    title="{{ 'Registry.Close' | translate }}"
                    (action)="closePopup()"
                ></app-button>
            </div>
        </div>
        <div
            [style.width]="modalWidth"
            [style.height]="modalHeight"
            class="card-body scroll-content"
            [class.updating-modal]="updating || confirming"
        >
            <ng-content></ng-content>
        </div>

        <div class="card-footer" *ngIf="!updating && !confirming">
            <small *ngIf="footerText">{{ footerText | translate }}</small>
            <div
                class="d-flex"
                [ngClass]="{
                    'justify-content-between': showCloseBtn,
                    'justify-content-end': !showCloseBtn,
                }"
            >
                <app-button
                    *ngIf="showCloseBtn"
                    [disabled]="closeBtnDisabled"
                    [design]="'secondary'"
                    class="me-2 mt-2"
                    (action)="onClose()"
                    [style.width]="appBtnWidth"
                    [btnWidth]="btnWidth"
                    buttonText="{{ closeBtnText | translate }}"
                />
                <app-button
                    [disabled]="saveBtnDisabled"
                    *ngIf="saveFunc"
                    class="float-right mt-2 mb-1"
                    (action)="onSave()"
                    [style.width]="appBtnWidth"
                    [btnWidth]="btnWidth"
                    [design]="'client-primary'"
                    buttonText="{{ saveBtnText | translate }}"
                />
            </div>
        </div>

        <div class="card-footer" *ngIf="updating">
            <h4 class="mt-1 text-center">
                <i attr.aria-label="Updating" class="fa-regular fa-spinner spinner"></i>
                <span class="updating-footer">{{ 'Registry.Updating' | translate }}</span>
            </h4>
        </div>
        <div class="card-footer" *ngIf="confirming">
            <h4 class="mt-1 text-center">
                <i attr.aria-label="Updating" class="fa-regular fa-spinner spinner"></i>
                <span class="updating-footer">{{ 'Registry.Confirming' | translate }}</span>
            </h4>
        </div>
    </div>
</div>
