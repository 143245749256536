<div class="bg-white p-3">
    <div class="d-flex">
        <div class="border-end">
            <ul class="nav flex-column nav-pills nav-justified">
                <li *ngFor="let panel of surveyPanels; let i = index" class="nav-item my-1">
                    <a
                        href="{{ '#nav-' + panel.name }}"
                        attr.aria-controls="{{ '#nav-' + panel.name }}"
                        class="nav-link panel-nav text-truncate border border-1"
                        [ngClass]="i === 0 ? 'active' : ''"
                        id="{{ 'nav-' + panel.name + '-tab' }}"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-selected="true"
                    >
                        #{{ i + 1 }} {{ getText(panel.title) }}</a
                    >
                </li>

                <button class="btn btn-outline-primary" title="Add a panel" (click)="addPanel()">
                    <div class="fs-6 fas fa-regular fa-plus"></div>
                </button>
            </ul>
        </div>
        <div class="w-100">
            <div class="tab-content w-100" id="nav-tabContent">
                <ng-container *ngFor="let panel of surveyPanels; let i = index">
                    <div
                        class="tab-pane w-100"
                        [ngClass]="i === 0 ? 'active show' : ''"
                        id="{{ 'nav-' + panel.name }}"
                        role="tabpanel"
                        attr.aria-labelledby="{{ '#nav-' + panel.name + '-tab' }}"
                    >
                        <app-panel-designer [panel]="panel"> </app-panel-designer>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
