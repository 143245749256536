import { Injectable } from '@angular/core';
import { environment as env } from '@environment/environment';

import { RequestService } from './request.service';
import { ISurveySession } from './models/survey/ISurveySession';
import { map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SurveySessionService {
    constructor(private reqSvc: RequestService) {}

    currentSession: ISurveySession = {
        id: '',
        opened: '',
        closed: '',
        taskId: '',
    };

    openSurveySession(taskId: any): any {
        let dataToSend = {
            taskId: taskId,
        };
        const req: any = { operation: 'openSurveySession', data: dataToSend };
        return this.reqSvc.post(env.API.surveySessionHandler, req).pipe(
            map((sessionId) => {
                this.currentSession.id = sessionId;
                this.currentSession.taskId = taskId;
            })
        );
    }

    closeSurveySession(id: string): any {
        const req: any = { operation: 'closeSurveySession', data: { id: id } };
        return this.reqSvc.post(env.API.surveySessionHandler, req).toPromise();
    }

    trackSurveyNavigation(surveyTrackingObj) {
        const dataToSend = {
            item: surveyTrackingObj.questionKey,
            itemOpened: surveyTrackingObj.itemOpened,
            itemClosed: surveyTrackingObj.itemClosed,
            itemType: 'survey question',
            navigateTo: surveyTrackingObj.navigateTo,
            id: surveyTrackingObj.surveySessionId,
        };
        const req: any = { operation: 'trackSurveyNavigation', data: dataToSend };
        return this.reqSvc.post(env.API.surveySessionHandler, req);
    }
}
