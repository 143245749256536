<div class="type-underline-size-sm-full-width-false-breakpoint-desktop">
    <div class="tabs" role="tablist">
        <div
            *ngFor="let tab of tabs; let i = index"
            id="{{ elementIdPrefix }}-{{ i }}"
            role="tab"
            [attr.tabindex]="i === selectedIndex ? 0 : -1"
            [attr.aria-selected]="i === selectedIndex"
            class="tab-button-base"
            [ngClass]="{ active: i === selectedIndex }"
            (click)="handleTabEvent($event, tab.tabId, i)"
            (keydown)="handleTabEvent($event, tab.tabId, i)"
        >
            <div class="text">{{ tab.tabDisplay | translate }}</div>
        </div>
    </div>
</div>
