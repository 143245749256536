import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { RequestService } from './request.service';
import { CommonService } from './common.service';
import { IDeployTracking } from './models/deploy/IDeployTracking';
import { IConfigTable } from './models/deploy/IConfigTable';

@Injectable({
    providedIn: 'root',
})
export class DeployService {
    constructor(
        private reqSvc: RequestService,
        private commonSvc: CommonService
    ) {}

    // Functions for deploy tracking
    listDeployTracking(env?: any): Observable<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        return this.reqSvc.post(targetEnv.API.deployManagement, {
            operation: 'listDeployTracking',
        });
    }

    getDeployTracking(id: string, env?: any): Observable<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        return this.reqSvc.post(targetEnv.API.deployManagement, {
            operation: 'getDeployTrackingByID',
            data: {
                deployTrackingId: id,
            },
        });
    }

    createDeployTracking(deployTracking: IDeployTracking, env?: any): Observable<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        return this.reqSvc.post(targetEnv.API.deployManagement, {
            operation: 'createDeployTracking',
            data: {
                deployTrackingItems: deployTracking,
            },
        });
    }

    updateDeployTracking(id: string, deployTracking: IDeployTracking, env?: any): Observable<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        return this.reqSvc.post(targetEnv.API.deployManagement, {
            operation: 'updateDeployTracking',
            data: {
                deployTrackingId: id,
                deployTrackingItems: deployTracking,
            },
        });
    }

    deleteDeployTracking(id: string, env?: any): Observable<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        return this.reqSvc.post(targetEnv.API.deployManagement, {
            operation: 'deleteDeployTracking',
            data: {
                deployTrackingId: id,
            },
        });
    }

    // Functions for config table and items
    listConfigTable(env?: any): Observable<IConfigTable[]> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        return this.reqSvc.post(targetEnv.API.deployManagement, {
            operation: 'listConfigTable',
        });
    }

    getConfigItems(configTable: string, env?: any): Observable<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const req = {
            operation: 'getConfigItems',
            data: {
                configTable: configTable,
            },
        };
        return this.reqSvc.post(targetEnv.API.deployManagement, req);
    }

    getConfigItem(configTable: string, configPKey: any, env?: any): Observable<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const req = {
            operation: 'getConfigItem',
            data: {
                configTable: configTable,
                configPKey: configPKey,
            },
        };
        return this.reqSvc.post(targetEnv.API.deployManagement, req);
    }

    createConfigItem(
        configTable: string,
        configPKey: string,
        configVersion: any,
        configItems: any,
        env?: any
    ): Promise<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const req = {
            operation: 'createConfigItem',
            data: {
                configTable: configTable,
                configPKey: configPKey,
                configVersion: configVersion,
                configItems: configItems,
            },
        };
        return lastValueFrom(this.reqSvc.post(targetEnv.API.deployManagement, req));
    }

    updateConfigItem(
        configTable: string,
        configPKey: string,
        configVersion: any,
        configItems: any,
        env?: any
    ): Observable<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const req = {
            operation: 'updateConfigItem',
            data: {
                configTable: configTable,
                configPKey: configPKey,
                configVersion: configVersion,
                configItems: configItems,
            },
        };
        return this.reqSvc.post(targetEnv.API.deployManagement, req);
    }

    deleteConfigItem(configTable: string, configPKey: string, env?: any): Promise<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const req = {
            operation: 'deleteConfigItem',
            data: {
                configTable: configTable,
                configPKey: configPKey,
            },
        };
        return lastValueFrom(this.reqSvc.post(targetEnv.API.deployManagement, req));
    }

    /**
     * Use this method when deploying from an older version to a newer version.
     * This method saves the newer version that is being replaced as 0.5 below the latest version of the config that is replacing it
     * @param configTable The table of the config being deployed to (table key) ex: email_template
     * @param configPKey The Partition Key of the row you want to update ex: proto-ui#proto-ui
     * @param configVersion The version of the new latest Item ex: 3
     * @param configItems The Config that is a replacing configDestItems and its version is less then configDestItems
     * @param configDestItems The Config being replaced and saved
     **/
    versionSavedDeployConfigItem(
        configTable: string,
        configPKey: string,
        configVersion: any,
        configItems: any,
        configDestItems: any,
        env: any
    ): Promise<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const req = {
            operation: 'versionSavedDeployConfigItem',
            data: {
                configTable: configTable,
                configPKey: configPKey,
                configVersion: configVersion,
                configItems: configItems, //Becomes the new latest Item in dest
                configDestItems: configDestItems, //gets replaced and becomes the item 0.5 below new latest version
            },
        };
        return lastValueFrom(this.reqSvc.post(targetEnv.API.deployManagement, req));
    }

    /**
     * Deploy the given config item to the config table in the destination environment.
     * @param configTableObj An object which has config table's information
     * @param sourceItem A config item in source table
     * @param destinationEnv Destination environment
     * @returns An object called deployResult which composed of done (boolean) and message to inform if the deployment went well.
     */
    deployConfigItem(
        configTableObj: IConfigTable,
        sourceItem: any,
        destinationEnv: string
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            const pKey = sourceItem[configTableObj.partitionKeyName];
            const {
                [configTableObj.partitionKeyName]: removePK,
                ['version']: removeSK,
                ['latest_version']: removeLatestVersion,
                ['created']: removeC,
                ...restSourceItem
            } = sourceItem;

            const sourceLatestVer = sourceItem.latest_version || sourceItem.version;

            const deployResult = {
                done: true,
                message: '',
            };

            this.getConfigItem(configTableObj.tableKey, pKey, destinationEnv)
                .toPromise()
                .then((destItem) => {
                    if (destItem) {
                        const destLatestVer = destItem.latest_version || destItem.version;
                        if (sourceLatestVer <= destLatestVer) {
                            deployResult.done = false;
                            deployResult.message = `${pKey}: Fail. Source version must be higher than destination version.`;
                            resolve(deployResult);
                        } else {
                            this.updateConfigItem(
                                configTableObj.tableKey,
                                pKey,
                                sourceLatestVer,
                                restSourceItem,
                                destinationEnv
                            )
                                .toPromise()
                                .then((res) => {
                                    deployResult.message = `${pKey}: Success`;
                                    resolve(deployResult);
                                })
                                .catch((err) => {
                                    console.error(err);
                                    deployResult.done = false;
                                    deployResult.message = `${pKey}: Fail. updateConfigItem error.`;
                                    resolve(deployResult);
                                });
                        }
                    } else {
                        this.createConfigItem(
                            configTableObj.tableKey,
                            pKey,
                            sourceLatestVer,
                            restSourceItem,
                            destinationEnv
                        )
                            .then((res) => {
                                deployResult.message = `${pKey}: Success`;
                                resolve(deployResult);
                            })
                            .catch((err) => {
                                console.error(err);
                                deployResult.done = false;
                                deployResult.message = `${pKey}: Fail. createConfigItem error.`;
                                resolve(deployResult);
                            });
                    }
                })
                .catch((err) => {
                    console.error(err);
                    deployResult.done = false;
                    deployResult.message = `${pKey}: Fail. Get item error.`;
                    resolve(deployResult);
                });
        });
    }
}
