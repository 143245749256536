import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    Renderer2,
    ElementRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface BadgeTooltip {
    display: boolean;
    text?: string;
    options?: {
        placement?: string;
        'show-delay'?: number;
        'hide-delay'?: number;
    };
}

export interface ButtonIconConfig {
    iconClasses: string;
    iconFirst?: boolean;
}

export type ButtonTextSizes = '-' | '+' | '++' | '+++' | '2x' | '4x';

export type ButtonDesigns =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'destructive-primary'
    | 'destructive-secondary'
    | 'destructive-tertiary'
    | 'client-primary';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
    @Input() type: 'button' | 'reset' | 'submit' = 'button';
    @Input() badgeVal?: string;
    @Input() iconConfig?: ButtonIconConfig;
    @Input() buttonClasses?: string;
    @Input() title?: string;
    @Input() disabled = false;
    @Input() tooltipTranslation?: string;
    /** if design is unset, buttons with text will be Primary
     *  and buttons without text will be Tertiary */
    @Input() design?: ButtonDesigns;
    @Input() hasDropdown = false;
    @Input() buttonText?: string;
    @Input() buttonTextSize?: ButtonTextSizes;
    @Input() routerLink?: string;
    @Input() ariaLabel?: string;
    @Input() id?: string;
    @Input() btnWidth?: string;
    @Input() convertToText?: any;
    convertedTextString: string;
    /**
     * @deprecated tooltips have the worst a11y ever, stop using them. just write the text on the button.
     */
    @Input() tooltip: BadgeTooltip = {
        display: false,
        text: 'no tooltip defined',
        options: {
            placement: 'bottom',
            'show-delay': 300,
            'hide-delay': 150,
        },
    };
    @Output() action = new EventEmitter();

    protected sizeMap = new Map<ButtonTextSizes, string>([
        ['4x', '--font-size-64'],
        ['2x', '--font-size-32'],
        ['+++', '--font-size-24'],
        ['++', '--font-size-21'],
        ['+', '--font-size-18'],
        ['-', '--font-size-10'],
    ]);

    protected designMap = new Map<ButtonDesigns, Array<string>>([
        ['client-primary', ['client-primary']],
        ['primary', ['primary']],
        ['secondary', ['secondary']],
        ['tertiary', ['tertiary']],
        ['destructive-primary', ['primary', 'destructive']],
        ['destructive-secondary', ['secondary', 'destructive']],
        ['destructive-tertiary', ['tertiary', 'destructive']],
    ]);

    dropdownVisible = false;
    private clickListener: () => void;

    constructor(
        public translate: TranslateService,
        private renderer: Renderer2,
        private el: ElementRef
    ) {}

    ngOnInit() {
        if (this.convertToText) {
            try {
                this.convertToText = JSON.parse(this.convertToText);
            } catch {
                //console.log('unable to parse translations');
            }
            this.convertedTextString =
                this.convertToText[this.translate.currentLang] ||
                this.convertToText['default'] ||
                this.convertToText;
        }
        this.clickListener = this.renderer.listen('document', 'click', (event: Event) => {
            if (this.dropdownVisible && !this.el.nativeElement.contains(event.target)) {
                this.dropdownVisible = false;
            }
        });
    }

    ngOnDestroy() {
        if (this.clickListener) {
            this.clickListener();
        }
    }

    toggleDropdown() {
        if (this.hasDropdown) {
            this.dropdownVisible = !this.dropdownVisible;
        }
    }

    clickMe($ev: any): any {
        this.action.emit($ev);
        if (this.hasDropdown) {
            this.toggleDropdown();
        }
    }

    mouseOver($ev: any): any {
        if (this.tooltipTranslation) {
            this.tooltip.text = this.translate.instant(this.tooltipTranslation);
        }
    }
}
