<!-- Compare Modal -->
<div class="container-fluid panel-control">
    <app-modal
        [footerText]=""
        [saveBtnText]=""
        *ngIf="showCompare"
        [(visible)]="showCompare"
        [header]="'Compare Config'"
        [context]="this"
        [saveFunc]=""
        [closeFunc]="closeCompareModal"
        [saveBtnDisabled]=""
        [modalWidth]="'80vw'"
        [modalHeight]="'80vh'"
    >
        <div class="col-12 position-relative mt-1">
            <div *ngIf="message" class="text-center text-danger mb-2">
                {{ message }}
            </div>
            <app-loading-component [loading]="compareLoading">
                <div class="row">
                    <div class="col-6">
                        <label>Source: {{ selectedTableKey }} in {{ sourceEnv }}</label>
                        <label *ngIf="selectedTable"
                            >Key: {{ sourceItem[this.selectedTable.partitionKeyName] }}</label
                        >
                        <label
                            >Latest Version:
                            {{ sourceItem.latest_version || sourceItem.version }}</label
                        >
                        <label>Created: {{ sourceItem.created }}</label>
                        <app-object-diff [obj]="sourceView"></app-object-diff>
                    </div>
                    <div class="col-6">
                        <label>Destination: {{ selectedTableKey }} in {{ destinationEnv }}</label>
                        <label *ngIf="selectedTable"
                            >Key: {{ destItem[this.selectedTable.partitionKeyName] }}</label
                        >
                        <label
                            >Latest Version:
                            {{ destItem.latest_version || destItem.version }}</label
                        >
                        <label>Created: {{ destItem.created }}</label>
                        <app-object-diff [obj]="destView"></app-object-diff>
                    </div>
                </div>
                <div class="mt-3">
                    <label>Different Values Only </label>
                    <small class="form-text text-muted">
                        Green: Source config & Red: Destination config
                    </small>
                    <app-object-diff [obj]="diffValueChanges"></app-object-diff>
                    <label>All Values</label>
                    <app-object-diff [obj]="diffView"></app-object-diff>
                </div>
            </app-loading-component>
        </div>
    </app-modal>

    <!-- Filter -->
    <app-tray [visible]="toggleFilters" [trayTitle]="trayTitle" (innerToggle)="innerToggle($event)">
        <div class="trayInsert">
            <div>
                <div class="mx-3">
                    <label class="w-100"
                        >Key
                        <input
                            placeholder="primary key"
                            type="text"
                            class="form-control"
                            (change)="getSearchKey($event.target.value)"
                    /></label>
                </div>
                <app-button
                    class="m-2"
                    (action)="filterConfigItems()"
                    [buttonText]="'Library.Search'"
                />
            </div>
        </div>
    </app-tray>
    <!-- Table -->
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h1 class="text-center mt-3 mb-3">Manage Config</h1>
                    <app-loading-component
                        [loading]="configsLoading"
                        [text]="'loading config tables'"
                    >
                        <div class="ms-3 d-flex">
                            <div class="mx-3">
                                <label>
                                    Tables in {{ sourceEnv }}:
                                    <select
                                        (change)="loadConfigItems($event.target.value)"
                                        class="form-select"
                                    >
                                        <option selected disabled>Pick a table</option>
                                        <option
                                            *ngFor="let config of configTables"
                                            [selected]="config.tableKey === selectedTable.tableKey"
                                        >
                                            {{ config.tableKey }}
                                        </option>
                                    </select>
                                </label>
                            </div>
                            <div
                                class="actions-container"
                                *ngIf="selectedTable.tableKey && selectedTable.tableKey !== ''"
                            >
                                <app-button
                                    *ngIf="canEdit"
                                    class="m-2"
                                    (action)="openConfigItemDetail('create')"
                                    [buttonText]="'Registry.CreateNew'"
                                />
                                <app-button
                                    class="m-2"
                                    (action)="deployItems()"
                                    [buttonText]="'Registry.DeployConfigs'"
                                />
                                <app-button
                                    class="m-2"
                                    (action)="showCompareModal()"
                                    [buttonText]="'Registry.Compare'"
                                />
                            </div>
                        </div>
                    </app-loading-component>
                    <div *ngIf="message" class="text-center text-danger mb-2">
                        {{ message }}
                    </div>
                    <div
                        *ngIf="showResultMsg && resultMsgList.length !== 0"
                        class="text-center mb-2"
                    >
                        Deploy Result
                        <div *ngFor="let msg of resultMsgList">
                            {{ msg }}
                            <br />
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div class="ms-1 d-flex"></div>
                    </div>
                    <hr />
                    <app-loading-component
                        [loading]="configItemsLoading"
                        [text]="'loading config items'"
                    >
                        <div class="ms-3 d-flex">
                            <div class="mx-3">Number of Item: {{ numberOfItems }}</div>
                        </div>

                        <app-table-component
                            *ngIf="
                                selectedTable.tableKey &&
                                selectedTable.tableKey !== '' &&
                                configItems
                            "
                            [rowData]="rows"
                            [columnDefs]="colDefs"
                            [autoHeight]="false"
                            rowSelection="multiple"
                            (selectionChanged)="onSelect($event)"
                            pagination="true"
                            [wrapText]="false"
                            [showFilterRow]="false"
                        ></app-table-component>
                    </app-loading-component>
                    <app-loading-component
                        [loading]="allVersionLoading"
                        [text]="'Loaging all versions'"
                    ></app-loading-component>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal
    [footerText]=""
    [saveBtnText]=""
    *ngIf="showView"
    [(visible)]="showView"
    [header]
    [context]="this"
    [saveFunc]=""
    [closeFunc]="true"
    [modalWidth]="'40vw'"
    [modalHeight]="'70vh'"
>
    <app-manage-config-detail [Viewparam]="sendChild"> </app-manage-config-detail>
</app-modal>
