import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appMaskInput]',
})
export class MaskInputDirective implements OnInit {
    @Input() appMaskValue: string;
    selectedDateFormat: string;
    delimiter: string;

    constructor(
        private elRef: ElementRef,
        private renderer: Renderer2
    ) {}

    ngOnInit() {
        this.selectedDateFormat = localStorage.getItem('prefDateFormat') || 'mm/dd/yyyy';
        this.delimiter = '/';
    }

    @HostListener('input', ['$event'])
    handleInput(event: KeyboardEvent) {
        let value: string = this.elRef.nativeElement.value.replace(/[^0-9]/g, '');
        let formattedValue: string = '';

        if (this.selectedDateFormat === 'dd/mm/yyyy') {
            if (value.length > 2 && value.length <= 4) {
                formattedValue = value.substring(0, 2) + this.delimiter + value.substring(2);
            } else if (value.length > 4) {
                formattedValue =
                    value.substring(0, 2) +
                    this.delimiter +
                    value.substring(2, 4) +
                    this.delimiter +
                    value.substring(4, 8);
            } else {
                formattedValue = value;
            }
        } else if (this.selectedDateFormat === 'yyyy/mm/dd') {
            if (value.length > 4 && value.length <= 6) {
                formattedValue = value.substring(0, 4) + this.delimiter + value.substring(4);
            } else if (value.length > 6) {
                formattedValue =
                    value.substring(0, 4) +
                    this.delimiter +
                    value.substring(4, 6) +
                    this.delimiter +
                    value.substring(6, 8);
            } else {
                formattedValue = value;
            }
        } else if (this.selectedDateFormat === 'mm/dd/yyyy') {
            if (value.length > 2 && value.length <= 4) {
                formattedValue = value.substring(0, 2) + this.delimiter + value.substring(2);
            } else if (value.length > 4) {
                formattedValue =
                    value.substring(0, 2) +
                    this.delimiter +
                    value.substring(2, 4) +
                    this.delimiter +
                    value.substring(4, 8);
            } else {
                formattedValue = value;
            }
        }

        this.renderer.setProperty(this.elRef.nativeElement, 'value', formattedValue);
    }
}
