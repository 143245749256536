import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthService } from '@h20-services/auth.service';
import { UserRole } from '@h20-services/models/role';

@Component({
    selector: 'app-create-password',
    templateUrl: './create-password-component.html',
    styleUrls: ['./create-password-component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CreatePasswordComponent),
            multi: true,
        },
    ],
})
export class CreatePasswordComponent implements OnInit, ControlValueAccessor {
    @Output() passwordEvent = new EventEmitter<any>();

    constructor(
        public translate: TranslateService,
        private auth: AuthService
    ) {}

    passwordInput: string = '';
    confirmPasswordInput: string = '';
    showCharacters = false;
    showConfirmCharacters = false;
    passwordValid: boolean;
    confirmPasswordValid: boolean;
    enteredPreviously = false;
    text = 'Login.Show';
    confirmText = 'Login.Show';
    createPasswordText = '';
    private onChange: (value: any) => void = () => {};
    private onTouched: () => void = () => {};

    hasRequiredLength = false;
    containsUpperCase = false;
    containsSpecialChar = false;
    containsNumber = false;
    containsLowerCase = false;
    params;
    charLen: string;
    requiredLength: number;

    ngOnInit(): void {
        const displayStrs = this.translate.store.translations[this.translate.currentLang];
        this.auth
            .getPulseAuth()
            .then((pulseAuth) => {
                const type = pulseAuth.getType();
                this.requiredLength = [UserRole.Patient, UserRole.Proxy].includes(type) ? 8 : 12;
                this.charLen =
                    this.requiredLength === 12
                        ? displayStrs.Login.Needs12Characters
                        : displayStrs.Login.Needs8Characters;
            })
            .catch((err: Error) => {
                console.error(err);
                // ToDo: When a composer user is activated
                this.requiredLength = 12;
                this.charLen = displayStrs.Login.Needs12Characters;
            });
    }

    emitParams(value: any): void {
        this.passwordEvent.emit(value);
    }

    togglePasswordVisibility(): void {
        this.showCharacters = !this.showCharacters;
        this.text = this.showCharacters ? 'Login.Hide' : 'Login.Show';
    }

    toggleConfirmPasswordVisibility(): void {
        this.showConfirmCharacters = !this.showConfirmCharacters;
        this.confirmText = this.showConfirmCharacters ? 'Login.Hide' : 'Login.Show';
    }

    validate(): void {
        const password = this.passwordInput;
        this.enteredPreviously = true;

        if (password) {
            this.hasRequiredLength = password.length >= this.requiredLength;
            this.containsUpperCase = /[A-Z]/.test(password);
            this.containsSpecialChar = /[^\w\s]/.test(password);
            this.containsNumber = /\d/.test(password);
            this.containsLowerCase = /[a-z]/.test(password);

            this.params = {
                password,
                hasRequiredLength: this.hasRequiredLength,
                containsUpperCase: this.containsUpperCase,
                containsSpecialChar: this.containsSpecialChar,
                containsNumber: this.containsNumber,
                containsLowerCase: this.containsLowerCase,
            };

            if (
                this.hasRequiredLength &&
                this.containsUpperCase &&
                this.containsSpecialChar &&
                this.containsNumber &&
                this.containsLowerCase
            )
                this.passwordValid = true;
        } else {
            this.hasRequiredLength = false;
            this.containsUpperCase = false;
            this.containsSpecialChar = false;
            this.containsNumber = false;
            this.containsLowerCase = false;
        }

        if (this.enteredPreviously) {
            this.validateConfirm();
            this.updateState();
        }
    }

    validateConfirm(): void {
        this.enteredPreviously = true;

        if (
            this.confirmPasswordInput.length >= this.requiredLength &&
            this.passwordInput === this.confirmPasswordInput
        ) {
            this.confirmPasswordValid = undefined;
            if (this.passwordValid) {
                this.confirmPasswordValid = true;
            }

            if (this.passwordValid && this.confirmPasswordValid) {
                this.params.password = this.confirmPasswordInput;
                this.emitParams(this.params);
                this.onChange(this.passwordInput);
            }
        } else {
            this.params.password = '';
            this.emitParams(this.params);
        }

        this.enteredPreviously = true;
    }

    writeValue(value: any): void {
        if (value) {
            this.passwordInput = value;
            this.validate();
        }
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    onPasswordBlur() {
        const displayStrs = this.translate.store.translations[this.translate.currentLang];
        this.passwordValid =
            this.hasRequiredLength &&
            this.containsUpperCase &&
            this.containsSpecialChar &&
            this.containsNumber &&
            this.containsLowerCase;
        this.createPasswordText = !this.passwordValid
            ? displayStrs.Login.AllRequirements
            : displayStrs.Login.PleaseCreatePassword;
    }

    updateState() {
        const REQUIRED_CHARACTER_LENGTH = 8;
        this.validateConfirm();
        if (
            !(
                this.confirmPasswordInput.length >= REQUIRED_CHARACTER_LENGTH &&
                this.passwordInput === this.confirmPasswordInput
            )
        ) {
            this.confirmPasswordValid = false;
        }
    }
    reset() {
        this.passwordInput = '';
        this.confirmPasswordInput = '';
        this.passwordValid = false;
        this.confirmPasswordValid = false;
        this.showCharacters = false;
        this.showConfirmCharacters = false;
        this.hasRequiredLength = false;
        this.containsUpperCase = false;
        this.containsSpecialChar = false;
        this.containsNumber = false;
        this.containsLowerCase = false;

        this.emitParams({ password: '' });
    }
}
