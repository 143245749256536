<div *ngIf="theFormGroup">
    <form [formGroup]="theFormGroup">
        <div class="form-box p-2 p-md-3 d-flex flex-column align-items-end">
            <app-pi-form-progress-bar
                *ngIf="progressType === 'progress-bar'"
                [panels]="panels"
                [theFormGroup]="theFormGroup"
                [activeElement]="activeElement"
                (progressClick)="setActiveElement($event)"
                class="w-100"
            ></app-pi-form-progress-bar>

            <div class="question-box w-100 d-flex flex-column justify-content-center">
                <ng-container *ngFor="let el of flatElementList; index as i">
                    <app-pi-control-switch
                        [element]="el"
                        [class.d-none]="i !== activeIndex"
                        [mode]="mode"
                        [theFormControl]="theFormGroup.controls[el.name]"
                        [formControlList]="theFormGroup.controls"
                        [formData]="formData"
                        [elementList]="elementList"
                        class="py-2"
                        [attr.id]="el.name"
                        (downloadButtonClicked)="downloadEventHandlerFunction($event)"
                        tabindex="-1"
                    >
                    </app-pi-control-switch>
                </ng-container>
                <div class="ps-3 fs-6 text-left fst-italic text-muted" *ngIf="hasStoppedForError">
                    {{ 'Surveys.TapArrow' | translate }}
                </div>
            </div>

            <div
                *ngIf="progressType === 'dot-nav'"
                class="d-flex flex-row justify-content-center w-100 pb-3"
            >
                <div *ngFor="let el of flatElementList; index as i">
                    <span
                        *ngIf="isEnabled(el)"
                        class="pi-form-progress-dot"
                        [ngClass]="getDotClass(el, i)"
                        (click)="setActiveIndex(i)"
                    ></span>
                </div>
            </div>

            <div class="w-100 d-flex flex-row justify-content-between">
                <app-button
                    type="button"
                    class="prev-button"
                    [buttonText]="'Registry.Previous'"
                    (action)="onPrev()"
                    [disabled]="existsPrevEnabledControl()"
                    [iconConfig]="{ iconClasses: 'fa-solid fa-caret-left', iconFirst: true }"
                    ariaLabel="{{ 'Registry.PreviousQuestion' | translate }}"
                    [design]="'client-primary'"
                />
                <app-button
                    type="submit"
                    class="next-button"
                    [design]="'client-primary'"
                    [buttonText]="'Registry.Next'"
                    (action)="onNext()"
                    [disabled]="existsNextEnabledControl()"
                    [iconConfig]="{ iconClasses: 'fa-solid fa-caret-right' }"
                    ariaLabel="{{ 'Registry.NextQuestion' | translate }}"
                />
            </div>
        </div>
    </form>
</div>
