<div class="container-fluid pt-5">
    <div class="row">
        <div class="col-4 offset-4">
            <div class="text-center text-primary pt-5">
                <h1>Welcome to the Healthie&trade; Composer!</h1>
                <hr />
                <div class="pt-3">
                    <p>Configure, manage and deploy to healthie&trade; platforms</p>
                </div>
            </div>
            <div class="pt-5">
                <h4>Getting started</h4>
                <p>Click the <i class="far fa-bars"></i> to see the available sections</p>
            </div>
            <div class="pt-5">
                <h4>Need help?</h4>
                <p>Join the #h20-composer channel for more info and to contact the developers</p>
            </div>
        </div>
        <br />
    </div>
</div>
