import { Injectable } from '@angular/core';
import { environment as env } from '@environment/environment';
import { Observable, firstValueFrom, map, throwError } from 'rxjs';
import { RequestService } from './request.service';
import { IFileManagerConfig } from './models/config/IFileManagerConfig';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';
import { IFileData, IFileStatus, IFileWithMetaData } from './models/IFileWithMetaData';
import { CDNService } from './cdn.service';
import { UuidService } from './uuid.service';
import { DataEntryRoles } from './models/role';

@Injectable({
    providedIn: 'root',
})
export class FileManagerService {
    constructor(
        private reqSvc: RequestService,
        private configSvc: ConfigService,
        private auth: AuthService,
        private cdnSvc: CDNService,
        private uuidsvc: UuidService
    ) {}

    getFileManagerConfig(): Promise<IFileManagerConfig> {
        return this.configSvc
            .getConfigurations(this.configSvc.getTenant())
            .then((config) => {
                const fileManagerConfig: IFileManagerConfig = config.fileManager;

                return fileManagerConfig;
            })
            .catch((err) => {
                console.error(err, 'File Manager Config not found.');
                throw new Error('File Manager Config not found.');
            });
    }

    async getFilesWithMetaData(
        site_ID: string,
        patient_ID: string
    ): Promise<Observable<IFileData[]>> {
        const pulseAuth = await this.auth.getPulseAuth();
        const submissionType = DataEntryRoles.includes(pulseAuth.getType())
            ? 'clinician-submissions'
            : 'patient-submissions';
        const req = {
            operation: 'getFilesWithMetaData',
            data: {
                folderName: `${site_ID}/private/${submissionType}/${patient_ID}`,
                patient_ID: patient_ID,
            },
        };
        return this.reqSvc.post(env.API.writeFileToBucket, req);
    }

    async uploadFileToManager(
        file,
        key: string,
        submission_origin: string,
        submission_id: string,
        task_id: string,
        patient_id: string,
        description: string
    ) {
        const now = new Date();

        const file_data: IFileData = {
            content_type: file.type,
            name: file.name,
            type: file.name.substring(file.name.lastIndexOf('.') + 1),
            question_key: key,
            submission_origin,
            upload_date: `${now.getUTCFullYear()}-${now.getUTCMonth() + 1}-${now.getUTCDate()}`,
            description: description,
        };

        if (submission_id) file_data.submission_id = submission_id;
        if (task_id) file_data.task_id = task_id;

        return this.cdnSvc
            .generateUploadUrl(file_data, patient_id)
            .then(async (presigned_data) => {
                await this.cdnSvc.uploadFileToUrl(presigned_data.upload_url, file);
                await this.cdnSvc.setPresignedUploadSuccess(presigned_data.file_id);

                file_data.id = presigned_data.file_id;
                return file_data;
            })
            .catch((e: Error) => {
                console.error('File Upload Fail', e);
                return throwError(e);
            });
    }

    async downloadFile(filePath: string, fileName: string) {
        this.cdnSvc
            .generateDownloadUrl(filePath)
            .then((downloadUrl) => {
                this.cdnSvc.downloadFileFromUrl(downloadUrl, fileName);
            })
            .catch((e: Error) => {
                console.error('downloadUserSurveyFile err', e);
                return throwError(e);
            });
    }

    async downloadMultipleFiles(filePath: string[], fileName: string[]) {
        this.cdnSvc
            .generateDownloadUrl(filePath)
            .then((downloadUrl) => {
                this.cdnSvc.downloadFileFromUrl(downloadUrl, fileName);
            })
            .catch((e: Error) => {
                console.error('downloadUserSurveyFile err', e);
                return throwError(e);
            });
    }

    async updateFileMetaData(fileMetaData: IFileData, siteId, userId) {
        const pulseAuth = await this.auth.getPulseAuth();
        const submissionType = DataEntryRoles.includes(pulseAuth.getType())
            ? 'clinician-submissions'
            : 'patient-submissions';
        const req = {
            filename: `${siteId}/private/${submissionType}/${userId}/file-uploads/${fileMetaData.id}.${fileMetaData.type}`,
            content: fileMetaData,
        };
        this.reqSvc.post(env.API.writeToBucketURL, req).subscribe();
    }

    getFileWithMetaDataByName(fileName: string, folderName: string) {
        const req = {
            operation: 'getFileWithMetaDataByFileName',
            data: { fileName: fileName, folderName: folderName },
        };
        return this.reqSvc.post(env.API.writeFileToBucket, req);
    }

    getFilesBySurveyId(surveyId: string, folderName: string): Observable<IFileWithMetaData[]> {
        const req = {
            operation: 'getFilesBySurveyId',
            data: { surveyId: surveyId, folderName: folderName },
        };
        return this.reqSvc.post(env.API.writeFileToBucket, req);
    }

    getFilesWithMetaDataByName(
        fileNames: string[],
        folderName: string
    ): Observable<IFileWithMetaData[]> {
        const req = {
            operation: 'getFilesWithMetaDataByFileName',
            data: { fileNames: fileNames, folderName: folderName },
        };
        return this.reqSvc.post(env.API.writeFileToBucket, req);
    }

    getFileDataById(fileIdList: string[]): Observable<IFileData[]> {
        const req = {
            operation: 'getFileDataById',
            data: { file_id_list: fileIdList },
        };
        return this.reqSvc.post(env.API.writeFileToBucket, req);
    }

    deleteFile(id: string): Observable<boolean> {
        const req = {
            operation: 'deleteFile',
            data: { file_id: id },
        };
        return this.reqSvc.post(env.API.writeFileToBucket, req);
    }

    updateFileSubmissionById(fileId: string, submissionId: string): Observable<string> {
        const req = {
            operation: 'updateFileSubmissionById',
            data: { file_id: fileId, submission_id: submissionId },
        };
        return this.reqSvc.post(env.API.writeFileToBucket, req);
    }

    async updateFileDescription(fileId: string, description: string) {
        const req = {
            operation: 'updateFileDescription',
            data: { file_id: fileId, description: description },
        };
        return firstValueFrom(this.reqSvc.post(env.API.writeFileToBucket, req));
    }
}
