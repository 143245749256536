<app-modal
    [saveBtnText]="hasCreateUser ? 'Create User' : 'Edit User'"
    *ngIf="viewNewUserPanel"
    [(visible)]="viewNewUserPanel"
    [header]="hasCreateUser ? 'Create new user' : 'Edit user'"
    [context]="this"
    [saveFunc]="hasCreateUser ? saveNewUser : saveEditUser"
    [closeFunc]="cancelNewUser"
    [modalWidth]="'40vw'"
    [saveBtnDisabled]="!nuForm.valid || loading"
    [modalHeight]="'60vh'"
>
    <div class="card-body" [formGroup]="newUserForm" #nuForm="ngForm">
        <div class="container-fluid">
            <div class="form-group row mt-2">
                <div class="col">
                    <input
                        aria-label="Name"
                        placeholder="Name"
                        type="text"
                        class="form-control"
                        id="nickname"
                        formControlName="nickname"
                    />
                    <div
                        *ngIf="
                            newUserForm.controls.nickname.invalid &&
                            (newUserForm.controls.nickname.dirty ||
                                newUserForm.controls.nickname.touched)
                        "
                    >
                        <div
                            *ngIf="newUserForm.controls.nickname.errors?.required"
                            class="formError text-danger"
                        >
                            Please enter a name
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row mt-2">
                <div class="col">
                    <input
                        [readonly]="!hasCreateUser"
                        aria-label="Email"
                        placeholder="Email"
                        id="email"
                        type="email"
                        class="form-control"
                        formControlName="username"
                        required
                    />
                    <div
                        *ngIf="
                            newUserForm.controls.username.invalid &&
                            (newUserForm.controls.username.dirty ||
                                newUserForm.controls.username.touched)
                        "
                    >
                        <div
                            *ngIf="newUserForm.controls.username.errors?.required"
                            class="formError text-danger"
                        >
                            Please enter an email. This is the username.
                        </div>
                    </div>
                </div>
            </div>
            <div
                *ngIf="
                    userClaims &&
                    (userClaims.usersCreateSelectRoleRegistry ||
                        userClaims.usersCreateSelectRoleAdmin)
                "
                class="form-group row mt-2"
            >
                <div class="col">
                    <select
                        class="form-control"
                        aria-label="Role"
                        formControlName="role"
                        id="role"
                        required
                    >
                        <option value="" disabled>Role --Please select--</option>
                        <option value="composer">composer</option>
                        <option value="composer-admin">composer-admin</option>
                        <option
                            *ngIf="this.userClaims.productionDeployment_fullAccess"
                            value="composer-super-admin"
                        >
                            composer-super-admin
                        </option>
                    </select>
                    <div
                        *ngIf="
                            newUserForm.controls.role.errors?.required &&
                            (newUserForm.controls.role.dirty || newUserForm.controls.role.touched)
                        "
                        class="formError text-danger"
                    >
                        Please select a role
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <h4 *ngIf="createUserError" class="badge badge-warning text-wrap">
                        Error: {{ createUserErrorMessage }}
                    </h4>
                </div>
            </div>
        </div>
    </div>
</app-modal>
<app-modal
    [saveBtnText]="'Delete User'"
    *ngIf="deleteUserPanel"
    [(visible)]="deleteUserPanel"
    [header]="'Delete User'"
    [context]="this"
    [saveFunc]="deleteUserDialog"
    [closeFunc]="cancelDeleteDialog"
    [modalWidth]="'40vw'"
    [modalHeight]="'30vh'"
>
    <div>
        <h6>
            Are you sure you want to delete
            {{ userEditing.preferred_name }}?
        </h6>
    </div>
</app-modal>

<div class="container-fluid panel-control">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body data-table-ctrl-992">
                    <div class="d-flex justify-content-between">
                        <h1>User Management</h1>
                        <div class="d-flex justify-content-end">
                            <div class="ms-1 d-flex">
                                <app-button
                                    [iconConfig]="{ iconClasses: 'fa-regular fa-sliders-v' }"
                                    [buttonClasses]="'ms-1'"
                                    style="opacity: 0.5; cursor: default; margin-bottom: 8px"
                                    (action)="showToast()"
                                >
                                </app-button>
                            </div>
                            <div *ngIf="userClaims.usersCreate" class="ms-1 d-flex">
                                <app-button
                                    [iconConfig]="{ iconClasses: 'fa-regular fa-plus' }"
                                    (action)="createUser($event)"
                                    data-target="#staticBackdrop"
                                    title="ClickToCreateNewUser"
                                >
                                </app-button>
                            </div>
                        </div>
                    </div>
                    <app-loading-component [loading]="loading">
                        <div class="row">
                            <div class="ag-theme-quartz" style="width: 100%">
                                <app-table-component
                                    pagination="true"
                                    [rowData]="users"
                                    [columnDefs]="colDefs"
                                    [filterModelKey]="'users'"
                                >
                                </app-table-component>
                            </div>
                        </div>
                    </app-loading-component>
                </div>
            </div>
        </div>
    </div>
</div>
