import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
    selector: 'app-multi-select-dropdown',
    templateUrl: './multi-select-dropdown.component.html',
    styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent {
    @Input() items: any[] = []; // List of items to display
    @Input() isMultiSelect: boolean = true; // Multi-select enabled
    @Input() enableSearch: boolean = true; // Search enabled or not
    @Input() scrollable: boolean = true; // Enable scroll

    @Output() selectionChange = new EventEmitter<any[]>();

    selectedItems: any[] = [];
    searchQuery: string = '';
    dropdownOpen: boolean = false; // Controls whether the dropdown is open

    // Toggle the dropdown open/close
    toggleDropdown() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    // Handle item selection and update selected items
    toggleSelection(item: any) {
        // If the item is already selected, remove it from the list
        if (this.selectedItems.includes(item)) {
            this.selectedItems = this.selectedItems.filter((i) => i !== item);
        } else {
            // Add the item to the selected list
            if (this.isMultiSelect) {
                this.selectedItems.push(item); // Add to the array for multi-selection
            } else {
                this.selectedItems = [item]; // Replace the selection for single-selection
                this.dropdownOpen = false; // Close dropdown if single-select
            }
        }

        // Emit the updated selected items
        this.selectionChange.emit(this.selectedItems);
    }

    // Remove selected item (for multi-select)
    removeItem(item: any) {
        this.selectedItems = this.selectedItems.filter((i) => i !== item);
        this.selectionChange.emit(this.selectedItems);
    }

    // Filter the dropdown items based on search query
    get filteredItems() {
        return this.searchQuery
            ? this.items.filter((item) =>
                  item.label.toLowerCase().includes(this.searchQuery.toLowerCase())
              )
            : this.items;
    }

    // Detect clicks outside the dropdown to close it
    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event) {
        const targetElement = event.target as HTMLElement;
        if (!targetElement.closest('.dropdown-container')) {
            this.dropdownOpen = false;
        }
    }
}
