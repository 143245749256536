import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ElementDesignerConfigService } from '../../survey-designer/element-designer/element-designer-config.service';
import { firstValueFrom } from 'rxjs';
import { SurveyService } from '@h20-services/survey.service';

@Component({
    selector: 'app-rtv-configuration-section',
    templateUrl: './rtv-configuration-section.component.html',
    styleUrls: ['./rtv-configuration-section.component.scss'],
})
export class RtvConfigurationSectionComponent implements OnChanges {
    constructor(
        private surveyService: SurveyService,
        private designerConfigService: ElementDesignerConfigService,
        private changeDetector: ChangeDetectorRef
    ) {}
    element: any; //this elements
    elements: any;
    @Input() survey;
    @Input() elementIndex;
    @Input() currentElement;
    @Input() panelIndex;
    formForm: any;
    designerForm;
    generalForm = { pages: [{ elements: [] }] };
    layoutForm = { pages: [{ elements: [] }] };
    logicForm = { pages: [{ elements: [] }] };
    options = {
        condensed: true,
    };
    hotReload = false;
    @Output() elementChange = new EventEmitter<any>();

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['survey'] ||
            changes['elementIndex'] ||
            changes['panelIndex'] ||
            changes['currentElement']
        ) {
            this.updateConfig();
        }
    }

    updateConfig(): void {
        let elementType =
            this.survey.pages[0].elements[this.panelIndex].elements[this.elementIndex].type;
        firstValueFrom(this.designerConfigService.getFullConfig(elementType))
            .then((valForm) => {
                this.designerForm = this.surveyService.concatenateSurveys('Element Form', valForm);
                this.generalForm.pages[0].elements = this.designerForm.pages[0].elements.filter(
                    (el) =>
                        el.name === 'element' ||
                        (elementType === 'text'
                            ? el.name === 'inputType'
                            : el.name === elementType) ||
                        el.name === 'element_others'
                );
                this.layoutForm.pages[0].elements = this.designerForm.pages[0].elements.filter(
                    (el) => el.name === 'layout'
                );
                this.logicForm.pages[0].elements = this.designerForm.pages[0].elements.filter(
                    (el) => el.name === 'logic'
                );
                this.doHotReload();
            })
            .catch((reason) => {
                console.error('Failed to load SB configurator survey config for some reason?');
                console.error(reason);
            });
    }
    onFormChanges(event: any) {
        if (this.currentElement.name !== event.value.name) {
            const panelInd = event.value;
            const elIndex = this.elementIndex;
            Object.assign(this.currentElement, this.nonNullValues(event.value));
            this.elementChange.emit({ elIndex, panelInd });
        } else {
            Object.assign(this.currentElement, this.nonNullValues(event.value));
        }
    }

    nonNullValues(obj: any): any {
        return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== null));
    }

    doHotReload(): void {
        this.hotReload = true;
        this.changeDetector.detectChanges();
        this.hotReload = false;
    }
}
