<div class="wrapper">
    <div class="row mb-3">
        <div class="left-column">
            <h4 class="desc-heading">
                {{ 'UserSettings.DateSettings' | translate }}
            </h4>
            <div class="desc-sub-heading" [innerHTML]="dateSubHeading"></div>
        </div>
        <div class="right-column">
            <div>
                <p class="input-heading">
                    {{ 'UserSettings.DateFormat' | translate }}
                </p>
                <select class="form" id="dropdown" [(ngModel)]="tempDateFormat">
                    <option value="dd/mm/yyyy">DD/MM/YYYY</option>
                    <option value="mm/dd/yyyy">MM/DD/YYYY</option>
                    <option value="yyyy/mm/dd">YYYY/MM/DD</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row mb-3">
        <div class="left-column">
            <h4 class="desc-heading">
                {{ 'UserSettings.TimeSettings' | translate }}
            </h4>
            <div class="desc-sub-heading" [innerHTML]="timeSubHeading"></div>
        </div>
        <div class="right-column">
            <div>
                <p class="input-heading">
                    {{ 'UserSettings.TimeFormat' | translate }}
                </p>
                <select class="form" id="dropdown" [(ngModel)]="tempTimeFormat">
                    <option value="12 hours">
                        {{ 'UserSettings.12Hour' | translate }}
                    </option>
                    <option value="24 hours">
                        {{ 'UserSettings.24Hour' | translate }}
                    </option>
                </select>
            </div>
            <app-button
                class="ms-1"
                [type]="'submit'"
                (action)="handleDateTimeFormatChange()"
                [buttonText]="'UserSettings.SaveDateTimeSettings'"
            />
        </div>
    </div>
</div>
