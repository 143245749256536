import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
} from '@angular/core';
import { CommonService } from '@h20-services/common.service';

@Component({
    selector: 'app-rtv-test-section',
    templateUrl: './rtv-test-section.component.html',
    styleUrls: ['./rtv-test-section.component.scss'],
})
export class RtvTestSectionComponent implements OnInit, OnChanges {
    constructor(
        private http: HttpClient,
        private common: CommonService,
        private changeDetector: ChangeDetectorRef
    ) {}
    @Output() elementChange = new EventEmitter<{}>();
    @Input() survey: any;
    @Input() currentElement;
    surveyPanels: any;
    formForm: any;
    panel: any;
    panelIndex: any = 0;
    currentLayout = 'flat';
    currentEditor: number = 0;
    @Input() elementIndex;
    @Input() newsurvey;
    @Input() randomNumber;
    hotReload = false;
    ngOnInit(): void {}

    ngOnChanges(): void {
        this.doHotReload();
    }

    onOtherFormChanges(event: any) {}

    doHotReload(): void {
        this.hotReload = true;
        this.changeDetector.detectChanges();
        this.hotReload = false;
    }
}
