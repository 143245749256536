import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment as env } from '@environment/environment';
import { Amplify } from 'aws-amplify';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LayoutComponent } from './layout/layout.component';
import { LayoutSidebarComponent } from './layout/layout-sidebar/layout-sidebar.component';
import { LayoutNavComponent } from './layout/layout-nav/layout-nav.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@h20-shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EnvironmentService } from '@h20-services/environment.service';
import { ComposerRoleService } from '@h20-services/composer-role.service';
import { LayoutCenteredComponent } from '@layout/layout-centered/layout-centered.component';
import { sessionStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

Amplify.configure(env.awsConfig);
cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);
@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        LayoutComponent,
        LayoutSidebarComponent,
        LayoutNavComponent,
        LayoutCenteredComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            isolate: false,
        }),
    ],
    providers: [EnvironmentService, ComposerRoleService],
    bootstrap: [AppComponent],
})
export class AppModule {}
