import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent {
    @Input() placeholder: string = '';
    @Input() maxLength: number = 100;
    @Input() errorText: string = '';
    @Input() hintText: string = '';
    @Input() isDisabled: boolean = false;
    //@Input() textAreaValue: string = "";
    @Input() state: 'default' | 'error' | 'filled' | 'focused' = 'default';

    @Input() textAreaValue: string = '';
    @Output() textAreaValueChange = new EventEmitter<string>();
    @Output() valueChange = new EventEmitter<string>();

    onTextAreaInput(event: Event) {
        const value = (event.target as HTMLTextAreaElement).value;
        this.textAreaValue = value;
        this.textAreaValueChange.emit(this.textAreaValue);
        this.valueChange.emit(this.textAreaValue);
    }
}
