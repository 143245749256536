<div class="sidebar" [class.closed]="!sideBarOpen">
    <app-layout-sidebar></app-layout-sidebar>
    <button class="arrows" (click)="openAndCloseSideBar()">
        <i
            class="fa-solid fa-chevron-down"
            [style.transform]="sideBarOpen ? 'rotate(90deg)' : 'rotate(270deg)'"
        ></i>
    </button>
</div>
<div class="main-content" [style.marginLeft]="sideBarOpen ? '300px' : '25px'">
    <app-layout-nav [sideBarOpen]="sideBarOpen"></app-layout-nav>
    <div class="container-fluid pt-3">
        <router-outlet></router-outlet>
    </div>
</div>
