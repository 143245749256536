import { Component } from '@angular/core';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
    constructor() {}

    sideBarOpen = false;

    openAndCloseSideBar() {
        this.sideBarOpen = !this.sideBarOpen;
    }
}
