import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SurveyBuilderRoutingModule } from './survey-builder-routing.module';
import { SurveyDesignerComponent } from './survey-designer/survey-designer.component';
import { SurveyTesterComponent } from './survey-tester/survey-tester.component';

import { SurveyBuilderComponent } from './survey-builder/survey-builder.component';
import { PanelDesignerComponent } from './survey-designer/panel-designer/panel-designer.component';
import { ElementDesignerComponent } from './survey-designer/element-designer/element-designer.component';
import { SharedModule } from '@h20-shared/shared.module';
import { ElementLayoutDesignerComponent } from './survey-designer/element-layout-designer/element-layout-designer.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { NgxRerenderModule } from 'ngx-rerender';
import { SpyDirective, SpyTargetDirective } from '@thejlifex/ngx-scroll-spy';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { FragmentSearchComponent } from './fragment-search/fragment-search.component';
import { SurveyRtvComponent } from './survey-rtv/survey-rtv.component';
import { RtvManagementSectionComponent } from './survey-rtv/rtv-management-section/rtv-management-section.component';
import { RtvConfigurationSectionComponent } from './survey-rtv/rtv-configuration-section/rtv-configuration-section.component';
import { RtvTestSectionComponent } from './survey-rtv/rtv-test-section/rtv-test-section.component';
export function homeHttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        SurveyDesignerComponent,
        SurveyTesterComponent,
        SurveyBuilderComponent,
        PanelDesignerComponent,
        ElementDesignerComponent,
        ElementLayoutDesignerComponent,
        FragmentSearchComponent,
        SurveyRtvComponent,
        RtvManagementSectionComponent,
        RtvConfigurationSectionComponent,
        RtvTestSectionComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        SurveyBuilderRoutingModule,
        NgJsonEditorModule,
        NgxRerenderModule,
        SpyDirective,
        SpyTargetDirective,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: homeHttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
})
export class SurveyBuilderModule {}
