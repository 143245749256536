import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-generic-badge',
    templateUrl: './generic-badge.component.html',
    styleUrls: ['./generic-badge.component.scss'],
})
export class GenericBadgeComponent {
    constructor(private readonly translate: TranslateService) {}
    @Input() size: 'sm' | 'md' | 'lg' = 'md';
    @Input() icon: null | 'dot' = null;
    @Input() color: 'gray' | 'blue' | 'red' | 'yellow' | 'green' = 'gray';
    @Input({ required: true }) label!: string;
}
