<div>
    <app-modal
        *ngIf="showMFAPanel"
        [saveBtnText]="'Admin.Confirm'"
        [appBtnWidth]="'50%'"
        [closeBtnText]="'Registry.Cancel'"
        [(visible)]="showMFAPanel"
        [context]="this"
        [closeFunc]="closeModal"
        [showCloseBtn]="true"
        [saveFunc]="confirmLogin.bind(this)"
        [modalWidth]="'25vw'"
        [btnWidth]="'100%'"
        [modalHeight]="'60vh'"
        [saveBtnDisabled]="!mfaForm.valid"
        [centerModal]="true"
        [confirming$]="confirming$"
    >
        <div class="container">
            <div class="modal-icon-container">
                <app-featured-icon
                    class="col-24"
                    [iconName]="'fa-regular fa-shield modal-icon'"
                    [size]="'large'"
                    [type]="'primary'"
                    [rounded]="true"
                ></app-featured-icon>
            </div>
            <div [formGroup]="mfaForm" #rpForm="ngForm">
                <div class="row justify-content-center text-center">
                    <div class="form-group">
                        <p class="modal-heading">
                            {{ 'MFA.AuthHeading' | translate }}
                        </p>
                        <p class="modal-description">
                            {{ 'MFA.AuthBody' | translate }}
                        </p>
                    </div>

                    <div class="input-heading row justify content-left">
                        <span class="input-heading">
                            {{ 'MFA.AuthInputLabel' | translate }}
                        </span>
                        <input
                            aria-label="mfaCode"
                            placeholder="000000"
                            type="mfaCode"
                            class="form-control"
                            formControlName="mfaCode"
                            id="mfaCodeField"
                            cdkFocusInitial
                            maxlength="6"
                        />
                        <div *ngIf="mfa_failed" class="err-msg">
                            {{ 'MFA.CodeError' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mx-2 pt-2">
                <span class="footer">
                    {{ 'MFA.HavingTrouble' | translate }}
                </span>
                <button class="footer req-support" (click)="requestMFASupport()">
                    {{ 'MFA.RequestSupport' | translate }}
                </button>
            </div>
        </div>
    </app-modal>
</div>

<app-modal
    *ngIf="requestHelpModal"
    [(visible)]="requestHelpModal"
    [header]
    [saveBtnText]="'MFA.RequestSupport'"
    [closeBtnText]="'Registry.Back'"
    [showCloseBtn]="true"
    [context]="this"
    [saveFunc]="sendMfaEmailSubmission.bind(this)"
    [closeFunc]="goBackToMFA.bind(this)"
    [modalWidth]="'25vw'"
    [modalHeight]="'60vh'"
    [btnWidth]="'48%'"
    [centerModal]="true"
>
    <app-loading-component [loading]="confirmLoginLoading">
        <div class="modalContainer">
            <div class="modal-icon-container">
                <app-featured-icon
                    class="col-24"
                    [iconName]="'fa-regular fa-circle-question fa-fw modal-icon'"
                    [size]="'large'"
                    [type]="'primary'"
                    [rounded]="true"
                ></app-featured-icon>
            </div>
            <p class="modal-heading">
                {{ 'MFA.HavingTroubleHeading' | translate }}
            </p>
            <p class="modal-description">
                {{ 'MFA.HavingTroubleBodyOne' | translate }}
            </p>
            <p class="modal-description">
                {{ 'MFA.HavingTroubleBodyTwo' | translate }}
            </p>
        </div>
    </app-loading-component>
</app-modal>

<app-modal
    *ngIf="helpSuccessModal"
    [context]="this"
    [(visible)]="helpSuccessModal"
    [header]
    [closeBtnText]="'Registry.Close'"
    [showCloseBtn]="true"
    [closeFunc]="closeModal"
    [modalWidth]="'25vw'"
    [modalHeight]="'60vh'"
    [btnWidth]="'100%'"
    [centerModal]="true"
>
    <div class="modalContainer">
        <div class="modal-icon-container">
            <app-featured-icon
                class="col-24"
                [iconName]="'fa-solid fa-check modal-icon'"
                [size]="'large'"
                [type]="'success'"
                [rounded]="true"
            ></app-featured-icon>
        </div>
        <p class="modal-heading">{{ 'MFA.SupportReqHeading' | translate }}</p>
        <p class="modal-description">{{ 'MFA.SupportReqBody' | translate }}</p>
    </div>
</app-modal>

<app-modal
    *ngIf="showSendRequestErrorModal"
    [(visible)]="showSendRequestErrorModal"
    [header]
    [saveBtnText]="'Registry.Close'"
    [closeBtnText]="'Registry.Back'"
    [showCloseBtn]="true"
    [context]="this"
    [saveFunc]="closeModal"
    [closeFunc]="goBackToRequestSupport.bind(this)"
    [modalWidth]="'25vw'"
    [modalHeight]="'60vh'"
    [btnWidth]="'100%'"
    [centerModal]="true"
>
    <div class="modalContainer">
        <div class="modal-icon-container">
            <app-featured-icon
                class="col-24"
                [iconName]="'fa-solid fa-circle-exclamation modal-icon'"
                [size]="'large'"
                [type]="'error'"
                [rounded]="true"
            ></app-featured-icon>
        </div>
        <p class="modal-heading">{{ 'MFA.SomethingWentWrong' | translate }}</p>
        <p class="modal-description">
            {{ 'MFA.SupportRequestFailed' | translate }}
        </p>
    </div>
</app-modal>

<!-- On activation when MFA is required it does not show in modal so need one more block to show in modal, as there are different classes so added new block
TODO: Create common html elements for both the conditions -->
<div>
    <app-modal
        *ngIf="showMFASetupPanel && showInModal"
        [saveBtnText]="'Admin.Login'"
        [closeBtnText]="'Admin.Cancel'"
        [(visible)]="showMFASetupPanel"
        [context]="this"
        [closeFunc]="onCancelLogin"
        [saveFunc]="confirmSetup.bind(this)"
        [saveBtnDisabled]="!ngForm.valid"
        [closeBtnDisabled]="false"
        [modalWidth]="'50vw'"
        [modalHeight]="'70vh'"
        (visibleChange)="onCancelLogin()"
    >
        <div class="container">
            <app-featured-icon
                [iconName]="'fa-regular fa-shield'"
                [type]="'primary'"
                [size]="'xlarge'"
                [rounded]="true"
            ></app-featured-icon>
            <div class="row p-3 text-center">
                <h4>{{ 'Activate.MultiFactorSetup' | translate }}</h4>
                <div>
                    {{ 'Activate.ConfigurationMessage' | translate }}
                </div>
            </div>
            <app-icon-card
                [fontChar]="'fa-regular fa-mobile'"
                [iconSize]="'xlarge'"
                [isSubHeading]="true"
                [isHeading]="true"
                heading="{{ 'Activate.Step1' | translate }}"
                subHeading="{{ 'Activate.DownloadApp' | translate }}"
                content="{{ 'Activate.DownloadContent' | translate }}"
            >
            </app-icon-card>
            <app-icon-card
                [fontChar]="'fa-regular fa-qrcode'"
                [iconSize]="'xlarge'"
                [isSubHeading]="true"
                [isHeading]="true"
                heading="{{ 'Activate.Step2' | translate }}"
                subHeading="{{ 'Activate.ScanQR' | translate }}"
                style="width: 100%"
            >
                <div>
                    <div>
                        {{ 'Activate.OpenAuthentication' | translate }}
                        <ul>
                            <li>
                                {{ 'Activate.AuthenticationInstructionStep1' | translate }}
                            </li>
                            <li>
                                {{ 'Activate.AuthenticationInstructionStep2' | translate }}
                            </li>
                        </ul>
                        <qrcode [qrdata]="mfaQrCodePath" [errorCorrectionLevel]="'M'"></qrcode>
                        <a class="p-3" (click)="showCode()"
                            >({{ 'Activate.CannotScanCode' | translate }})</a
                        >
                        <div *ngIf="showCodeDiv">
                            <p>
                                {{ 'Activate.ManualEntryInstructions' | translate }}
                            </p>
                            <p>
                                <strong>{{ mfaQrCode }}</strong>
                            </p>
                            <p>
                                {{ 'Activate.QRCodeExtension' | translate }}
                            </p>
                            <p>
                                {{ 'Activate.AppBrowserCodeGeneration' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </app-icon-card>

            <app-icon-card
                [fontChar]="'fa-regular fa-input-numeric'"
                [iconSize]="'xlarge'"
                [isSubHeading]="true"
                [isHeading]="true"
                heading="{{ 'Activate.Step3' | translate }}"
                subHeading="{{ 'Activate.EnterCode' | translate }}"
                style="width: 100%"
            >
                <div>
                    {{ 'Activate.EnterGeneratedCode' | translate }}
                </div>
                <div class="col-4">
                    <form [formGroup]="mfaForm" #ngForm="ngForm">
                        <input
                            aria-label="mfaCode"
                            placeholder="000000"
                            class="form-control"
                            formControlName="mfaCode"
                            id="mfaCodeField"
                            type="mfaCode"
                            (keyup)="checkErrorMessage()"
                            required
                        />
                    </form>
                </div>
                <div
                    *ngIf="
                        mfaForm.controls.mfaCode.invalid &&
                        (mfaForm.controls.mfaCode.dirty || mfaForm.controls.mfaCode.touched)
                    "
                >
                    <div
                        *ngIf="mfaForm.controls.mfaCode.errors?.required"
                        class="formError text-danger"
                    >
                        {{ 'Activate.PleaseEnterCodeMessage' | translate }}
                    </div>
                </div>
                <div *ngIf="mfa_failed" class="formError text-danger">
                    {{ 'Activate.WrongCodeError' | translate }}
                </div>
            </app-icon-card>
        </div>
    </app-modal>
</div>
<div *ngIf="showMFASetupPanel && !showInModal">
    <div class="setup-container">
        <div class="row">
            <div class="icon-col icon-column">
                <div class="icon-circle">
                    <i class="fa-regular fa-mobile"></i>
                </div>
                <div class="content-col">
                    <div class="main-heading">{{ 'Activate.Step1' | translate }}</div>
                    <div class="sub-heading">
                        {{ 'Activate.DownloadApp' | translate }}
                    </div>
                    <div class="content-div">
                        {{ 'Activate.DownloadContent' | translate }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="icon-col icon-column">
                <div class="icon-circle">
                    <i class="fa-regular fa-qrcode"></i>
                </div>
                <div class="content-col">
                    <div class="main-heading">{{ 'Activate.Step2' | translate }}</div>
                    <div class="sub-heading">{{ 'Activate.ScanQR' | translate }}</div>
                    <qrcode [qrdata]="mfaQrCodePath" [errorCorrectionLevel]="'M'" />
                    <div class="content-div">
                        <div>{{ 'Activate.OpenAuthentication' | translate }}</div>
                        <ul>
                            <li>
                                {{ 'Activate.AuthenticationInstructionStep1' | translate }}
                            </li>
                            <li>
                                {{ 'Activate.AuthenticationInstructionStep2' | translate }}
                            </li>
                        </ul>
                        <a class="p-3 help" (click)="showCode()">
                            {{ 'Activate.CannotScanCode' | translate }}
                        </a>
                        <div class="p-3" *ngIf="showCodeDiv">
                            <p class="mb-2">
                                {{ 'Activate.ManualEntryInstructions' | translate }}
                            </p>
                            <p class="mb-2">
                                <strong>{{ mfaQrCode }}</strong>
                            </p>
                            <p class="mb-2">
                                {{ 'Activate.QRCodeExtension' | translate }}
                            </p>
                            <p class="mb-2">
                                {{ 'Activate.AppBrowserCodeGeneration' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="icon-col icon-column">
                <div class="icon-circle">
                    <i class="fa-regular fa-input-numeric"></i>
                </div>
                <div class="content-col">
                    <div class="main-heading">{{ 'Activate.Step3' | translate }}</div>
                    <div class="sub-heading">{{ 'Activate.EnterCode' | translate }}</div>
                    <div class="content-div">
                        <div>
                            {{ 'Activate.EnterGeneratedCode' | translate }}
                        </div>
                        <div class="col-4 mb-2 mt-1">
                            <form [formGroup]="mfaForm" #ngForm="ngForm">
                                <input
                                    aria-label="mfaCode"
                                    placeholder="000000"
                                    class="form-control"
                                    formControlName="mfaCode"
                                    id="mfaCodeField"
                                    type="mfaCode"
                                    (keyup)="checkErrorMessage()"
                                    required
                                />
                            </form>
                        </div>
                        <div
                            *ngIf="
                                mfaForm.controls.mfaCode.invalid &&
                                (mfaForm.controls.mfaCode.dirty || mfaForm.controls.mfaCode.touched)
                            "
                        >
                            <div
                                *ngIf="mfaForm.controls.mfaCode.errors?.required"
                                class="formError mb-3"
                            >
                                {{ 'Activate.PleaseEnterCodeMessage' | translate }}
                            </div>
                        </div>
                        <div
                            *ngIf="mfa_failed && !mfaForm.controls.mfaCode.errors?.required"
                            class="formError mb-3"
                        >
                            {{ 'Activate.WrongCodeError' | translate }}
                        </div>
                        <app-button
                            [type]="'submit'"
                            (action)="confirmSetup()"
                            [buttonText]="'MFA.Action'"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showMFAPanel" class="black-transparent-bg"></div>
