import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import defaultLanguage from '../assets/i18n/en.json';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'H20-Composer';

    private static stdLangs = ['en'];

    constructor(public translate: TranslateService) {
        translate.addLangs(AppComponent.stdLangs);
        translate.setTranslation('en', defaultLanguage);
        translate.setDefaultLang('en');
        translate.use(translate.getDefaultLang()); //H20-1349
    }
}
