import { Injectable } from '@angular/core';
import { Hub } from 'aws-amplify/utils';
import { PatientService } from './patient.service';
import { AuthService } from './auth.service';
import { environment as env } from '@environment/environment';
import { UserStatus } from './models/status';
import { UserService } from './user.service';
import { UserRole } from './models/role';
import { RequestService } from './request.service';
import { PulseParticipant } from './models/PulseParticipant';

@Injectable({
    providedIn: 'root',
})
export class ParticipantService {
    constructor(
        private reqSvc: RequestService,
        private patientSvc: PatientService,
        private userSvc: UserService
    ) {
        Hub.listen('auth', (data) => {
            if (data.payload.event === AuthService.SIGN_IN_EVENT) {
            } else if (data.payload.event === AuthService.SIGN_OUT_EVENT) {
                this.clearCurrentParticipant();
                this.clearClinicalEntryPatient();
            }
        });
    }

    curr_prt_storage_key = 'currentParticipant';
    curr_prt_name_storage_key = 'currentParticipantName';
    clinical_entry_patient_key = 'tempPatientIdForClinicalEntry';

    private findProxyID(userlist): string {
        for (const u of userlist) {
            if (u.type == UserRole.Proxy) return u.id;
        }
        return '';
    }

    private findProxyPatient(sameAuths): any {
        if (Object.keys(sameAuths).length > 1) {
            for (let i = 0; i < Object.keys(sameAuths).length; i++) {
                if (sameAuths[i].type == UserRole.Patient) return sameAuths[i];
            }
        }
        return null;
    }

    // Return an array of all participants.
    // If the user has not registered other patients, it will return null.

    getParticipants(authID: string): Promise<PulseParticipant[]> {
        return new Promise((resolve, reject) => {
            this.userSvc.getValidUsersByAuthId(authID).then(
                (sameAuths) => {
                    const proxyUserID = this.findProxyID(sameAuths);
                    if (!proxyUserID) {
                        resolve([]);
                        return;
                    }

                    this.patientSvc.getPatientByProxyID(proxyUserID).subscribe(
                        (patients) => {
                            let participants: PulseParticipant[] = [];
                            // Check if the proxy has an adult patient role as well
                            const proxyPatient = this.findProxyPatient(sameAuths);
                            if (proxyPatient) participants.push(new PulseParticipant(proxyPatient));

                            for (const p of patients) {
                                if (
                                    p.status == UserStatus.Declined ||
                                    p.status == UserStatus.Withdrawn
                                )
                                    continue;
                                const pulsePart = new PulseParticipant(p);
                                participants.push(pulsePart);
                            }
                            resolve(participants);
                        },
                        (err: any) => {
                            console.error(err);
                            reject(err);
                        }
                    );
                },
                (err: any) => {
                    console.error(err);
                    reject(err);
                }
            );
        });
    }

    // Init current participant by proxy
    initCurrentParticipant(authID: string): any {
        return new Promise((resolve, reject) => {
            this.getParticipants(authID).then((prts) => {
                if (prts && prts.length > 0) {
                    // FIXME:  Why the last created?
                    const latestPrt = PulseParticipant.getLastCreated(prts);
                    this.setCurrentParticipant(latestPrt.getUserID());
                    this.setCurrentParticipantName(latestPrt.getPreferredName());
                    resolve('set');
                } else {
                    this.clearCurrentParticipant();
                    resolve('clear');
                }
            });
        });
    }

    setCurrentParticipant(participantId) {
        localStorage.setItem(this.curr_prt_storage_key, participantId);
    }

    getCurrentParticipant(): any {
        return localStorage.getItem(this.curr_prt_storage_key);
    }

    setCurrentParticipantName(participantName) {
        localStorage.setItem(this.curr_prt_name_storage_key, participantName);
    }

    getCurrentParticipantName(): any {
        return localStorage.getItem(this.curr_prt_name_storage_key);
    }

    setClinicalEntryPatient(patientId) {
        localStorage.setItem(this.clinical_entry_patient_key, patientId);
    }

    getClinicalEntryPatient(): any {
        return localStorage.getItem(this.clinical_entry_patient_key);
    }

    clearClinicalEntryPatient() {
        localStorage.removeItem(this.clinical_entry_patient_key);
    }

    clearCurrentParticipant() {
        localStorage.removeItem(this.curr_prt_storage_key);
        localStorage.removeItem(this.curr_prt_name_storage_key);
    }

    updateParticipantStatus(currPrtId, proxyId, proxyAuthId, email, status) {
        var dataToSend = {
            currentParticipantID: currPrtId,
            proxyID: proxyId,
            proxyAuthID: proxyAuthId,
            username: email,
            status: status,
        };

        const req: any = { data: dataToSend };
        return this.reqSvc.post(env.API.updateParticipantStatus, req);
    }
}
