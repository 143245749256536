import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeployManagementRoutingModule } from './deploy-management-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@h20-shared/shared.module';
import { DeployTrackingComponent } from './deploy-tracking/deploy-tracking.component';
import { ManageConfigComponent } from './manage-config/manage-config.component';
import { ManageConfigDetailComponent } from './manage-config-detail/manage-config-detail.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';

@NgModule({
    declarations: [DeployTrackingComponent, ManageConfigComponent, ManageConfigDetailComponent],
    imports: [
        CommonModule,
        DeployManagementRoutingModule,
        NgxDatatableModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        NgJsonEditorModule,
    ],
})
export class DeployManagementModule {}
