<app-modal
    *ngIf="openEditModal"
    [(visible)]="openEditModal"
    [context]="this"
    [saveFunc]="saveEditModal"
    [saveBtnDisabled]="!surveyForm.valid"
    [saveBtnText]="'Surveys.Save'"
    [showCloseBtn]="false"
    [closeFunc]="closeModal"
    [centerModal]="false"
    [appBtnWidth]="'97%'"
    [btnWidth]="'97%'"
    [modalWidth]="'35vw'"
    [modalHeight]="'95vh'"
    id="editSurveyModal"
    class="dynamic-panel-modal"
>
    <div class="card-body p-0 px-1" [formGroup]="surveyForm">
        <div class="container-fluid px-0">
            <p
                style="
                    color: var(--colors-text-text-primary-900, #101828);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px;
                    margin-bottom: 0px;
                "
            >
                Enter survey details
            </p>
            <p class="description">
                Required fields are noted with a <span class="required">*</span>
            </p>

            <div class="form-group row">
                <div class="col p-0">
                    <p class="input-title">Survey title <span class="required">*</span></p>
                    <input
                        aria-label="Survey Title"
                        placeholder="Enter a title"
                        type="text"
                        class="form-control"
                        [value]="surveyForm.get('title')?.value"
                        (change)="updateValue('title', $event.target.value)"
                        id="title"
                        formControlName="title"
                        [ngClass]="{
                            'is-invalid':
                                surveyForm.get('title')?.invalid &&
                                (surveyForm.get('title')?.touched ||
                                    surveyForm.get('title')?.dirty),
                        }"
                        required
                    />
                    <p
                        class="description mt-1"
                        [ngClass]="{
                            'text-danger':
                                surveyForm.get('title')?.invalid &&
                                (surveyForm.get('title')?.touched ||
                                    surveyForm.get('title')?.dirty),
                        }"
                    >
                        {{
                            surveyForm.get('title')?.invalid &&
                            (surveyForm.get('title')?.touched || surveyForm.get('title')?.dirty)
                                ? 'Please enter a title to create your survey'
                                : 'This is displayed in Composer to help you locate your survey'
                        }}
                    </p>
                </div>
            </div>

            <div class="form-group row mt-2">
                <div class="col p-0">
                    <p class="input-title">Survey display name <span class="required">*</span></p>
                    <input
                        aria-label="Survey Display Name"
                        placeholder="Enter a display name"
                        type="text"
                        class="form-control"
                        id="name"
                        formControlName="name"
                        [value]="surveyForm.get('name')?.value"
                        (change)="updateValue('name', $event.target.value)"
                        [ngClass]="{
                            'is-invalid':
                                surveyForm.get('name')?.invalid &&
                                (surveyForm.get('name')?.touched || surveyForm.get('name')?.dirty),
                        }"
                    />
                    <p
                        class="description mt-1"
                        [ngClass]="{
                            'text-danger':
                                surveyForm.get('name')?.invalid &&
                                (surveyForm.get('name')?.touched || surveyForm.get('name')?.dirty),
                        }"
                        style="min-height: 40px"
                    >
                        {{
                            surveyForm.get('name')?.invalid &&
                            (surveyForm.get('name')?.touched || surveyForm.get('name')?.dirty)
                                ? 'Please enter a display name if you want it different from the survey title'
                                : 'This is the name that will be displayed to users in Healthie. Enter a name if you want this to be different from the survey title.'
                        }}
                    </p>
                </div>
            </div>

            <div class="form-group row mt-2">
                <div class="col p-0">
                    <p class="input-title">Description</p>
                    <textarea
                        aria-label="Description"
                        placeholder="e.g. This survey will be used to collect your demographics"
                        class="form-control"
                        id="description"
                        formControlName="description"
                        [value]="surveyForm.get('description')?.value"
                        (change)="updateValue('description', $event.target.value)"
                    ></textarea>
                    <p class="description mt-1">
                        This description will be displayed to users in Healthie to give them
                        additional information regarding the content of the survey.
                    </p>
                </div>
            </div>

            <div class="form-group row mt-2">
                <div class="col p-0">
                    <p class="input-title">Copyright attribution</p>
                    <textarea
                        aria-label="Copyright"
                        placeholder="e.g. (c) Pulse Inforname. This survey is a trademark of Pulse Inforname"
                        type="text"
                        class="form-control"
                        id="endNotes"
                        formControlName="endNotes"
                        [value]="surveyForm.get('endNotes')?.value"
                        (change)="updateValue('endNotes', $event.target.value)"
                    ></textarea>
                    <p class="description mt-1">
                        Enter a copyright or attribution if you are using a validated instrument or
                        copyrighted survey tool.
                    </p>
                </div>
            </div>

            <div class="form-group row mt-2">
                <div class="col p-0">
                    <p class="input-title">Registry</p>
                    <select
                        aria-label="Registry"
                        class="form-control w-50"
                        id="registry"
                        [value]="surveyUnderConstruction.registry?.[0]"
                        (change)="updateRegistryValue('registry', $event.target.value)"
                    >
                        <option value="surveyUnderConstruction.registry?.[0]" selected>
                            {{ surveyUnderConstruction.registry?.[0] }}
                        </option>
                        <option *ngFor="let registry of getFilteredRegistries()" [value]="registry">
                            {{ registry }}
                        </option>
                    </select>
                    <p class="description mt-1">
                        Select which registry this survey will be applied to.
                    </p>
                </div>
            </div>
        </div>
    </div>
</app-modal>

<div *ngIf="surveyUnderConstruction">
    <div class="w-100">
        <div class="nav nav-tabs">
            <div class="nav-item">
                <a
                    href="#nav-designer"
                    aria-controls="nav-designer"
                    class="nav-link active"
                    id="nav-design-tab"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected="true"
                    (click)="clickDesign()"
                >
                    Design</a
                >
            </div>
            <div class="nav-item">
                <a
                    href="#nav-test"
                    aria-controls="nav-test"
                    class="nav-link"
                    id="nav-test-tab"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    (click)="clickTest()"
                    >Test</a
                >
            </div>
            <div class="nav-item" *ngIf="userClaims && userClaims.h20ComposerAdmin">
                <a
                    href="#nav-json"
                    aria-controls="nav-json"
                    class="nav-link"
                    id="nav-json-tab"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    (click)="clickJsonEditor()"
                    >Edit Json</a
                >
            </div>
            <div class="nav-item">
                <a
                    href="#nav-rtv"
                    aria-controls="nav-rtv"
                    class="nav-link"
                    id="nav-rtv-tab"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    (click)="clickRtv()"
                    >RTV Prototype</a
                >
            </div>
            <div class="ms-auto">
                <div class="d-flex">
                    <app-button
                        (action)="backToSurveys()"
                        [buttonText]="'Registry.BackToSurveys'"
                    />
                    <app-button
                        class="mx-3"
                        (action)="openEditDetailModal()"
                        [buttonText]="'Surveys.EditDetail'"
                    />
                    <app-button
                        class="me-3"
                        [disabled]="loading"
                        (action)="saveSurvey()"
                        [buttonText]="'Registry.SaveSurvey'"
                    />
                    <div *ngIf="saveSurveyMessage" class="text-muted my-auto">
                        {{ saveSurveyMessage }}
                    </div>
                </div>
            </div>
            <div class="ms-auto">
                <h5>{{ getText(surveyUnderConstruction.title) }}</h5>
            </div>
        </div>

        <div class="tab-content" id="nav-tabContent">
            <div
                class="tab-pane active show"
                id="nav-designer"
                role="tabpanel"
                aria-labelledby="nav-designer-tab"
            >
                <app-survey-designer
                    *mcRerender="update"
                    [survey]="surveyUnderConstruction"
                ></app-survey-designer>
            </div>
            <div class="tab-pane" id="nav-test" role="tabpanel" aria-labelledby="nav-test-tab">
                <app-survey-tester
                    *mcRerender="update"
                    [survey]="surveyUnderConstruction"
                ></app-survey-tester>
            </div>
            <div class="tab-pane" id="nav-json" role="tabpanel" aria-labelledby="nav-json-tab">
                <json-editor
                    [options]="jsonEditorOptions"
                    [data]="jsonSurveyUnderConstruction"
                    (change)="updateJsonEditorSurvey($event)"
                ></json-editor>
            </div>
            <div class="tab-pane" id="nav-rtv" role="tabpanel" aria-labelledby="nav-rtv-tab">
                <app-survey-rtv
                    *mcRerender="update"
                    [survey]="surveyUnderConstruction"
                ></app-survey-rtv>
            </div>
        </div>
    </div>
</div>
