import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-pi-form-view-response',
    templateUrl: './pi-form-view-response.component.html',
    styleUrls: ['./pi-form-view-response.component.scss'],
})
export class PiFormViewResponseComponent implements OnInit {
    @Input() element: any;
    @Input() theFormControl: UntypedFormControl;
    @Input() displayValue: string;
    @Input() download: string;
    @Input() isRestrictedDownload: boolean;
    @Input() surveyId: string;
    showDownload: boolean = true;
    constructor(protected translate: TranslateService) {}

    @Output() downloadButtonClicked: EventEmitter<string> = new EventEmitter<string>();

    downloadClicked(item: any) {
        this.downloadButtonClicked.emit(item);
    }

    ngOnInit(): void {
        if (this.surveyId == 'b88c9936-09a2-4c5d-837c-f84a346b8456') {
            // UVEAL add patient survey
            this.showDownload = false;
        }
    }
}
