import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@h20-services/common.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import Highcharts from 'highcharts/highmaps';
import { BaseTileComponent } from '../base-tile/base-tile.component';

@Component({
    selector: 'app-map-tile',
    templateUrl: './map-tile.component.html',
    styleUrls: ['./map-tile.component.scss'],
})
export class MapTileComponent extends BaseTileComponent implements OnInit {
    @Input() tile;

    Highcharts;
    chartConstructor: string;
    chartOptions;
    // TODO: Need to allow more maps. Was having troubles with dynamically requiring the map.
    usandca = require('@highcharts/map-collection/custom/usa-and-canada.topo.json');
    world = require('@highcharts/map-collection/custom/world.topo.json');
    maps = { usandca: this.usandca, world: this.world };

    constructor(
        com_svc: CommonService,
        private translate: TranslateService
    ) {
        super(com_svc);
    }

    ngOnInit(): void {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.reloadChart();
        });

        this.Highcharts = Highcharts;
        this.chartConstructor = 'mapChart';
        this.reloadChart();
    }

    getText = this.com_svc.getText;

    reloadChart() {
        this.chartOptions = this.Highcharts.Options = {
            chart: {
                map: this.maps[this.tile.map],
            },
            title: {
                text: this.getText(this.tile.title),
            },
            subtitle: {
                text: this.getText(this.tile.subTitle),
                useHTML: true,
            },
            mapNavigation: this.tile.mapNavigation,
            legend: {
                enabled: this.tile.enableLegend,
            },
            colorAxis: {
                min: 0,
                minColor: this.tile.minColor,
                maxColor: this.tile.maxColor,
            },
            series: [
                {
                    type: 'map',
                    name: this.getText(this.tile.title),
                    states: {
                        hover: {
                            color: this.tile.hoverColor,
                        },
                    },
                    dataLabels: {
                        enabled: this.tile.enableDataLabels,
                        format: '{point.name}',
                    },
                    allAreas: false,
                    data: this.tile.data,
                },
            ],
            credits: {
                enabled: false,
            },
        };
    }
}
