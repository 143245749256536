<div>
    <h1 class="m-3">Translation Export</h1>
    <p class="m-3" style="color: #0d6efd">
        After exporting content, reach out to a external provider, for translations.
    </p>
    <div *ngIf="loading">
        <h4 class="mt-3 text-center">
            <i attr.aria-label="Loading" class="fas fa-spinner fa-spin"></i>
            Loading
        </h4>
        <p>Waiting on:</p>
        <ul>
            <li *ngIf="loadingRegConfig">Registry_config</li>
            <li *ngIf="loadingSES">Email Templates</li>
            <li *ngIf="loadingTasks">Tasks</li>
            <li *ngIf="loadingPlaformSTR">Platform Strings</li>
            <li *ngIf="loadingSurveys">Surveys</li>
        </ul>
    </div>

    <div *ngIf="!loading" class="form-group mx-3">
        <!-- Filters -->
        <div class="row">
            <div *ngIf="selectedRegistry" class="col mx-5">
                <p>The Selected registry is set to support the following languages:</p>
                {{ selectedRegistry.languages }}
                <div *ngIf="!selectedRegistry.languages">[No languages defined]</div>
            </div>
            <div class="col text-center">
                <div class="row">
                    <div class="col">
                        <small
                            >Platform strings are only exported if exporting ALL registry
                            data.</small
                        >
                        <small>If you want them you need to export them separately</small>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            <app-button
                                (action)="buildAllExport()"
                                [buttonText]="'Registry.ExportAll'"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col text-center">
                <app-button
                    (action)="buildAllSelectedExport()"
                    [buttonText]="'Registry.ExportAllSelected'"
                />
            </div>
        </div>
        <div class="row" *ngIf="selectedRegistry.registry_id !== 'All'">
            <p>Content:</p>
            <div *ngFor="let item of translationItems" class="col mx-3">
                <label class="form-control"
                    ><input
                        type="checkbox"
                        (change)="contentSelectionChanged(item, $event.target.checked)"
                        class="mx-3"
                    />{{ item }}</label
                >
                <p class="mt-2">{{ ItemDescriptions[item] }}</p>
            </div>
        </div>
        <br />
        <hr />
        <div class="row">
            <app-loading-component [loading]="loadingExport"></app-loading-component>
        </div>

        <div
            class="accordion mt-3"
            id="platformStringsAccordion"
            *ngIf="sectionItems['Platform-Strings']"
        >
            <div class="accordion-item">
                <h2 class="accordion-header" id="platformStringsHeading">
                    <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#platformStringsCollapse"
                        aria-expanded="true"
                        aria-controls="platformStringsCollapse"
                    >
                        <h4>Platform Strings</h4>
                    </button>
                </h2>
                <div
                    id="platformStringsCollapse"
                    class="accordion-collapse collapse show"
                    aria-labelledby="platformStringsHeading"
                    data-bs-parent="#platformStringsAccordion"
                >
                    <div class="accordion-body">
                        <div *ngIf="sectionItems['Platform-Strings']" class="mt-3">
                            <div class="row mb-3">
                                <h5 class="col">Available Platform Languages:</h5>
                                <label class="col mx-3">
                                    <input
                                        type="checkbox"
                                        class="mx-3"
                                        (change)="selectAllPlatformStrings($event.target.checked)"
                                    />
                                    Select All
                                </label>
                                <div class="col mx-3">
                                    <app-button
                                        (action)="buildPlatformStringsExport(false)"
                                        [buttonText]="'Registry.DownloadAll'"
                                    ></app-button>
                                </div>
                                <div class="col mx-3">
                                    <app-button
                                        (action)="buildPlatformStringsExport()"
                                        [buttonText]="'Registry.DownloadSelected'"
                                    ></app-button>
                                </div>
                            </div>
                            <label
                                *ngFor="let str of listPlatformStrings()"
                                class="form-control col mx-3"
                            >
                                <input
                                    type="checkbox"
                                    class="mx-3"
                                    (change)="changePlatformStrings(str, $event.target.checked)"
                                    [checked]="platformStringsSelection[str]"
                                />
                                {{ str }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="accordion mt-3"
            id="emailTemplatesAccordion"
            *ngIf="sectionItems['Email-Templates']"
        >
            <div class="accordion-item">
                <h2 class="accordion-header" id="emailTemplatesHeading">
                    <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#emailTemplatesCollapse"
                        aria-expanded="true"
                        aria-controls="emailTemplatesCollapse"
                    >
                        <h4>Email Templates</h4>
                    </button>
                </h2>
                <div
                    id="emailTemplatesCollapse"
                    class="accordion-collapse collapse show"
                    aria-labelledby="emailTemplatesHeading"
                    data-bs-parent="#emailTemplatesAccordion"
                >
                    <div class="accordion-body">
                        <div class="row mb-3">
                            <h5 class="col">Available Email Templates:</h5>
                            <label class="col mx-3">
                                <input
                                    type="checkbox"
                                    class="mx-3"
                                    (change)="selectAllEmailTemplates($event.target.checked)"
                                />Select All
                            </label>
                            <div class="col">
                                <app-button
                                    (action)="buildEmailTemplateExport()"
                                    [buttonText]="'Registry.ExportSelected'"
                                />
                            </div>
                        </div>
                        <label
                            *ngFor="let emailTemplate of listEmailTemplates()"
                            class="form-control col mx-3"
                        >
                            <input
                                type="checkbox"
                                class="mx-3"
                                (change)="
                                    changeEmailTemplate(
                                        emailTemplate.template_name,
                                        $event.target.checked
                                    )
                                "
                                [checked]="sesTemplateSelection[emailTemplate.template_name]"
                            />{{ emailTemplate.template_name }}
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion mt-3" id="taskPrototypesAccordion" *ngIf="sectionItems['Tasks']">
            <div class="accordion-item">
                <h2 class="accordion-header" id="taskPrototypesHeading">
                    <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#taskPrototypesCollapse"
                        aria-expanded="true"
                        aria-controls="taskPrototypesCollapse"
                    >
                        <h4>Task Prototypes</h4>
                    </button>
                </h2>
                <div
                    id="taskPrototypesCollapse"
                    class="accordion-collapse collapse show"
                    aria-labelledby="taskPrototypesHeading"
                    data-bs-parent="#taskPrototypesAccordion"
                >
                    <div class="accordion-body">
                        <div class="row mb-3">
                            <h5 class="col">Available Task Prototypes:</h5>
                            <label class="col mx-3">
                                <input
                                    type="checkbox"
                                    class="mx-3"
                                    (change)="selectAllTaskPrototypes($event.target.checked)"
                                />Select All
                            </label>
                            <div class="col">
                                <app-button
                                    (action)="buildTaskPrototypeExport()"
                                    [buttonText]="'Registry.ExportSelected'"
                                />
                            </div>
                        </div>
                        <label
                            *ngFor="let task of listTaskPrototypes()"
                            class="form-control col mx-3"
                        >
                            <input
                                type="checkbox"
                                class="mx-3"
                                (change)="changeTaskPrototype(task.id, $event.target.checked)"
                                [checked]="taskPrototypeSelection[task.id]"
                            />{{ task.label }}
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion mt-3" id="surveysAccordion" *ngIf="sectionItems['Surveys']">
            <div class="accordion-item">
                <h2 class="accordion-header" id="surveysHeading">
                    <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#surveysCollapse"
                        aria-expanded="true"
                        aria-controls="surveysCollapse"
                    >
                        <h5>Surveys</h5>
                    </button>
                </h2>
                <div
                    id="surveysCollapse"
                    class="accordion-collapse collapse show"
                    aria-labelledby="surveysHeading"
                    data-bs-parent="#surveysAccordion"
                >
                    <div class="accordion-body">
                        <div class="row mb-3">
                            <h3 class="col">Available Surveys:</h3>
                            <label class="col mx-3">
                                <input
                                    type="checkbox"
                                    class="mx-3"
                                    (change)="selectAllSurveys($event.target.checked)"
                                />Select All
                            </label>
                            <div class="col">
                                <app-button
                                    (action)="buildSurveyExport()"
                                    [buttonText]="'Registry.ExportSelected'"
                                />
                            </div>
                        </div>
                        <label *ngFor="let survey of listSurveys()" class="form-control col mx-3">
                            <input
                                type="checkbox"
                                class="mx-3"
                                (change)="changeSurveys(survey.id, $event.target.checked)"
                                [checked]="surveySelection[survey.id]"
                            />{{ getText(survey.title) }}
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="accordion mt-3"
            id="registryConfigAccordion"
            *ngIf="sectionItems['Registry-Configuration-Data']"
        >
            <div class="accordion-item">
                <h2 class="accordion-header" id="registryConfigHeading">
                    <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#registryConfigCollapse"
                        aria-expanded="true"
                        aria-controls="registryConfigCollapse"
                    >
                        <h4>Registry Configuration</h4>
                    </button>
                </h2>
                <div
                    id="registryConfigCollapse"
                    class="accordion-collapse collapse show"
                    aria-labelledby="registryConfigHeading"
                    data-bs-parent="#registryConfigAccordion"
                >
                    <div class="accordion-body">
                        <div *ngIf="selectedRegistry.registry_id === 'All'">
                            This section doesnt make sense to render for all registries
                        </div>
                        <div *ngIf="selectedRegistry.registry_id !== 'All'">
                            <div class="row mb-3">
                                <h5 class="col">Registry Configuration Content:</h5>
                                <div class="col">
                                    <app-button
                                        (action)="buildRegistryConfigurationExport()"
                                        [buttonText]="'Registry.ExportSelected'"
                                    />
                                </div>
                            </div>
                            <label
                                *ngFor="let reg of listRegistryConfigKeys()"
                                class="form-control col mx-3"
                            >
                                <input
                                    type="checkbox"
                                    class="mx-3"
                                    (change)="changeRegistryConfig(reg, $event.target.checked)"
                                    [checked]="registryConfigurationSelection[reg]"
                                />{{ reg }}
                            </label>
                            <json-editor
                                *ngIf="userClaims && userClaims.h20ComposerAdmin"
                                [options]="jsonEditorOptions"
                                [data]="getRegistryConfigJson()"
                            ></json-editor>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
