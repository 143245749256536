import { HttpClient } from '@angular/common/http';
import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnChanges,
    ChangeDetectorRef,
} from '@angular/core';
import { SurveyService } from '@h20-services/survey.service';
import { firstValueFrom } from 'rxjs';
import { ElementDesignerConfigService } from './element-designer-config.service';

@Component({
    selector: 'app-element-designer',
    templateUrl: './element-designer.component.html',
    styleUrls: ['./element-designer.component.scss'],
})
export class ElementDesignerComponent implements OnChanges {
    constructor(
        private surveyService: SurveyService,
        private designerConfigService: ElementDesignerConfigService,
        private changeDetector: ChangeDetectorRef
    ) {}
    @Input() element: any; //this elements
    @Input() elements: any; //all elements (for validators that reference other elements, etc.)
    @Output() elementChange = new EventEmitter<any>();

    designerForm;
    hotReload = false;

    options = {
        condensed: true,
    };

    ngOnChanges(): void {
        firstValueFrom(this.designerConfigService.getFullConfig(this.element.type))
            .then((valForm) => {
                this.designerForm = this.surveyService.concatenateSurveys('Element Form', valForm);
                this.doHotReload();
            })
            .catch((reason) => {
                console.error('Failed to load SB configurator survey config for some reason?');
                console.error(reason);
            });
    }

    /**
     * forces the inner piform to be unloaded and reloaded
     * because otherwise the form doesn't reload its properties (only happens in oninit, not onchanges)
     * this is because surveybuilder form components aren't designed to be updated
     * this is a bad hack that will cause problems later probably
     * but rearchitecting everything ain't happening today
     */
    doHotReload(): void {
        this.hotReload = true;
        this.changeDetector.detectChanges();
        this.hotReload = false;
    }

    onFormChanges(event: any) {
        this.element = Object.assign(this.element, this.nonNullValues(event.value));
        this.elementChange.emit(this.element);
    }
    nonNullValues(obj: any): any {
        return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== null));
    }
}
