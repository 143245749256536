import { Injectable } from '@angular/core';
import { environment as env } from '@environment/environment';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root',
})
export class MessageCenterService {
    constructor(private reqSvc: RequestService) {}

    /* ----------------------------- CRUD For Tasks --------------------------------- */

    updateTask(id, status, survey_submission_ID?): any {
        var dataToSend = {
            id: id,
            status: status,
        };
        if (survey_submission_ID) dataToSend['survey_submission_ID'] = survey_submission_ID;
        const req: any = { operation: 'updateTask', data: dataToSend };
        return this.reqSvc.post(env.API.writeToDBTask, req);
    }

    updateTaskStatusBySurvey(status, survey_ID, user_ID, survey_submission_ID): any {
        var dataToSend = {
            status: status,
            survey_ID: survey_ID,
            user_ID: user_ID,
            survey_submission_ID: survey_submission_ID,
        };

        const req: any = {
            operation: 'updateTaskStatusBySurvey',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBTask, req);
    }

    listTasks(userId: any): any {
        var dataToSend = {
            user_ID: userId.user_ID.eq,
        };
        const req: any = { operation: 'listTasks', data: dataToSend };
        return this.reqSvc.post(env.API.writeToDBTask, req);
    }

    // // Chaining will flatten trees, but only single path chains are expected atm.
    // orderTasksByChaining(tasks: Task[], root?: Task) {
    //     // Spoofing because of db errors on saving next_ID
    //     return [
    //         'Identification',
    //         'Demographics',
    //         'Diagnostics',
    //         'Lifestyle',
    //         'Kidney Disease',
    //         'EuroQ',
    //     ].map((name: string) => {
    //         return tasks.find((task: Task) => {
    //             return task.label.indexOf(name) != -1;
    //         });
    //     });

    //     // return tasks.sort((a, b) => {
    //     //   if (a.next_ID === b.id || !a.previous_ID || a.id === b.previous_ID) {
    //     //     return -1;
    //     //   } else {
    //     //     return 1;
    //     //   }
    //     // });
    // }

    chainTasksByOrder(tasks: any): void {
        tasks.map((task: any, i: number) => {
            task.previous_ID = i === 0 ? null : tasks[i - 1].id;
            task.next_ID = i === tasks.length - 1 ? null : tasks[i + 1].id;
        });
    }

    flatten(arr, result = []) {
        for (let i = 0, length = arr.length; i < length; i++) {
            const value = arr[i];
            if (Array.isArray(value)) {
                this.flatten(value, result);
            } else {
                result.push(value);
            }
        }
        return result;
    }

    getTask(id: any): any {
        var dataToSend = {
            id: id,
        };
        const req: any = { operation: 'getTask', data: dataToSend };
        return this.reqSvc.post(env.API.writeToDBTask, req);
    }
}
