import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-form-list-editor',
    templateUrl: './form-list-editor.component.html',
    styleUrls: ['./form-list-editor.component.scss'],
})
export class FormListEditorComponent implements OnInit {
    @Input() model: any;
    @Input() field: string;
    @Input() min: number;
    @Input() max: number;

    @Output() modelChange = new EventEmitter();

    newField: any;

    constructor() {}

    ngOnInit(): void {
        return;
    }

    addField() {
        if (!this.model[this.field]) {
            this.model[this.field] = [];
        }

        this.model[this.field].push({});
        this.newField = this.model[this.field][this.model[this.field].length];
    }

    removeField(item) {
        this.model[this.field].splice(this.model[this.field].indexOf(item), 1);

        if (this.model[this.field].length === 0) {
            delete this.model[this.field];
        }
    }
}
