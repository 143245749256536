import { Component, OnDestroy, OnInit } from '@angular/core';
import { SurveyService } from '@h20-services/survey.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { saveAs } from 'file-saver';
import { CustomFilterComponent } from '@h20-shared/table-component/custom-filter/custom-filter.component';
import { ToastService } from '@h20-shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '@h20-services/environment.service';
@Component({
    selector: 'app-deploy-survey',
    templateUrl: './deploy-survey.component.html',
    styleUrls: ['./deploy-survey.component.scss'],
})
export class DeploySurveyComponent implements OnInit, OnDestroy {
    sourceEnvs = this.com_svc.listSourceEnvs();
    targetEnvs = this.com_svc.listTargetEnvs();
    message: string;
    loading = false;
    deployLoading = false;
    tempSurveys: any[] = [];
    surveys;
    destinationSurveys;
    subscription;
    sourceSite = '';
    deploySite = '';
    availableRegistries = [];
    currentSite: any;
    destinationSite: any;

    registries = [];
    selectedRegistryId: string = '';
    selectedPublishSurveyId: string;
    selectedPublishSurveyTitle: string;
    selectedPIIQuestionKeys = [];
    showPublishModal: boolean;
    showSelectRegistryModal: boolean;
    showConfirmQuestionKeyModal: boolean;
    loadingConfirmQuestionKey: boolean = false;
    flatElementList: any[] = [];
    colDefsQKey = null;

    constructor(
        private svc: SurveyService,
        private fb: FormBuilder,
        private com_svc: CommonService,
        private toastService: ToastService,
        public translate: TranslateService,
        private environmentSvc: EnvironmentService
    ) {
        this.environmentSvc.currentSource.subscribe((environment) => {
            this.sourceSite = environment;
            this.changeSite();
        });
        this.environmentSvc.currentDestination.subscribe((environment) => {
            this.deploySite = environment;
            this.changeDeploySite();
        });
        this.environmentSvc.currentRegistry.subscribe((registry) => {
            this.selectedRegistryId = registry;
            this.registryFilter(registry);
        });
    }

    togglePane = '';
    listOfUnmachtedSurveyIds: any = [];
    isRegistrySelected = false;
    colDefs = this.getTableColumns();

    ngOnInit(): void {
        this.surveys = [];
    }

    getTableColumns() {
        return [
            {
                headerName: 'title',
                field: 'title',
                valueFormatter: (params) => {
                    if (params?.value?.default) {
                        return params.value['en'] ?? params.value['default'];
                    }
                    return params.value;
                },
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'GUID',
                field: 'id',
                cellDataType: 'textWithIcon',
                cellRendererParams: (params) => {
                    return {
                        iconPosition: 'after',
                        btnClass: 'ms-1 btn btn-outline-secondary btn-sm',
                        iconClass: 'far fa-copy',
                        iconText: params?.value?.substring(0, 8),
                        onClick: (value) => {
                            navigator.clipboard.writeText(value);
                        },
                    };
                },
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Modified',
                field: 'modified',
            },
            {
                headerName: 'Registries',
                field: 'registry',
                filter: CustomFilterComponent,
                filterParams: {
                    options: this.availableRegistries,
                },
            },
            {
                headerName: 'action',
                field: 'id',
                cellDataType: 'actionBtn',
                cellRendererParams: (params) => {
                    return {
                        isVisible: true,
                        buttonText: 'Deploy',
                        btnDisabled: !this.isRegistrySelected,
                        onClick: () => this.deploySurveyById(params.data),
                    };
                },
            },
            {
                headerName: 'publish metadata',
                field: 'id',
                cellDataType: 'actionBtn',
                cellRendererParams: (params) => {
                    return {
                        isVisible: true,
                        buttonText: 'Publish',
                        btnDisabled: !this.isRegistrySelected,
                        onClick: () => this.openPublishSurveyModal(params.data),
                    };
                },
            },
            {
                headerName: 'hashCode(Source)',
                field: 'hashCode',
                cellDataType: 'iconBtns',
                cellRendererParams: (params) => {
                    return {
                        text: params?.value?.substring(0, 8),
                        actions: [
                            {
                                isVisible: this.isRegistrySelected,
                                label: 'Copy to clipboard',
                                iconClass: 'far fa-copy',
                                btnClass: 'ms-1 btn btn-outline-secondary btn-sm',
                                onClick: () => navigator.clipboard.writeText(params.value),
                            },
                            {
                                isVisible: this.isRegistrySelected,
                                label: 'Download survey',
                                iconClass: 'fas fa-download',
                                btnClass: 'ms-4 btn btn-secondary',
                                onClick: () =>
                                    this.downloadSurveyById(
                                        params.data,
                                        this.currentSite,
                                        this.sourceSite
                                    ),
                            },
                        ],
                    };
                },
            },
            {
                headerName: 'hashCode(Dest)',
                field: 'hashCodeDest',
                cellDataType: 'iconBtns',
                cellRendererParams: (params) => {
                    return {
                        text: params?.value?.substring(0, 8),
                        actions: [
                            {
                                isVisible:
                                    this.isRegistrySelected &&
                                    params?.value?.substring(0, 8) != 'N/A',
                                label: 'Copy to clipboard',
                                iconClass: 'far fa-copy',
                                btnClass: 'ms-1 btn btn-outline-secondary btn-sm',
                                onClick: () => navigator.clipboard.writeText(params.value),
                            },
                            {
                                isVisible:
                                    this.isRegistrySelected &&
                                    params?.value?.substring(0, 8) != 'N/A',
                                label: 'Download survey',
                                iconClass: 'fas fa-download',
                                btnClass: 'ms-4 btn btn-secondary',
                                onClick: () =>
                                    this.downloadSurveyById(
                                        params.data,
                                        this.destinationSite,
                                        this.deploySite
                                    ),
                            },
                        ],
                    };
                },
            },
        ];
    }

    getSurveys(site): any {
        this.loading = true;
        this.svc.getSurveys_DeployVersion(site).subscribe((result: any) => {
            this.surveys = result.map((s: any) => {
                if (
                    s.registry &&
                    Array.isArray(s.registry) &&
                    s.registry.some((r) => this.availableRegistries.includes(r))
                ) {
                    this.availableRegistries.push(
                        ...s.registry.filter((r) => !this.availableRegistries.includes(r))
                    );
                }

                if (s.json) {
                    return s;
                } else {
                    return s;
                }
            });
            this.tempSurveys = [...this.surveys];

            if (!this.surveys) {
                // Nothing in the database, so use what is in local storage
                this.surveys = [];
                this.subscription = this.svc.storage.keys().subscribe((results: any) => {
                    if (results) {
                        this.svc.storage.get(results).subscribe((survey: any) => {
                            this.surveys.push(JSON.parse(survey));
                            this.surveys = [...this.surveys];
                            this.tempSurveys = [...this.surveys];
                        });
                    }
                });
            }
            this.registryFilter(this.selectedRegistryId);
            this.loading = false;
        });
    }

    async registryFilter(selectedReg: string) {
        if (!this.destinationSite) {
            alert('Please Select Destination Env');
            return;
        }
        this.surveys = this.tempSurveys.filter(
            (s) =>
                (s.registry && s.registry.includes(selectedReg.toLowerCase())) ||
                selectedReg === 'All'
        );
        this.loading = true;
        let items = this.surveys;
        let surveyIds = [];
        items.map((el) => {
            surveyIds.push(el.id);
        });
        this.loading = false;
        if (surveyIds.length == 0) {
            return;
        }
        items = await this.svc.getSurveysByIds(surveyIds).toPromise();
        this.surveys.map((survey) => {
            survey.hashCode = items.find((e) => e.id === survey.id)?.hashCode;
        });
        this.destinationSurveys = await this.svc
            .getSurveysByIds_Site(surveyIds, this.destinationSite)
            .toPromise();
        let desSurvey = null;
        this.surveys.map((ent) => {
            desSurvey = this.destinationSurveys.find((e) => e.id === ent.id)?.hashCode;
            ent.hashCodeDest = desSurvey ? desSurvey : 'N/A';
        });
        this.isRegistrySelected = true;
        this.loading = false;
        this.colDefs = this.getTableColumns();
    }

    onPage($event): void {}

    changeSite(): void {
        this.message = '';
        this.currentSite = this.com_svc.getTargetEnv(this.sourceSite);
        this.getSurveys(this.currentSite);
        if (this.selectedRegistryId) this.registryFilter(this.selectedRegistryId);
    }

    changeDeploySite(): void {
        this.destinationSite = this.com_svc.getTargetEnv(this.deploySite);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    getText(strOrLangs): string {
        if (!strOrLangs) return '';
        return strOrLangs['en'] || strOrLangs['default'] || strOrLangs;
    }

    deploySurveyById(row): void {
        if (this.deploySite == '') {
            alert('Please select deploy env.');
            return;
        }
        if (this.sourceSite == this.deploySite) {
            alert('Please select a deploy env that is different from source env.');
            return;
        }
        if (confirm(`Are you sure you want to deploy?`)) {
            this.deployLoading = true;
            this.svc
                .getSurveyById_Deploy(row.id, this.currentSite)
                .toPromise()
                .then((result: any) => {
                    if (result[0]) {
                        let currentSurvey = result[0];
                        this.svc
                            .getSurveyById_Deploy(row.id, this.destinationSite)
                            .toPromise()
                            .then((destResult: any) => {
                                if (destResult && destResult.length > 0) {
                                    currentSurvey['deploy_mode'] = 'update';
                                } else {
                                    currentSurvey['deploy_mode'] = 'create';
                                }

                                this.svc
                                    .saveSurvey_DeployVersion(currentSurvey, this.destinationSite)
                                    .toPromise()
                                    .then((res: any) => {
                                        this.currentSite = this.com_svc.getTargetEnv(
                                            this.sourceSite
                                        );
                                        this.setDeployErrorHandler('Deployed successfully.');
                                    })
                                    .catch((error: Error) => {
                                        this.setDeployErrorHandler('An error occurs.', error);
                                    });
                            })
                            .catch((error: Error) => {
                                this.setDeployErrorHandler('An error occurs.', error);
                            });
                    } else {
                        this.deployLoading = false;
                        this.message = `Cannot load the survey`;
                    }
                })
                .catch((error: Error) => {
                    this.setDeployErrorHandler('An error occurs.', error);
                });
        }
    }

    async deployByRegistry(): Promise<void> {
        this.surveys.map((ent) => {
            let tempEnt = this.destinationSurveys.find((en) => en.id === ent.id);
            if (tempEnt) {
                if (ent.hashCode !== tempEnt.hashCode) {
                    this.listOfUnmachtedSurveyIds.push(ent.id);
                }
            }
        });
        if (this.sourceSite == '' || this.deploySite == '' || this.selectedRegistryId == '') {
            alert('Please select source env, deploy env and registry');
            return;
        }
        if (this.sourceSite == this.deploySite) {
            alert('Please select a deploy env that is different from source env.');
            return;
        }
        if (confirm(`Are you sure you want to deploy by registry?`)) {
            this.deployLoading = true;
            this.surveys = this.tempSurveys.filter(
                (s) =>
                    (s.registry && s.registry.includes(this.selectedRegistryId.toLowerCase())) ||
                    this.selectedRegistryId === 'All'
            );
            let surveyIds = [];
            this.surveys.map((el) => {
                surveyIds.push(el.id);
            });
            let items = await this.svc.getSurveysByIds(surveyIds).toPromise();
            let countItems = 0;
            if (items.length > 0) {
                items.forEach((element) => {
                    let currentSurvey = element;
                    this.svc
                        .getSurveyById_Deploy(currentSurvey.id, this.destinationSite)
                        .toPromise()
                        .then((destResult: any) => {
                            if (destResult && destResult.length > 0) {
                                currentSurvey['deploy_mode'] = 'update';
                            } else {
                                currentSurvey['deploy_mode'] = 'create';
                            }
                            this.svc
                                .saveSurvey_DeployVersion(currentSurvey, this.destinationSite)
                                .toPromise()
                                .then((res: any) => {
                                    ++countItems;
                                    if (countItems == items.length) {
                                        this.deployLoading = false;
                                        this.message = `Deployed successfully ${this.selectedRegistryId} to ${this.deploySite}`;
                                    }
                                })
                                .catch((error: Error) => {
                                    this.setDeployErrorHandler('An error occurs.', error);
                                });
                        })
                        .catch((error: Error) => {
                            this.setDeployErrorHandler('An error occurs.', error);
                        });
                });
            }
        }
    }

    async deployRegistryColumn(): Promise<void> {
        this.surveys.map((ent) => {
            let tempEnt = this.destinationSurveys.find((en) => en.id === ent.id);
            if (tempEnt) {
                if (ent.hashCode !== tempEnt.hashCode) {
                    this.listOfUnmachtedSurveyIds.push(ent.id);
                }
            }
        });
        if (this.sourceSite == '' || this.deploySite == '' || this.selectedRegistryId == '') {
            alert('Please select source env, deploy env and registry');
            return;
        }
        if (this.sourceSite == this.deploySite) {
            alert('Please select a deploy env that is different from source env.');
            return;
        }
        if (confirm(`Are you sure you want to deploy registry column?`)) {
            this.deployLoading = true;
            this.surveys = this.tempSurveys.filter(
                (s) =>
                    (s.registry && s.registry.includes(this.selectedRegistryId.toLowerCase())) ||
                    this.selectedRegistryId === 'All'
            );
            let surveyIds = [];
            this.surveys.map((el) => {
                surveyIds.push(el.id);
            });
            let items = await this.svc.getSurveysByIds(surveyIds).toPromise();
            let countItems = 0;
            if (items.length > 0) {
                items.forEach((element) => {
                    let currentSurvey = element;
                    this.svc
                        .getSurveyById_Deploy(currentSurvey.id, this.destinationSite)
                        .toPromise()
                        .then((destResult: any) => {
                            ++countItems;
                            if (destResult && destResult.length > 0) {
                                currentSurvey['deploy_mode'] = 'updateRegistryColumn';
                                this.svc
                                    .saveSurvey_DeployVersion(currentSurvey, this.destinationSite)
                                    .toPromise()
                                    .then((res: any) => {})
                                    .catch((error: Error) => {
                                        this.setDeployErrorHandler('An error occurs.', error);
                                    });
                            }

                            if (countItems == items.length) {
                                this.deployLoading = false;
                                this.message = `Deployed successfully ${this.selectedRegistryId} to ${this.deploySite}`;
                            }
                        })
                        .catch((error: Error) => {
                            this.setDeployErrorHandler('An error occurs.', error);
                        });
                });
            }
        }
    }

    setDeployErrorHandler(msg, err?) {
        this.message = `${msg}`;
        if (err) console.error(err);
        if (this.deployLoading) this.deployLoading = false;
    }

    downloadSurveyById(row, deploySite, site) {
        this.svc
            .getSurveyById_Deploy(row.id, deploySite)
            .toPromise()
            .then((result: any) => {
                let survey = JSON.parse(result[0].json);
                this.downloadFile('' + row.id + '_' + site + '.json', survey);
            });
    }

    downloadFile(file_name, export_data): void {
        const str = JSON.stringify(export_data);
        const bytes = new TextEncoder().encode(str);
        let blob = new Blob([bytes], { type: 'application/json;charset=utf-16' });
        saveAs(blob, file_name);
    }

    showToast() {
        this.toastService.show({
            body: this.translate.instant('Toast.FilterOptions'),
        });
    }

    // Functions for publish survey
    openPublishSurveyModal(row) {
        if (!this.destinationSite) {
            alert('Please select the destination env to update metadata.');
            return;
        }
        this.showPublishModal = true;
        this.selectedPublishSurveyId = row.id;
        this.selectedPublishSurveyTitle = this.getText(row.title);
    }

    closePublishSurveyModal() {
        this.showPublishModal = false;
        this.selectedPublishSurveyTitle = '';
    }

    async publishSurvey(context): Promise<void> {
        try {
            if (context.selectedRegistryId) context.selectedRegistryId = null;

            context.registries = await context.svc.getRegistriesBySurveyId(
                context.selectedPublishSurveyId,
                context.destinationSite
            );

            if (context.registries?.length === 1) {
                context.selectedRegistryId = context.registries[0].registry_ID;
                context.showConfirmQuestionKeyModal = true;
                context.loadingConfirmQuestionKey = true;
            } else if (context.registries?.length > 1) {
                context.showSelectRegistryModal = true;
            } else {
                context.setDeployErrorHandler('Registry does not exist for this survey.');
            }

            const surveyQKeyMappingData = await context.svc.getSurveyQKeyMappingData(
                context.selectedRegistryId,
                context.selectedPublishSurveyId,
                context.destinationSite
            );
            context.flatElementList = surveyQKeyMappingData.flat_element_list;

            // Add a description of the elements and pii flag
            context.flatElementList.forEach(async (el) => {
                el.desc = el.is_new ? 'New' : 'Existing';
                if (el.is_ref_exist) el.desc += ', Reference from another key';
                el.pii_flag = el.pii ? 'yes' : 'no';
            });

            context.colDefsQKey = context.getQKeyTableColumns();
            context.loadingConfirmQuestionKey = false;
        } catch (error) {
            context.setDeployErrorHandler('An error occurs.', error);
        }
    }

    assignSelectedRegistry(value: any = null): void {
        if (value) {
            this.selectedRegistryId = value;
        }
        this.showSelectRegistryModal = false;
        this.showConfirmQuestionKeyModal = true;
    }

    closeSelectRegistryModal(): void {
        this.showSelectRegistryModal = false;
    }

    closeConfirmQuestionKeyModal(): void {
        this.showConfirmQuestionKeyModal = false;
    }

    // Todo: make handler, dest_table and dest_column dropdown configurable
    getQKeyTableColumns() {
        return [
            {
                headerName: 'desc',
                field: 'desc',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'key',
                field: 'name',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'title',
                field: 'title',
                cellStyle: {
                    height: '100%',
                    display: 'flex ',
                    'align-items': 'center ',
                },
                filter: 'agTextColumnFilter',
                valueFormatter: (params) => this.getText(params?.value) || params.value,
                wrapText: true,
            },
            {
                headerName: 'type',
                field: 'type',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'pii',
                field: 'pii_flag',
                cellDataType: 'dropdown',
                cellStyle: {
                    height: '100%',
                    display: 'flex ',
                    'align-items': 'center ',
                },
                cellRendererParams: (params) => {
                    return {
                        onChange: (event) => this.updatePIIChange(event, params.data),
                        value: params?.value,
                        dropdownOptions: ['yes', 'no'],
                    };
                },
                filter: CustomFilterComponent,
                filterParams: {
                    options: ['yes', 'no'],
                },
            },
            {
                headerName: 'handler',
                field: 'handler',
                cellDataType: 'dropdown',
                cellStyle: {
                    height: '100%',
                    display: 'flex ',
                    'align-items': 'center ',
                },
                cellRendererParams: (params) => {
                    return {
                        onChange: (event) => this.updateHandlerChange(event, params.data),
                        value: params?.value,
                        dropdownOptions: ['default', 'pii', 'divert', 'name'],
                    };
                },
                filter: CustomFilterComponent,
                filterParams: {
                    options: ['default', 'pii', 'divert', 'name'],
                },
            },
            {
                headerName: 'dest_table',
                field: 'dest_table',
                cellDataType: 'dropdown',
                cellStyle: {
                    height: '100%',
                    display: 'flex ',
                    'align-items': 'center ',
                },
                cellRendererParams: (params) => {
                    return {
                        onChange: (event) => this.updateDestTableChange(event, params.data),
                        value: params?.value,
                        dropdownOptions: [null, 'contact_data', 'identifier'],
                    };
                },
                filter: CustomFilterComponent,
                filterParams: {
                    options: ['contact_data', 'identifier'],
                },
            },
            {
                headerName: 'dest_column',
                field: 'dest_column',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'parent_key',
                field: 'parent_question_key',
                filter: 'agTextColumnFilter',
            },
        ];
    }

    updatePIIChange(event, row): void {
        this.flatElementList.forEach(async (el) => {
            if (el.name === row.name) {
                el.pii = event.target.value === 'yes' ? 1 : null;
            }
        });
    }

    updateHandlerChange(event, row): void {
        this.flatElementList.forEach(async (el) => {
            if (el.name === row.name) {
                el.handler = event.target.value;
            }
        });
    }

    updateDestTableChange(event, row): void {
        this.flatElementList.forEach(async (el) => {
            if (el.name === row.name) {
                if (event.target.value === 'null') {
                    el.dest_table = null;
                    el.dest_column = null;
                }
            }
        });
    }

    async saveQuestionKeyMapping(context): Promise<void> {
        let qkmResult;
        try {
            if (
                confirm(
                    `Are you sure you want to publish survey metadata to ${context.deploySite}?`
                )
            ) {
                context.loading = true;
                qkmResult = await context.svc.updateQuestionKeyMapping(
                    context.selectedRegistryId,
                    context.selectedPublishSurveyId,
                    context.flatElementList,
                    context.destinationSite
                );

                const metadataResult = await context.svc
                    .updateSurveyMetadata(
                        context.selectedRegistryId,
                        context.selectedPublishSurveyId,
                        context.destinationSite
                    )
                    .toPromise();
                context.loading = false;
            }
        } catch (error) {
            context.loading = false;
            if (qkmResult !== 'success') {
                context.setDeployErrorHandler(
                    'An error occurs while updating question key mapping.',
                    error
                );
            } else {
                console.error(error);
            }
        }
    }
}
