import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '@h20-services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
    _visible: boolean;
    @Input() set visible(_value: boolean) {
        if (_value) {
            document.body.style.overflow = 'scroll';
            document.body.style.height = '100%';
        } else {
            document.body.style.overflow = 'scroll';
            document.body.style.height = 'auto';
        }
        this._visible = _value;
    }
    get visible() {
        return this._visible;
    }
    @Output() visibleChange = new EventEmitter<boolean>();

    @Input() header: string;
    @Input() saveBtnText: string;
    @Input() saveBtnDisabled: boolean;
    @Input() context: any;
    @Input() showCloseBtn: boolean = true;
    @Input() footerText: string;
    @Input() modalWidth: string;
    @Input() modalHeight: string;
    @Input() closeBtnText: string;
    @Input() closeBtnDisabled: boolean;
    @Input() headerText: string;
    @Input() appBtnWidth: string;
    @Input() saveFunc: (context) => boolean;
    @Input() closeFunc: (context) => boolean;
    @Input() centerModal = false;
    @Input() btnWidth: string;

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.keyCode === 13 && this.saveBtnDisabled === false) {
            this.onSave();
        } else if (event.keyCode === 27) {
            this.onClose();
        }
    }

    updating: boolean;
    confirming: boolean;
    @Input() updating$: BehaviorSubject<boolean>;
    @Input() confirming$: BehaviorSubject<boolean>;

    constructor(
        private com_svc: CommonService,
        private translate: TranslateService
    ) {}
    ngOnInit(): void {
        this.closeBtnText = this.closeBtnText || 'Registry.Close';
        if (this.updating$ !== undefined) {
            this.updating$.subscribe((updating) => {
                this.updating = updating;
            });
        }
        if (this.appBtnWidth == '50%') {
            this.btnWidth = '100%';
        }

        if (this.confirming$) {
            this.confirming$.subscribe((confirming) => {
                this.confirming = confirming;
            });
        }
    }

    getText = this.com_svc.getText;
    closePopup(): void {
        if (this.closeFunc) {
            this.closeFunc(this.context);
        }
        this.visible = false;
        this.visibleChange.emit(this.visible);
    }
    onSave(): void {
        if (this.saveFunc(this.context)) {
            this.closePopup();
        }
    }
    onClose(): void {
        this.closeFunc(this.context);
        this.closePopup();
    }
}
