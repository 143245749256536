<nav class="sticky-top pif-nav" id="pif-nav">
    <div class="d-flex align-items-center justify-content-between position-relative pif-nav-holder">
        <div class="col-10 d-flex align-items-center">
            <div class="ms-3 pif-nav__logo">
                <a routerLink="/home">
                    <img
                        id="header_logo"
                        [ngStyle]="{ display: !sideBarOpen ? 'block' : 'none' }"
                        src="/assets/image/composerLogo.png"
                        alt="Logo"
                    />
                </a>
            </div>
            <div class="col-3 p-3" style="min-width: 200px">
                <label class="col-12 px-0"> Source Environment </label>
                <select
                    class="form-control"
                    id="source_env"
                    (change)="updateSession($event, 'source')"
                >
                    <option [selected]="!selectedSourceEnv" value="" disabled>
                        --Please select--
                    </option>
                    <option
                        *ngFor="let env of sourceEnvs"
                        [value]="env"
                        [selected]="env === selectedSourceEnv"
                    >
                        {{ env }}
                    </option>
                </select>
                <div *ngIf="!sourceIsSelected" class="formError text-danger">
                    Please select a source env
                </div>
            </div>
            <div class="col-3 p-3" style="min-width: 210px">
                <label class="col-12 px-0"> Destination Environment </label>
                <select
                    class="form-control"
                    id="destination_env"
                    (change)="updateSession($event, 'destination')"
                >
                    <option value="" [selected]="!selectedDestinationEnv" disabled>
                        --Please select--
                    </option>
                    <option
                        *ngFor="let env of destinationEnvs"
                        [value]="env"
                        [selected]="env === selectedDestinationEnv"
                    >
                        {{ env }}
                    </option>
                </select>
                <div *ngIf="!destinationIsSelected" class="formError text-danger">
                    Please select a destination env
                </div>
            </div>
            <div class="col-3 p-3" style="min-width: 200px">
                <label class="col-8 px-0"> Registry </label>
                <select class="form-control" (change)="updateSession($event, 'registry')">
                    <option value="" [selected]="!registryIsSelected" disabled>
                        --Please select--
                    </option>
                    <option
                        *ngFor="let reg of listRegistries"
                        [value]="reg"
                        [selected]="reg === selectedRegistry"
                    >
                        {{ reg }}
                    </option>
                </select>
                <div *ngIf="!registryIsSelected" class="formError text-danger">
                    Please select a registry
                </div>
            </div>
        </div>

        <li class="col-2 menu-item list-nav">
            <a id="account">
                <i
                    attr.aria-label="{{ 'Icons.User' | translate }}"
                    title="{{ 'Registry.Account' | translate }}"
                    class="fa-light fa-user"
                ></i>
            </a>
            <div class="sub-menu">
                <ul class="list-nav d-flex row">
                    <div
                        class="list-nav"
                        *ngIf="
                            canChangeRoles?.length > 1 ||
                            canChangeRoles?.productionDeployment_fullAccess
                        "
                    >
                        <li *ngFor="let role of roleEntries" [hidden]="!role.claim">
                            <label>
                                <input
                                    [checked]="selectedRole === role.key"
                                    type="radio"
                                    name="role"
                                    class="custom-radio"
                                    [value]="role.key"
                                    (change)="onRoleChange($event)"
                                />
                                <span>{{ role.label }}</span>
                            </label>
                        </li>

                        <div><hr class="logout-line" /></div>
                    </div>

                    <li (click)="logout()" class="login-btn">
                        <i
                            attr.aria-label="{{ 'Icons.User' | translate }}"
                            title="{{ 'Registry.Account' | translate }}"
                            class="fa-light fa-right-from-bracket"
                            style="margin-left: 10px"
                        ></i>
                        <a class="fauxLink">
                            {{ 'Registry.Logout' | translate }}
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    </div>
</nav>
