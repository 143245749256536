<div class="bg-white p-3 pe-0">
    <div class="configuration-title-section mb-4">
        <p class="configuration-title">Check box Properties</p>
        <button class="trash-button">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
            >
                <path
                    d="M13.3333 5.00002V4.33335C13.3333 3.39993 13.3333 2.93322 13.1517 2.5767C12.9919 2.2631 12.7369 2.00813 12.4233 1.84834C12.0668 1.66669 11.6001 1.66669 10.6667 1.66669H9.33333C8.39991 1.66669 7.9332 1.66669 7.57668 1.84834C7.26308 2.00813 7.00811 2.2631 6.84832 2.5767C6.66667 2.93322 6.66667 3.39993 6.66667 4.33335V5.00002M8.33333 9.58335V13.75M11.6667 9.58335V13.75M2.5 5.00002H17.5M15.8333 5.00002V14.3334C15.8333 15.7335 15.8333 16.4336 15.5608 16.9683C15.3212 17.4387 14.9387 17.8212 14.4683 18.0609C13.9335 18.3334 13.2335 18.3334 11.8333 18.3334H8.16667C6.76654 18.3334 6.06647 18.3334 5.53169 18.0609C5.06129 17.8212 4.67883 17.4387 4.43915 16.9683C4.16667 16.4336 4.16667 15.7335 4.16667 14.3334V5.00002"
                    stroke="white"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </button>
    </div>

    <ul class="nav nav-tabs mb-4 d-flex" id="pills-tab" role="tablist">
        <li class="nav-item text-center flex-grow-1" role="presentation">
            <button
                class="nav-link w-100 active"
                id="pills-general-tab"
                data-bs-toggle="tab"
                data-bs-target="#pills-general"
                type="button"
                role="tab"
                aria-controls="pills-general"
                aria-selected="true"
            >
                General
            </button>
        </li>
        <li class="nav-item text-center flex-grow-1" role="presentation">
            <button
                class="nav-link w-100"
                id="pills-layout-tab"
                data-bs-toggle="tab"
                data-bs-target="#pills-layout"
                type="button"
                role="tab"
                aria-controls="pills-layout"
                aria-selected="false"
            >
                Layout
            </button>
        </li>
        <li class="nav-item text-center flex-grow-1" role="presentation">
            <button
                class="nav-link w-100"
                id="pills-conditions-tab"
                data-bs-toggle="tab"
                data-bs-target="#pills-conditions"
                type="button"
                role="tab"
                aria-controls="pills-conditions"
                aria-selected="false"
            >
                Logic
            </button>
        </li>
    </ul>

    <!-- Tab Content -->
    <div class="tab-content" id="pills-tabContent">
        <div
            class="tab-pane fade show active"
            id="pills-general"
            role="tabpanel"
            aria-labelledby="pills-general-tab"
            tabindex="0"
        >
            <app-pi-form
                *ngIf="generalForm && currentElement && !hotReload"
                [formData]="currentElement"
                [formDef]="generalForm"
                [mode]="mode"
                (formChanges)="onFormChanges($event)"
                layout="flat"
            ></app-pi-form>
        </div>
        <div
            class="tab-pane fade"
            id="pills-layout"
            role="tabpanel"
            aria-labelledby="pills-layout-tab"
            tabindex="0"
        >
            <app-pi-form
                *ngIf="layoutForm && currentElement && !hotReload"
                [formData]="currentElement"
                [formDef]="layoutForm"
                [mode]="mode"
                (formChanges)="onFormChanges($event)"
                layout="flat"
            ></app-pi-form>
        </div>
        <div
            class="tab-pane fade"
            id="pills-conditions"
            role="tabpanel"
            aria-labelledby="pills-conditions-tab"
            tabindex="0"
        >
            <app-pi-form
                *ngIf="logicForm && currentElement && !hotReload"
                [formData]="currentElement"
                [formDef]="logicForm"
                [mode]="mode"
                (formChanges)="onFormChanges($event)"
                layout="flat"
            ></app-pi-form>
        </div>
    </div>
</div>
