import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { AuthService } from '@h20-services/auth.service';
import { ToastService } from '@h20-shared/services/toast.service';
import { CreatePasswordComponent } from '@h20-shared/create-password-component/create-password-component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthUser, getCurrentUser, updatePassword } from '@aws-amplify/auth';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
    @ViewChild(CreatePasswordComponent)
    createPasswordComponent: CreatePasswordComponent;
    noCurrentPassword = false;
    incorrectCurrentPassword = false;
    resetPasswordForm: UntypedFormGroup;
    passwordConfirmed;
    currentCognitoUser;
    pulseAuth: PulseAuth = PulseAuth.NotLoggedIn;
    passwordFailed = false;
    @ViewChild('passwordInput') passwordInputRef: ElementRef<HTMLInputElement>;
    @ViewChild('newPasswordInput')
    newPasswordInputRef: ElementRef<HTMLInputElement>;
    @ViewChild('currentPasswordInput')
    currentPasswordInputRef: ElementRef<HTMLInputElement>;
    showCharacters = {
        passwordInput: false,
        newPasswordInput: false,
        currentPasswordInput: false,
    };
    passwordValid: boolean;
    newPasswordValid: boolean;
    currentPassword: string;
    newPassword: string;
    userType;
    email;
    text = {
        passwordInput: this.translate.instant('Login.Show'),
        newPasswordInput: this.translate.instant('Login.Show'),
        currentPasswordInput: this.translate.instant('Login.Show'),
    };

    constructor(
        public translate: TranslateService,
        private readonly auth: AuthService,
        private readonly toastService: ToastService,
        private readonly fb: UntypedFormBuilder
    ) {}

    ngOnInit() {
        this.auth.getPulseAuth().then((pulseAuth: PulseAuth) => {
            this.userType = pulseAuth.getType();
            this.email = pulseAuth.getEmailAddr();
        });
        this.resetPasswordForm = this.fb.group({
            currentpassword: ['', [Validators.required]],
            password: ['', [Validators.required]],
            confirmPassword: ['', [Validators.required]],
        });

        getCurrentUser().then((currAuthUser) => {
            this.currentCognitoUser = currAuthUser;
        });
    }

    handleConfirmPassword(event) {
        this.resetPasswordForm.controls['password'].setValue(event.password);
        this.resetPasswordForm.controls['confirmPassword'].setValue(event.password);
    }
    updatePassword() {
        this.noCurrentPassword = false;
        this.incorrectCurrentPassword = false;
        if (this.resetPasswordForm.value.currentpassword.length <= 0) {
            this.noCurrentPassword = true;
        }

        const currentPasswordValue = this.resetPasswordForm.value.currentpassword;
        const newPasswordValue = this.resetPasswordForm.value.password;
        updatePassword({
            oldPassword: currentPasswordValue,
            newPassword: newPasswordValue,
        })
            .then(() => {
                this.auth
                    .changePasswordEmail(this.email, this.translate.currentLang)
                    .toPromise()
                    .then((res: any) => {
                        this.showToast({
                            type: 'success',
                            body: 'Toast.SettingsSuccessBody',
                        });
                        this.resetForms();
                    });
            })
            .catch((error: any) => {
                if (error.message == 'Incorrect username or password.') {
                    this.showToast({
                        type: 'error',
                        body: 'Login.IncorrectPassword',
                    });
                } else {
                    this.showToast({
                        type: 'error',
                        body: 'Toast.SettingsErrorBody',
                    });
                }
            });
    }

    resetForms() {
        this.resetPasswordForm.reset();
        this.incorrectCurrentPassword = false;
        this.noCurrentPassword = false;
        this.createPasswordComponent.reset();
    }

    togglePasswordVisibility(field: string): void {
        const inputElement = this.currentPasswordInputRef?.nativeElement;
        if (!inputElement) {
            return;
        }
        this.showCharacters[field] = !this.showCharacters[field];
        inputElement.type = this.showCharacters[field] ? 'text' : 'password';
        this.text[field] = this.showCharacters[field] ? 'Hide' : 'Show';
    }

    showToast(toast) {
        this.toastService.show({
            type: toast.type,
            body: this.translate.instant(toast.body),
        });
    }
}
