const lambdaEndpoint = 'https://asnmtbz74h.execute-api.us-east-1.amazonaws.com/Prod/';

export const environment = {
    production: true,
    development: false,
    captchaOn: false,
    awsConfig: {
        Auth: {
            Cognito: {
                identityPoolId: 'us-east-1:cb9fe603-e010-4ce8-9ef5-13e10fba53aa',
                region: 'us-east-1',
                userPoolId: 'us-east-1_sPY74VJmt',
                userPoolClientId: '3f2ntug21r3ns3moi0lgs7448i',
            },
            storage: 'sessionStorage',
            signUpVerificationMethod: 'code',
            oauth: {
                domain: `nkfpatientnetwork.devops.healthie.net/login`,
                scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                redirectSignIn: `https://nkfpatientnetwork.devops.healthie.net/`,
                redirectSignOut: `https://nkfpatientnetwork.devops.healthie.net/logout/`,
                responseType: 'code',
            },
        },

        Storage: {
            S3: {
                deployed_ui_bucket: 'h20-devops-website',
                bucket: 'h20-devops-data',
                region: 'us-east-1',
            },
        },
        Path: {
            defaultWebAssetPath: 'https://h20-development-web-assets.s3.amazonaws.com',
            liveWebAssetPath: 'https://h20-live-web-assets-ca-central-1.s3.amazonaws.com',
        },
    },
    API: {
        aws_web_assets: 'h20-development-web-assets.s3.amazonaws.com',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'content-type': 'application/json',
            Accept: 'application/json, text/plain',
        },
        createH20Account: 'NA',
        getConsentDoc:
            'https://mx11qbc2vi.execute-api.us-east-1.amazonaws.com/default/GetConsentDoc', //preprod getConsentDoc
        writeToBucketURL: lambdaEndpoint + 'WriteToBucket',
        writeToBucketKey: 'NA',
        readFromBucketKey: 'NA',
        listBucketItemsKey: 'NA',
        readFromBucketURL: lambdaEndpoint + 'ReadFromBucket',
        listBucketItemsURL: lambdaEndpoint + 'ListBucketItems',
        setExternalIdentifierURL: lambdaEndpoint + 'SetExternalIdentifier',
        writeToDb: 'NA',
        writeToDbKey: 'NA',
        userServiceProvider: lambdaEndpoint + 'UserServiceProvider',
        writeToDBUser: lambdaEndpoint + 'WriteToDBUser',
        writeToDBUserAttribute: lambdaEndpoint + 'WriteToDBUserAttribute',
        writeToDBSurvey: lambdaEndpoint + 'WriteToDBSurvey',
        writeToDBConsent: lambdaEndpoint + 'WriteToDBConsent',
        writeToDBActivationStatus: 'NA',
        writeToDBTask: lambdaEndpoint + 'WriteToDBTask',
        writeToDBPatient: lambdaEndpoint + 'WriteToDBPatient',
        writeToDBInfo: lambdaEndpoint + 'WriteToDBInfo',
        sendEmailFailedLogin: 'NA',
        writeToDBSite: lambdaEndpoint + 'WriteToDBSite',
        writeToDBClaim: lambdaEndpoint + 'WriteToDBClaim',
        sendAdminNotification: 'NA',
        registerUser: 'NA',
        userSettingsService: lambdaEndpoint + 'UserSettingsService',
        writeToDBCaseReview: lambdaEndpoint + 'WriteToDBCaseReview',
        scoringSubmission: 'NA',
        writeFileToBucket: lambdaEndpoint + 'WriteFileToBucket',
        submissionTriggers: lambdaEndpoint + 'SubmissionTriggers',
        hardDeletePatient: lambdaEndpoint + 'HardDeletePatient',
        writeToDBClinicalData: lambdaEndpoint + 'WriteToDBClinicalData',
        getToken: lambdaEndpoint + 'GetToken',
        sendEmailTemplate: lambdaEndpoint + 'SendEmailTemplate',
        updateParticipantStatus: lambdaEndpoint + 'UpdateParticipantStatus',
        writeToDBLog: lambdaEndpoint + 'WriteToDBLog',
        writeToDBTrackers: lambdaEndpoint + 'WriteToDBTrackers',
        docusignHandler: lambdaEndpoint + 'DocusignHandler',
        DDBEmailTemplates: lambdaEndpoint + 'DDBEmailTemplates',
        registryConfiguration: lambdaEndpoint + 'RegistryConfiguration',
        writeToDBRegistry: lambdaEndpoint + 'WriteToDBRegistry',
        emailMapping: lambdaEndpoint + 'EmailMapping',
        scheduleConfiguration: lambdaEndpoint + 'ScheduleConfiguration',
        deployManagement: lambdaEndpoint + 'DeployManagement',
        writeToDBDashboard: 'NA',
        anonymousSurveyHandler: lambdaEndpoint + 'AnonymousSurveyHandler',
        getWithdrawnData: lambdaEndpoint + 'GetWithdrawnData',
        layoutConfiguration: lambdaEndpoint + 'LayoutConfiguration',
        clinicalScreenerHandler: lambdaEndpoint + 'ClinicalScreenerHandler',
        choicesFromURLWriter: lambdaEndpoint + 'ChoicesFromURLWriter',
        choicesFromURLHandler: lambdaEndpoint + 'ChoicesFromURLHandler',
        dashboardConfiguration: lambdaEndpoint + 'DashboardConfiguration',
        surveySessionHandler: 'NA',
        greenlightHandler: 'NA',
        sendAdminNotificationPrtRegister: 'NA',
        listBucketItemsComposer: lambdaEndpoint + 'ListBucketItemsComposer',
        readFromBucketComposer: lambdaEndpoint + 'ReadFromBucketComposer',
        vocabularyConfiguration: lambdaEndpoint + 'VocabularyConfiguration',
        surveyQuestionKeyMappingHandler: lambdaEndpoint + 'SurveyQuestionKeyMappingHandler',
        surveySubmissionHandler: lambdaEndpoint + 'SurveySubmissionHandler',
        getSurveySubmission: lambdaEndpoint + 'GetSurveySubmission',
    },
};
