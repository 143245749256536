import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MessageCenterService } from '@h20-services/message-center.service';
import { AuthService } from '@h20-services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ParticipantService } from '@h20-services/participant.service';
import { TaskStatus } from '@h20-services/models/status';
import { Router } from '@angular/router';
import { ITask } from '@h20-services/models/tasks/ITask';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { UserStatus } from '@h20-services/models/status';
import { UserRole } from '@h20-services/models/role';
@Component({
    selector: 'app-message-center',
    templateUrl: './message-center.component.html',
    styleUrls: ['./message-center.component.scss'],
})
export class MessageCenterComponent implements OnInit {
    @Output() showConsentFormEvent = new EventEmitter<void>();

    get tasks(): any {
        return this.JSON;
    }
    @Input() set tasks(tasks: any) {
        this.setTask(tasks);
    }
    showStatus = true;
    @Input() loading;
    JSON = [];
    text;
    currentParticipantId: string;
    activeSlide = 0;
    activeSlideStatus = '';
    isConsentRequired;
    role;
    constructor(
        private router: Router,
        private auth: AuthService,
        private svc: MessageCenterService,
        public translate: TranslateService,
        private prtSvc: ParticipantService
    ) {}

    ngOnInit(): void {
        this.auth.getPulseAuth().then((pulseAuth: PulseAuth) => {
            if (pulseAuth) {
                // FIXME getCurrentParticipant
                this.currentParticipantId =
                    this.prtSvc.getCurrentParticipant() || pulseAuth.getUserID();

                const status = pulseAuth.status as UserStatus;
                this.isConsentRequired = [UserStatus.Consent, UserStatus.MigratedConsent].includes(
                    status
                )
                    ? true
                    : false;
                this.role = pulseAuth.getType();
            }
        });
    }

    processClicks(event, task): void {
        // Remove tasks that do not have routes.  Tasks with routes go to the destination which decides whether to dismissed.
        if (!task.task_configuration) {
            task.status = TaskStatus.Completed;
            this.svc.updateTask(task.id, task.status).subscribe((res: any) => {});
        }
    }

    clickProgress(i): any {
        this.activeSlide = i;
        this.activeSlideStatus = this.JSON[this.activeSlide].status;
    }

    getText(strOrLangs): string {
        try {
            this.text = JSON.parse(strOrLangs);
        } catch {
            this.text = strOrLangs;
        }

        if (this.text === undefined) {
            return strOrLangs;
        }
        if (this.text[this.translate.currentLang] === undefined) {
            return strOrLangs;
        }

        if (!strOrLangs) return '';
        return this.text[this.translate.currentLang] || strOrLangs;
    }

    openTask(task): void {
        if (task.task_configuration === 'consent-form') {
            return this.showConsentFormEvent.emit();
        }
        let survey_ID = task.task_configuration;
        let task_ID = task.id;
        this.router.navigate(['surveys', survey_ID, 'task', task_ID]);
    }

    setTask(tasks) {
        const isAdmin = [UserRole.Admin, UserRole.SiteAdmin].includes(this.role) ? true : false;
        if (!isAdmin && tasks && Array.isArray(tasks)) {
            if (tasks.length === 0) {
                const consentTask = {
                    message: 'Please sign your Consent/Assent document',
                    status: 'new',
                    task_type: 'consent',
                    task_configuration: 'consent-form',
                };
                tasks.unshift(consentTask);
            }
            this.JSON = tasks.filter(
                (task: ITask) => task.status === 'new' || task.status === 'opened'
            );
            if (this.JSON.length > 0) {
                this.showStatus = true;
                this.activeSlideStatus = this.JSON[0].status;
            } else {
                this.showStatus = false;
            }
        } else {
            this.JSON = [];
            this.showStatus = false;
        }
    }
}
