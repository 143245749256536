import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '@h20-services/common.service';
import { AuthService } from '@h20-services/auth.service';
import { MFAStatus } from '@h20-services/enums/mfaStatus/mfaStatus';
import { UserService } from '@h20-services/user.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ToastService } from '@h20-shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { RequestService } from '@h20-services/request.service';
import { environment as env } from '@environment/environment';
import { TemplateTypes } from '@h20-services/models/emails/email_template_types';

@Component({
    selector: 'app-mfa',
    templateUrl: './mfa.component.html',
    styleUrls: ['./mfa.component.scss'],
})
export class MfaComponent implements OnInit {
    mfa_failed = false;
    mfaForm: UntypedFormGroup;
    needsVerification = false;
    confirmLoginLoading = false;
    showCodeDiv = false;
    message: any;
    subscription: Subscription;
    supportModalNeeded: boolean = true;
    showSendRequestErrorModal: boolean = false;

    @Output() needsVerificationEvent = new EventEmitter<boolean>();

    needsVerificationChange() {
        this.needsVerificationEvent.emit(this.needsVerification);
    }

    @Output() changeMFAPanelEvent = new EventEmitter<boolean>();

    changeMFAPanel(value) {
        this.changeMFAPanelEvent.emit(value);
    }

    @Output() cancelLoginEvent = new EventEmitter();

    cancelLogin() {
        this.cancelLoginEvent.emit();
    }

    @Output() changeMFASetupPanelEvent = new EventEmitter<boolean>();

    changeMFASetupPanel(value: boolean) {
        this.changeMFASetupPanelEvent.emit(value);
    }

    @Input() showMFAPanel;
    @Input() showMFASetupPanel;
    @Input() mfaQrCode;
    @Input() mfaQrCodePath;
    @Input() authUser;
    @Input() navigateTo;
    @Input() showInModal: boolean = false;
    formChange: Subscription;

    confirming$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    requestHelpModal;
    helpSuccessModal;
    errorCount = 0;

    constructor(
        private fb: UntypedFormBuilder,
        private auth: AuthService,
        private usvc: UserService,
        private route: Router,
        private commonService: CommonService,
        private toastService: ToastService,
        private translate: TranslateService,
        private reqSvc: RequestService
    ) {
        this.subscription = this.commonService.getPanelMessage().subscribe((message) => {
            this.showMFASetupPanel = message.text === 'showMFASetupPanel' ? message.value : false;
            this.showMFAPanel = message.text === 'showMFAPanel' ? message.value : false;
            this.mfaForm.reset();
            this.mfa_failed = false;
        });
    }

    ngOnInit(): void {
        this.mfaForm = this.fb.group({
            mfaCode: ['', [Validators.required]],
        });
    }

    confirmMfaSetup(context) {
        context.needsVerification = true;
        context.needsVerificationChange();
        context.changeMFAPanel(true);
        context.changeMFASetupPanel(false);
    }

    private onCancelLogin = () => {
        this.mfa_failed = false;
        try {
            if (this.mfaForm) {
                this.mfaForm.reset();
            }
            this.showMFASetupPanel = false;
            this.cancelLogin();
            this.changeMFASetupPanel(false);
        } catch (error) {
            console.error('error signing out: ', error);
        }
    };

    showCode() {
        this.showCodeDiv = true;
    }

    checkErrorMessage() {
        this.mfa_failed = false;
    }
    confirmSetup() {
        this.mfa_failed = false;
        if (this.mfaForm.controls.mfaCode.invalid) {
            this.mfaForm.controls.mfaCode.markAsTouched();
            return;
        }

        this.auth
            .verifyTOTPSetupToken(this.authUser, this.mfaForm.value.mfaCode)
            .then(() => {
                this.auth.setPreferredMfa(this.authUser, 'TOTP');

                this.mfa_failed = false;
                this.usvc
                    .updateMfaByAuthId(MFAStatus.setupComplete, true, this.auth.getUserSub())
                    .toPromise()
                    .then((res) => {
                        if (!this.showInModal) {
                            this.SuccessToast();
                            this.changeMFAPanel(true);
                        } else {
                            this.route.navigate(['/home']);
                        }
                    })
                    .catch((err) => {
                        this.confirmLoginLoading = false;
                        this.mfa_failed = true;
                        this.ErrorToast();
                        console.error(err);
                    });
            })
            .catch((e) => {
                this.confirmLoginLoading = false;
                this.mfa_failed = true;
                console.error(e);
            });
    }

    confirmLogin(context: this) {
        context.confirming$.next(true);
        context.mfa_failed = false;

        let isConfirmed = false;

        context.auth
            .confirmSignIn(
                context.authUser,
                context.mfaForm.value.mfaCode,
                context.authUser.nextStep.signInStep
            )
            .then((userLogged) => {
                isConfirmed = true;
                context.auth.completeSetPulseAuth();
                context.mfa_failed = false;
                context.errorCount = 0;
                context.route.navigate(['/home']);
                context.closeModal(context);
            })
            .catch((error) => {
                context.mfa_failed = true;

                context.errorCount++;

                if (context.errorCount > 3 && context.supportModalNeeded) {
                    context.requestMFASupport();
                    this.showMFAPanel = false;
                }
            })
            .finally(() => {
                context.confirming$.next(false);
            });
    }

    requestMFASupport() {
        this.mfa_failed = false;
        this.requestHelpModal = true;
    }

    closeModal(context) {
        context.showMFAPanel = false;
        context.requestHelpModal = false;
        context.helpSuccessModal = false;
        context.supportModalNeeded = true;
        context.showSendRequestErrorModal = false;
        context.errorCount = 0;
    }

    sendMfaEmailSubmission(context): any {
        context.confirmLoginLoading = true;
        const emailId = context.authUser.username;
        const registryId = location.hostname.split('.')[0];
        const template = TemplateTypes.support_request_admin_alert;
        const GUID = localStorage.getItem('clientID');
        const pkey = `master#default#master#${template}#${this.translate.currentLang}`;

        const reqObj: any = {
            email: emailId,
            registry_id: registryId,
            templateType: template,
            guid: GUID,
            'registry_id#organization#site_id#template#language': pkey,
        };

        context.reqSvc.post(env.API.sendEmailTemplate, JSON.stringify(reqObj)).subscribe(
            (response: any) => {
                context.confirmLoginLoading = false;
                context.helpSuccessModal = true;
            },
            (err: any) => {
                this.confirmLoginLoading = false;
                this.requestHelpModal = false;
                this.showSendRequestErrorModal = true;
            }
        );
    }

    goBackToRequestSupport(context) {
        context.requestHelpModal = true;
    }

    goBackToMFA(context) {
        context.requestHelpModal = false;
        context.showMFAPanel = true;
        context.supportModalNeeded = false;
        context.mfaForm.reset();
    }

    ErrorToast() {
        this.toastService.show({
            type: 'error',
            heading: this.translate.instant('Login.SignInErrorHeading'),
            body: this.translate.instant('Login.SignInErrorBody'),
        });
    }
    SuccessToast() {
        this.toastService.show({
            type: 'success',
            body: this.translate.instant('Toast.SettingsSuccessBody'),
        });
    }
}
