<div class="card mb-3">
    <div class="card-body">

        <div id="messageCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
            <div class="badge-row d-flex justify-content-end">
                <h5 *ngIf="loading" class="msg-center-badge-loading badge badge-pill placeholder-glow">
                    <span></span>
                </h5>
                <h2 *ngIf="!loading" class="badge-text">
                    <div *ngIf="JSON.length > 0" class="msg-center-badge badge badge-pill">
                        {{ 'Registry.Remaining' | translate }}
                        <span class="badge badge-client ms-1">
                            {{ JSON.length }}
                        </span>
                    </div>
                </h2>
            </div>
            <div class="d-block mt-3 d-sm-flex justify-content-between align-items-center mb-3 mb-sm-0">
                <h2 *ngIf="loading" class="w-100 text-sm-start placeholder-glow">
                    <span class="placeholder col-3"></span>
                </h2>
                <h2 class="title" *ngIf="!loading">
                    <span [ngClass]="JSON.length > 0 ? 'msg-center-heading' : 'cursor-auto'" data-bs-toggle="collapse"
                        href="#tasks-incomplete" role="button" aria-expanded="false" aria-controls="tasks-incomplete"
                        onclick="document.getElementById('toggle-msg-center').classList.toggle('fa-caret-circle-up');">
                        {{ 'Registry.MessageCenter' | translate }}
                        <i id="toggle-msg-center" *ngIf="JSON.length > 1"
                            class="fa-solid fa-caret-circle-down fa-caret-circle-up"
                            attr.aria-label="{{ 'Icons.ArrowUp' | translate }}"></i>
                    </span>
                </h2>
                <div *ngIf="JSON.length > 1" class="carousel-indicators">
                    <button type="button" data-bs-target="#messageCarousel" *ngFor="let item of JSON; let i = index"
                        [attr.data-bs-slide-to]="i" [class.active]="i === 0" (click)="clickProgress(i)"></button>
                </div>
            </div>
            <div *ngIf="JSON.length > 1" id="tasks-incomplete" class="collapse msg-center-expander">
                <div class="close-tasks-incomplete" data-bs-toggle="collapse" data-target="#tasks-incomplete"
                    aria-expanded="false" aria-controls="tasks-incomplete">
                    <i onclick="document.getElementById('toggle-msg-center').classList.toggle('fa-caret-circle-up');"
                        attr.aria-label="{{ 'Icons.Close' | translate }}" class="fa-solid fa-times"
                        data-bs-toggle="collapse" href="#tasks-incomplete"></i>
                </div>
                <h2 class="w-100 mb-1">
                    {{ 'Registry.IncompleteTasks' | translate }}
                </h2>
                <ul class="mb-0">
                    <li *ngFor="let item of JSON; let i = index" class="d-block me-3">
                        <i class="fa-solid fa-file-invoice fa-lg me-1"
                            attr.aria-label="{{ 'Icons.Survey' | translate }}"></i>
                        <a [href]="'/surveys/' + item.task_configuration + '/task/' + item.id">
                            {{ getText(item.message) }}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="d-flex h-100" *ngIf="showStatus">
                <a style="height: 4rem" *ngIf="loading" class="btn disabled placeholder w-100"
                    style="background-color: var(--brand-500)"></a>
                <div *ngIf="!loading" class="carousel-inner msg-center-banner"
                    [class.big-red]="activeSlideStatus !== 'new' && activeSlideStatus !== 'opened'">
                    <ng-container *ngFor="let item of JSON; let i = index">
                        <div class="carousel-item px-5" *ngIf="item.status !== 'completed'" [class.active]="i === 0"
                            [attr.id]="item.uuid" (click)="processClicks($event, item)">
                            <div class="w-100 d-flex align-items-center">
                                <div class="me-1">
                                    <i class="{{ item.icon }}"></i>
                                </div>
                                <div *ngIf="!item.task_configuration">
                                    {{ getText(item.message) }}
                                </div>
                                <div *ngIf="item.task_configuration" class="w-100">
                                    <a
                                        class="d-block d-sm-flex align-items-center justify-content-between w-100 text-center text-sm-start">
                                        <span>{{ getText(item.message) }}</span>
                                        <button class="br_btn mt-2 mt-sm-0" (click)="openTask(item)">
                                            {{ 'Registry.GoNow' | translate }}
                                            <i attr.aria-label="{{
                                                    'Icons.ArrowCircleRight' | translate
                                                }}" class="fa-solid fa-arrow-circle-right ms-1 mt-1"></i>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <button *ngIf="JSON.length > 1"
                        class="carousel-ctrl carousel-control-prev d-flex align-items-center justify-content-center h-100"
                        type="button" data-bs-target="#messageCarousel" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">
                            {{ 'Registry.Previous' | translate }}
                        </span>
                    </button>
                    <button *ngIf="JSON.length > 1"
                        class="carousel-ctrl carousel-control-next d-flex align-items-center justify-content-center"
                        type="button" data-bs-target="#messageCarousel" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">
                            {{ 'Registry.Next' | translate }}
                        </span>
                    </button>
                </div>
            </div>
            <div class="carousel-inner msg-center-banner big-green" *ngIf="!showStatus">
                <div class="w-100 d-flex align-items-center">
                    <div class="me-1">
                        <i attr.aria-label="{{ 'Icons.ThumbsUp' | translate }}" class="far fa-thumbs-up"></i>
                    </div>
                    <div>{{ 'Registry.AllUpToDate' | translate }}</div>
                </div>
            </div>
        </div>
    </div>
</div>