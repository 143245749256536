<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <div class="row d-flex flex-column border-bottom">
            <div class="col-12"><app-element-title [element]="element"></app-element-title></div>
            <div id="gesture-overlay" style="position: relative; display: inline-block">
                <img id="gestureImage" width="500px" [src]="element.imageurl" />
            </div>
        </div>
    </div>
</div>
<ng-template #formModeTemplate>
    <div *ngIf="!disabled" class="pi-control-form-mode">
        <label class="mb-2"
            >{{ getText(element.title) }} <app-required [element]="element"></app-required>:
        </label>

        <div [formGroup]="control">
            <div id="gesture-overlay" style="position: relative; display: inline-block">
                <img
                    id="gestureImage"
                    width="500px"
                    [src]="element.imageurl"
                    (click)="imgClick($event)"
                />
            </div>
        </div>

        <!-- <app-validator [control]="control"></app-validator>
    --></div>
</ng-template>
