<button
    [type]="type || 'button'"
    class="btn"
    [attr.id]="id"
    [style.width]="btnWidth"
    [class]="buttonClasses"
    [ngClass]="designMap.get(design) ? designMap.get(design) : buttonText ? 'primary' : 'tertiary'"
    [class.btn-icon-only]="!buttonText"
    [disabled]="disabled"
    [attr.title]="title"
    [tooltip]="tooltip.text"
    [options]="tooltip.options"
    [display]="tooltip.display"
    (click)="clickMe($event)"
    (mouseover)="mouseOver($event)"
    attr.aria-label="{{ ariaLabel | translate }}"
    [attr.data-bs-toggle]="hasDropdown ? 'dropdown' : null"
>
    <i *ngIf="iconConfig && iconConfig.iconFirst" [ngClass]="iconConfig.iconClasses"></i>
    <span *ngIf="buttonText" [style.fontSize]="sizeMap.get(buttonTextSize)">
        {{ buttonText | translate }} {{ convertedTextString | translate }}
    </span>
    <i *ngIf="iconConfig && !iconConfig.iconFirst" [ngClass]="iconConfig.iconClasses"></i>

    <div class="badge" *ngIf="badgeVal">{{ badgeVal }}</div>
</button>
<div
    *ngIf="hasDropdown"
    class="dropdown-menu"
    [class.show]="dropdownVisible"
    aria-labelledby="dropdownMenuButton"
>
    <ng-content></ng-content>
</div>
