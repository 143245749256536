import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-validator',
    templateUrl: './validator.component.html',
    styleUrls: ['./validator.component.scss'],
})
export class ValidatorComponent implements OnInit {
    @Input() control: AbstractControl;
    @Input() customErrorMessages: any;
    @Input() delay: number;

    // The warning control is a control where validators are setup for
    // the warning conditions, but the errors do not impact the form validity
    @Input() warningControl: AbstractControl;
    @Input() customWarningMessages: any;
    showError = true;

    constructor(
        private translate: TranslateService,
        private commonSvc: CommonService
    ) {}
    async ngOnInit() {
        if (this.delay) {
            this.control.statusChanges.subscribe(async (status) => {
                this.showError = false;
                await new Promise((res) => setTimeout(res, this.delay));
                this.showError = true;
            });
        }
    }

    getText = this.commonSvc.getText;
    onChanges() {}
}
