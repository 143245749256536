import { Injectable } from '@angular/core';
import { environment as env } from '@environment/environment';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root',
})
export class LogService {
    auditLogInfo = {
        typeName: 'audit_log',
        tableName: null, // Should be null for audit_log.
        reason: null, // Should be null for audit_log.
        action: '',
        user_ID: null,
        pageName: null,
    };

    changeLogInfo = {
        typeName: 'change_log',
        tableName: null,
        reason: null,
        action: '',
        user_ID: null,
        pageName: null,
    };

    constructor(private reqSvc: RequestService) {}

    getAuditLogBySiteAndRole(managed_roles: any, managed_sites: any) {
        let dataToSend = {
            managed_roles: managed_roles,
            managed_sites: managed_sites,
        };
        const req: any = {
            operation: 'getAuditLogBySiteAndRole',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBLog, req);
    }

    createAuditLog(logInfo) {
        let dataToSend = {
            logInfo: logInfo,
        };
        const req: any = {
            operation: 'createAuditLog',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBLog, req);
    }
}
