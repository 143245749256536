<div class="container">
    <h4 class="heading-text-2">
        {{ 'UserSettings.Password' | translate }}
    </h4>
    <div class="sub-heading-text">
        {{ 'UserSettings.PasswordSubHeading' | translate }}
    </div>
</div>
<hr />
<div class="row mb-3 wrapper">
    <div></div>
    <div class="left-column">
        <h4 class="desc-heading">
            {{ 'UserSettings.ChangePassword' | translate }}
        </h4>
        <div class="desc-sub-heading">
            {{ 'UserSettings.ChangePasswordDesc' | translate }}
        </div>
    </div>
    <div class="right-column">
        <div class="form-group" [formGroup]="resetPasswordForm">
            <h5 class="password-title required-label">
                {{ 'UserSettings.EnterCurrentPassword' | translate }}
            </h5>
            <div class="password-field d-flex">
                <input
                    #currentPasswordInput
                    [attr.type]="showCharacters.currentPasswordInput ? 'text' : 'password'"
                    id="current-password-input"
                    class="form-control"
                    formControlName="currentpassword"
                    [ngClass]="{
                        'is-valid': passwordValid,
                        'is-invalid': !passwordValid && passwordValid !== undefined,
                    }"
                    [attr.aria-label]="'Login.EnterCurrentPassword' | translate"
                    [attr.placeholder]="'Login.EnterCurrentPassword' | translate"
                    required
                />
                <button
                    class="password-toggle d-flex"
                    [attr.aria-label]="'Login.ShowPassword' | translate"
                    (click)="togglePasswordVisibility('currentPasswordInput')"
                    id="password-toggle"
                    title="{{ text.currentPasswordInput }}"
                >
                    <i
                        class="fa-regular"
                        [ngClass]="{
                            'fa-eye-slash': showCharacters.currentPasswordInput,
                            'fa-eye': !showCharacters.currentPasswordInput,
                        }"
                        id="eye-icon"
                    ></i>
                    <span class="btn-text">{{ text.currentPasswordInput }}</span>
                </button>
            </div>

            <div *ngIf="noCurrentPassword">
                <p class="formError">
                    {{ 'Login.PleaseEnterPassword' | translate }}
                </p>
            </div>
            <div *ngIf="!noCurrentPassword && incorrectCurrentPassword">
                <p class="formError">
                    {{ 'Login.IncorrectPassword' | translate }}
                </p>
            </div>
        </div>
        <app-create-password class="mb-3" (passwordEvent)="handleConfirmPassword($event)" />
        <app-button
            [type]="'submit'"
            (action)="updatePassword()"
            [buttonText]="'UserSettings.UpdatePassword'"
        />
    </div>
</div>
