import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-loading-component',
    templateUrl: './loading-component.component.html',
    styleUrls: ['./loading-component.component.scss'],
})
export class LoadingComponent {
    @Input() loading: boolean;

    @Input() loading$: Observable<boolean>;
    @Input() loadingData$: Observable<{ isLoading: boolean }>;
    @Input() text = 'Registry.Loading';
    @Input() class = 'fas fa-spinner fa-spin';

    constructor(private translate: TranslateService) {}
}
