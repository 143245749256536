import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-notification-setting',
    templateUrl: './notification-settings.component.html',
    styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingComponent {
    constructor(public translate: TranslateService) {}

    ngOnInit(): void {}
}
