import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastService, ToastInfo } from '@h20-shared/services/toast.service'; // Adjust the import path as necessary

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
    toasts$: Observable<ToastInfo[]>;

    constructor(public toastService: ToastService) {
        this.toasts$ = this.toastService.toasts$;
    }

    ngOnInit(): void {}

    remove(toast) {
        this.toastService.remove(toast);
    }
}
