<ng-container *ngIf="theFormControl">
    <ng-container [ngSwitch]="element.type">
        <!--element switch state-->
        <div
            class="ps-3 fs-6 text-left fst-italic text-muted"
            *ngIf="element.preamble || (element.description && !element.descriptionLocation)"
            [innerHtml]="element.preamble ?? element.description"
        ></div>
        <ng-container
            *ngSwitchCase="'html'"
            [ngTemplateOutlet]="htmlTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'dropdown'"
            [ngTemplateOutlet]="dropdownTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'radiogroup'"
            [ngTemplateOutlet]="radioTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'boolean'"
            [ngTemplateOutlet]="booleanTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'droptext'"
            [ngTemplateOutlet]="dropTextTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'logic-builder'"
            [ngTemplateOutlet]="logicBuilderTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'relative-date-validator-builder'"
            [ngTemplateOutlet]="relativeDateValidatorBuilderTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'checkbox'"
            [ngTemplateOutlet]="checkboxTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'datepicker'"
            [ngTemplateOutlet]="datepickerTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'partial-date'"
            [ngTemplateOutlet]="partialDateTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'phone'"
            [ngTemplateOutlet]="phoneTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>

        <ng-container *ngSwitchCase="'text'" [ngSwitch]="element.inputType">
            <ng-container
                *ngSwitchCase=""
                [ngTemplateOutlet]="textTemplate"
                [ngTemplateOutletContext]="{ $implicit: element }"
            ></ng-container>
            <ng-container
                *ngSwitchCase="'text'"
                [ngTemplateOutlet]="textTemplate"
                [ngTemplateOutletContext]="{ $implicit: element }"
            ></ng-container>
            <ng-container
                *ngSwitchCase="'number'"
                [ngTemplateOutlet]="numberTemplate"
                [ngTemplateOutletContext]="{ $implicit: element }"
            ></ng-container>
            <ng-container
                *ngSwitchCase="'tel'"
                [ngTemplateOutlet]="default1Template"
                [ngTemplateOutletContext]="{ $implicit: element }"
            ></ng-container>
            <ng-container
                *ngSwitchCase="'email'"
                [ngTemplateOutlet]="emailTemplate"
                [ngTemplateOutletContext]="{ $implicit: element }"
            ></ng-container>
            <ng-container
                *ngSwitchCase="'date'"
                [ngTemplateOutlet]="dateTemplate"
                [ngTemplateOutletContext]="{ $implicit: element }"
            ></ng-container>
            <ng-container
                *ngSwitchCase="'range'"
                [ngTemplateOutlet]="euroqolSliderTemplate"
                [ngTemplateOutletContext]="{ $implicit: element }"
            ></ng-container>
            <ng-container
                *ngSwitchCase="'slider'"
                [ngTemplateOutlet]="sliderTemplate"
                [ngTemplateOutletContext]="{ $implicit: element }"
            ></ng-container>
            <ng-container
                *ngSwitchCase="'time'"
                [ngTemplateOutlet]="timeTemplate"
                [ngTemplateOutletContext]="{ $implicit: element }"
            ></ng-container>
            <ng-container
                *ngSwitchDefault
                [ngTemplateOutlet]="default1Template"
                [ngTemplateOutletContext]="{ $implicit: element }"
            ></ng-container>
        </ng-container>

        <ng-container
            *ngSwitchCase="'multipletext'"
            [ngTemplateOutlet]="multipleTextTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'comment'"
            [ngTemplateOutlet]="commentTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'html-builder'"
            [ngTemplateOutlet]="htmlBuilderTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'rating'"
            [ngTemplateOutlet]="ratingTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'file'"
            [ngTemplateOutlet]="fileTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'paneldynamic'"
            [ngTemplateOutlet]="dynamicPanelTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'gesture-map-input'"
            [ngTemplateOutlet]="gestureMapTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>

        <ng-container
            *ngSwitchCase="'gesture'"
            [ngTemplateOutlet]="gestureTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <!-- 
        <ng-container
            *ngSwitchCase="'nodeperson'"
            [ngTemplateOutlet]="nodemanTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container> -->

        <ng-container
            *ngSwitchCase="'link-generator'"
            [ngTemplateOutlet]="linkGeneratorTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchCase="'sb-choices'"
            [ngTemplateOutlet]="choiceManagerTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
        <ng-container
            *ngSwitchDefault
            [ngTemplateOutlet]="defaultTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
        ></ng-container>
    </ng-container>
    <div
        class="ps-3 fs-6 text-left fst-italic text-muted"
        *ngIf="
            element.footnote ||
            (element.description &&
                element.descriptionLocation &&
                element.descriptionLocation === 'underInput')
        "
        [innerHtml]="element.footnote ?? element.description"
    ></div>
</ng-container>

<!-- Template Definitions -->
<ng-template #htmlTemplate let-element>
    <app-pi-control-html
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-html>
</ng-template>

<ng-template #dropdownTemplate let-element>
    <app-pi-control-dropdown
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
        [patientId]="patientId"
        [surveyId]="surveyId"
    ></app-pi-control-dropdown>
</ng-template>

<ng-template #radioTemplate let-element>
    <app-pi-control-radiogroup
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-radiogroup>
</ng-template>

<ng-template #booleanTemplate let-element>
    <app-pi-control-boolean
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-boolean>
</ng-template>

<ng-template #dropTextTemplate let-element>
    <app-pi-control-drop-text
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-drop-text>
</ng-template>

<ng-template #logicBuilderTemplate let-element>
    <app-pi-control-logic-builder
        [element]="element"
        [formControl]="theFormControl"
        [formControlList]="formControlList"
        [elementList]="elementList"
        [hideDisabled]="hideDisabled"
        [parentList]="parentList"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-logic-builder>
</ng-template>

<ng-template #relativeDateValidatorBuilderTemplate let-element>
    <app-pi-control-relative-date-validator-builder
        [element]="element"
        [formControl]="theFormControl"
        [formControlList]="formControlList"
        [elementList]="elementList"
        [hideDisabled]="hideDisabled"
        [parentList]="parentList"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-relative-date-validator-builder>
</ng-template>

<ng-template #checkboxTemplate let-element>
    <app-pi-control-checkbox
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-checkbox>
</ng-template>

<ng-template #ratingTemplate let-element>
    <app-pi-control-rating
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-rating>
</ng-template>

<ng-template #euroqolSliderTemplate let-element>
    <app-pi-control-euroqol-slider
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-euroqol-slider>
</ng-template>

<ng-template #sliderTemplate let-element>
    <app-pi-control-slider
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-slider>
</ng-template>

<ng-template #timeTemplate let-element>
    <app-pi-control-time
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-time>
</ng-template>

<ng-template #fileTemplate let-element>
    <app-pi-control-filepicker
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
        [formData]="formData"
        [entryType]="entryType"
        [surveyId]="surveyId"
        (downloadButtonClicked)="downloadEventHandlerFunction($event)"
        (stageFileUploadChanges)="stageFileUploadChangesHandlerFunction($event)"
        (stageFileDeleteChanges)="stageFileDeleteChangesHandlerFunction($event)"
    ></app-pi-control-filepicker>
</ng-template>

<!-- Text Types  -->
<ng-template #textTemplate let-element>
    <app-pi-control-text
        [element]="element"
        [formControl]="theFormControl"
        [formControlList]="formControlList"
        [elementList]="elementList"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-text>
</ng-template>

<ng-template #numberTemplate let-element>
    <app-pi-control-number
        [element]="element"
        [formControl]="theFormControl"
        [formControlList]="formControlList"
        [elementList]="elementList"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-number>
</ng-template>

<ng-template #phoneTemplate let-element>
    <app-pi-control-phone
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-phone>
</ng-template>

<ng-template #emailTemplate let-element>
    <app-pi-control-email
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-email>
</ng-template>

<ng-template #dateTemplate let-element>
    <app-pi-control-date
        [element]="element"
        [formControl]="theFormControl"
        [formControlList]="formControlList"
        [elementList]="elementList"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-date>
</ng-template>

<ng-template #datepickerTemplate let-element>
    <app-pi-control-date-picker
        [element]="element"
        [formControl]="theFormControl"
        [formControlList]="formControlList"
        [elementList]="elementList"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-date-picker>
</ng-template>

<ng-template #partialDateTemplate let-element>
    <app-pi-control-partial-date
        [element]="element"
        [formControl]="theFormControl"
        [formControlList]="formControlList"
        [elementList]="elementList"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-partial-date>
</ng-template>

<ng-template #multipleTextTemplate let-element>
    <app-pi-control-multitext
        [element]="element"
        [formControl]="theFormControl"
        [formControlList]="formControlList"
        [elementList]="elementList"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-multitext>
</ng-template>

<ng-template #commentTemplate let-element>
    <app-pi-control-comment
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-comment>
</ng-template>

<ng-template #htmlBuilderTemplate let-element>
    <app-pi-control-html-builder
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-html-builder>
</ng-template>

<ng-template #gestureTemplate let-element>
    <app-pi-control-gesture
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-gesture>
</ng-template>

<ng-template #gestureMapTemplate let-element>
    <app-pi-control-gesture-map
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-gesture-map>
</ng-template>

<ng-template #nodemanTemplate let-element>
    <app-pi-control-nodeman
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-nodeman>
</ng-template>

<ng-template #choiceManagerTemplate let-element>
    <app-surveybuilder-choice-manager
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-surveybuilder-choice-manager>
</ng-template>

<!-- Extras -->

<ng-template #dynamicPanelTemplate let-element>
    <app-pi-control-dynamic-panel
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-dynamic-panel>
</ng-template>
<ng-template #linkGeneratorTemplate let-element>
    <app-pi-control-link-generator
        [element]="element"
        [formControl]="theFormControl"
        [hideDisabled]="hideDisabled"
        [mode]="mode"
        [options]="options"
    ></app-pi-control-link-generator>
</ng-template>

<!-- Default -->
<ng-template #default1Template let-element>
    {{ element.type }} - UnMapped text type: {{ element.inputType }}
</ng-template>

<ng-template #defaultTemplate let-element>
    {{ element.type }}
</ng-template>
