import { Injectable } from '@angular/core';
import { environment as env } from '@environment/environment';
import { Observable } from 'rxjs';
import { ITracker } from './models/ITracker';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root',
})
export class TrackerService {
    constructor(private reqSvc: RequestService) {}

    create(tracker: ITracker): Observable<any> {
        const request = {
            operation: 'create',
            data: tracker,
        };

        return this.reqSvc.post(env.API.writeToDBTrackers, request);
    }

    update(tracker: ITracker): Observable<any> {
        const request = {
            operation: 'update',
            data: tracker,
        };

        return this.reqSvc.post(env.API.writeToDBTrackers, request);
    }
}
