import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EnvType } from '@h20-services/common.service';
import { DeployService } from '@h20-services/deploy.service';
import { ManageConfigMode } from '@h20-services/enums/deploy/ManageConfigMode';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

@Component({
    selector: 'app-manage-config-detail',
    templateUrl: './manage-config-detail.component.html',
    styleUrls: ['./manage-config-detail.component.scss'],
})
export class ManageConfigDetailComponent implements OnInit {
    targetEnv = EnvType.Development;
    mode: string;
    isNewConfig: boolean;

    selectedTableKey: string;
    partitionKeyName: string;
    partitionKey: string;
    tempPKey: string;
    configItem: any;
    configItemRest: any;

    loading: boolean = true;
    submitLoading: boolean = false;
    message: string;

    //modal
    @Input() Viewparam: any;
    isModalOpened: boolean = false;

    jsonEditorOptions: JsonEditorOptions;
    @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;

    configForm: FormGroup = this.fb.group({
        partition_key: ['', [Validators.required]],
        latest_version: [''],
        created: [''],
    });

    constructor(
        private deploySvc: DeployService,
        private route: ActivatedRoute,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.isModalOpened = false;
            this.loadConfigs(params);
        });

        if (!!this.Viewparam) {
            this.isModalOpened = true;
            this.loadConfigs(this.Viewparam);
        }
    }

    loadConfigs(params) {
        if (params) {
            this.mode = params.mode;
            this.targetEnv = params.targetEnv;
            this.selectedTableKey = params.selectedTable;
            this.partitionKeyName = params.partitionKeyName;
            this.partitionKey = params.partitionKey;

            if (this.mode === ManageConfigMode.edit) {
                this.isNewConfig = false;
                this.loadConfigItem();
            } else if (this.mode === ManageConfigMode.view) {
                this.isNewConfig = false;
                this.loadConfigItem();
            } else if (this.mode === ManageConfigMode.duplicate) {
                this.isNewConfig = true;
                this.tempPKey = params.partitionKey;
                this.loadConfigItem();
            } else if (this.mode === ManageConfigMode.create) {
                this.isNewConfig = true;
                this.partitionKey = null;
                this.loading = false;
            }

            this.jsonEditorOptions = new JsonEditorOptions();
            if (this.mode === ManageConfigMode.view) {
                this.jsonEditorOptions.modes = ['view'];
                this.jsonEditorOptions.mode = 'view'; //initial mode
            } else {
                this.jsonEditorOptions.modes = ['code', 'text', 'tree', 'view'];
                this.jsonEditorOptions.mode = 'code';
            }
        }
    }

    loadConfigItem(): void {
        this.deploySvc
            .getConfigItem(this.selectedTableKey, this.partitionKey, this.targetEnv)
            .toPromise()
            .then((confRes) => {
                this.configItem = confRes;
                this.configForm = this.fb.group({
                    partition_key: [this.configItem[this.partitionKeyName], [Validators.required]],
                    latest_version: [this.configItem.latest_version || this.configItem.version],
                    created: [this.configItem.created],
                });

                const {
                    [this.partitionKeyName]: removePK,
                    ['version']: removeSK,
                    ['latest_version']: removeLatestVersion,
                    ['created']: removeCreated,
                    ...rest
                } = confRes;
                this.configItemRest = rest;

                this.loading = false;
            })
            .catch((err) => {
                this.message = `Cannot load the config item on ${this.targetEnv}`;
                this.loading = false;
            });
    }

    configIsValid(): boolean {
        if (
            this.mode === ManageConfigMode.duplicate &&
            this.tempPKey === this.configForm.value.partition_key
        ) {
            this.message = 'Please change the partition key.';
            return false;
        } else return true;
    }

    async submit(): Promise<void> {
        this.submitLoading = true;

        if (!this.configIsValid()) {
            this.submitLoading = false;
        } else {
            this.message = '';
            try {
                //@ts-ignore
                this.configItemRest = this.editor.get();

                if (this.isNewConfig) {
                    this.partitionKey = this.configForm.value.partition_key;

                    const existItem = await this.deploySvc
                        .getConfigItem(this.selectedTableKey, this.partitionKey, this.targetEnv)
                        .toPromise();

                    if (existItem) {
                        this.message = 'This Item already exists.';
                    } else {
                        await this.deploySvc.createConfigItem(
                            this.selectedTableKey,
                            this.partitionKey,
                            null, // Should be null to be version 1
                            this.configItemRest,
                            this.targetEnv
                        );
                        this.message = 'Config is created successfully.';
                    }
                    this.submitLoading = false;
                } else {
                    await this.deploySvc
                        .updateConfigItem(
                            this.selectedTableKey,
                            this.partitionKey,
                            null, // Should be null to increment by 1
                            this.configItemRest,
                            this.targetEnv
                        )
                        .toPromise();
                    this.submitLoading = false;
                    this.message = 'Config is updated successfully.';
                    this.loadConfigItem();
                }
            } catch (err) {
                console.error(err);
                this.submitLoading = false;
            }
        }
    }
}
