<div class="bg-white p-3 w-100">
    <div class="d-flex w-100">
        <div class="flex-shrink-0 border-end" style="width: 16.6667%">
            <div class="bg-white p-3 ps-0">
                <div class="d-flex w-100">
                    <div class="border-end w-100">
                        <ul class="nav flex-column nav-pills nav-justified w-100">
                            <li
                                *ngFor="let panel of surveyPanels; let i = index"
                                class="nav-item my-1 w-100"
                            >
                                <div
                                    class="accordion accordion-flush"
                                    [att.id]="'panelAccordion' + i"
                                >
                                    <div class="accordion-item border-0">
                                        <div
                                            class="accordion-header d-flex align-items-center justify-content-between primary"
                                            id="panelAccordion-heading"
                                        >
                                            <div
                                                class="flex-grow-1 me-3"
                                                style="cursor: pointer"
                                                data-bs-toggle="collapse"
                                                [attr.data-bs-target]="
                                                    '#panelAccordion-collapse' + i
                                                "
                                                aria-expanded="false"
                                                [attr.aria-controls]="'panelAccordion-collapse' + i"
                                            >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    [value]="
                                                        '#' + (i + 1) + ' ' + getText(panel.title)
                                                    "
                                                    disabled
                                                    style="
                                                        background-color: transparent;
                                                        color: white;
                                                        border: none;
                                                        box-shadow: none;
                                                        pointer-events: none;
                                                        font-size: 1.1rem;
                                                    "
                                                />
                                            </div>

                                            <div class="dropdown section-options">
                                                <i
                                                    class="fas fa-ellipsis-v me-2 section-ellipsis px-2 dropdown-toggle"
                                                    role="button"
                                                    id="dropdownMenuButton{{ i }}"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                ></i>
                                                <ul
                                                    class="dropdown-menu dropdown-menu-end"
                                                    aria-labelledby="dropdownMenuButton{{ i }}"
                                                >
                                                    <li
                                                        class="dropdown-item"
                                                        (click)="deleteSection(i)"
                                                    >
                                                        Delete
                                                        <i
                                                            class="fa fa-trash ms-2"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div
                                            [attr.id]="'panelAccordion-collapse' + i"
                                            class="accordion-collapse collapse"
                                            [attr.aria-labelledby]="'panelAccordion-heading' + i"
                                            data-bs-parent=".accordion"
                                        >
                                            <div class="accordion-body w-100 px-0">
                                                <div>
                                                    <div
                                                        class="d-flex justify-content-end mb-2 w-100"
                                                        style="width: 100%"
                                                    >
                                                        <ul
                                                            id="element-nav-list"
                                                            style="padding-left: 0; width: 100%"
                                                        >
                                                            <li
                                                                *ngFor="
                                                                    let element of panel.elements;
                                                                    let ind = index
                                                                "
                                                                class="nav-item d-flex w-100 align-items-center border border-1"
                                                            >
                                                                <a
                                                                    class="nav-link d-flex align-items-center w-100 px-3 py-1"
                                                                    [ngClass]="
                                                                        ind === currentEditor &&
                                                                        i === panelIndex
                                                                            ? 'active'
                                                                            : ''
                                                                    "
                                                                    id="{{
                                                                        'nav-' +
                                                                            element.name +
                                                                            '-tab'
                                                                    }}"
                                                                    role="tab"
                                                                    (click)="changeEditor(ind, i)"
                                                                >
                                                                    <span
                                                                        class="fa-stack tiny-text mx-1"
                                                                    >
                                                                        <i
                                                                            class="fa-solid fa-square fa-stack-2x"
                                                                            [ngClass]="
                                                                                getColor(element)
                                                                            "
                                                                        ></i>
                                                                        <i
                                                                            class="fa-solid fa-stack-1x fa-inverse"
                                                                            [ngClass]="
                                                                                getIcon(element)
                                                                            "
                                                                        ></i>
                                                                    </span>
                                                                    <div
                                                                        class="font-monospace me-3"
                                                                    >
                                                                        #{{ ind + 1 }}
                                                                    </div>
                                                                    <span
                                                                        *ngIf="navExpanded"
                                                                        class="text-wrap w-100 text-start font-size-13"
                                                                    >
                                                                        <div
                                                                            class="displayed-characters"
                                                                        >
                                                                            {{
                                                                                getText(
                                                                                    element.title
                                                                                )
                                                                            }}
                                                                        </div></span
                                                                    >
                                                                </a>
                                                            </li>
                                                            <button
                                                                class="btn btn-light btn-md"
                                                                (click)="
                                                                    addAfter(
                                                                        panel.elements.length,
                                                                        i
                                                                    )
                                                                "
                                                            >
                                                                Add New Question<i
                                                                    class="fas fa-plus"
                                                                ></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <button
                                class="btn btn-outline-primary"
                                title="Add a panel"
                                (click)="addPanel()"
                            >
                                <div class="fs-6 fas fa-regular fa-plus">Add Panel</div>
                            </button>
                        </ul>
                    </div>
                </div>
            </div>
            <app-modal
                *ngIf="adding"
                [(visible)]="adding"
                header="Add Element"
                [context]="this"
                [closeFunc]="clearAdding"
            >
                <h3>Search the library</h3>
                <app-fragment-search (addElementEvent)="addPrefab($event)"></app-fragment-search>
                <hr />
                <h3>Create a new question</h3>
                <div class="row">
                    <div *ngFor="let element of ELEMENT_TYPES" class="col-3 my-1">
                        <div
                            class="p-4 w-100 shadow-sm border border-1 rounded d-flex align-items-center justify-content-start"
                            (click)="confirmAdd(element.text)"
                        >
                            <div class="fa-stack fs-4">
                                <i
                                    class="fa-solid fa-square fa-stack-2x"
                                    [ngClass]="element.color"
                                ></i>
                                <i
                                    class="fa-solid fa-stack-1x fa-inverse"
                                    [ngClass]="element.icon"
                                ></i>
                            </div>
                            <div>{{ getText(element.displayText) }}</div>
                        </div>
                    </div>
                </div>
            </app-modal>
        </div>

        <div class="flex-grow-1" style="width: 58%; max-width: 66.6667%">
            <app-rtv-test-section
                [(survey)]="survey"
                [randomNumber]="randomNumber"
                [elementIndex]="elementIndex"
            ></app-rtv-test-section>
        </div>

        <div style="width: 32%" tabindex="0" class="tab-content flex-grow-1 ms-3">
            <ng-container *ngIf="currentElement as element">
                <app-rtv-configuration-section
                    [(survey)]="survey"
                    [currentElement]="currentElement"
                    [panelIndex]="panelIndex"
                    [elementIndex]="elementIndex"
                    (elementChange)="elementChange($event)"
                ></app-rtv-configuration-section>
            </ng-container>
            <!--
            <ng-container *ngFor="let panel of survey.pages[0].elements; let i = index"> 
                <ng-container *ngFor="let element of panel.elements; let idx = index"> 
                    <app-rtv-configuration-section id="{{ 'elementConf_' + i + '_' + idx }}" hidden [survey]="survey" [elementIndex]="idx"></app-rtv-configuration-section>
                </ng-container>
            </ng-container>
            -->
        </div>
    </div>
</div>
