<div>
    <h1>My Library Content:</h1>
    <app-loading-component [loading]="!isInit">
        <label>
            Select Registry:
            <select class="form-control" (change)="reloadRegistry($event.target.value)">
                <option selected disabled>select</option>
                <option *ngFor="let reg of listRegistries">
                    {{ reg }}
                </option>
            </select>
        </label>
        <label class="mx-2">
            Site
            <select class="form-control" (change)="reloadSite($event.target.value)">
                <option selected disabled>select</option>
                <option *ngFor="let site of listSites">
                    {{ site.name }}
                </option>
            </select>
        </label>
        <label class="mx-2">
            Organization
            <select class="form-control" (change)="reloadOrganization($event.target.value)">
                <option selected disabled>default</option>
            </select>
        </label>
        <label class="mx-2">
            Role
            <select class="form-control" (change)="reloadRole($event.target.value)">
                <option selected>default</option>
                <option *ngFor="let role of listRoles">
                    {{ role }}
                </option>
            </select>
        </label>
        <label class="mx-2">
            Language
            <select class="form-control" (change)="reloadLanguage($event.target.value)">
                <option *ngFor="let lang of listLanguages">
                    {{ lang }}
                </option>
            </select>
        </label>
        <label class="mx-2">
            Page
            <select class="form-control" (change)="reloadPage($event.target.value)">
                <option *ngFor="let page of listPages">
                    {{ page }}
                </option>
            </select>
        </label>
        <app-button class="mx-3" (action)="saveLibrary()" [buttonText]="'Registry.SaveChanges'" />
        <small *ngIf="message" class="text-danger">
            {{ message }}
        </small>
        <app-loading-component [loading]="saveLoading" [text]="'saving'"></app-loading-component>
        <br />
        <small class="text-muted">
            * Registry and Site are required. Organization is only default. (TBD)
        </small>
        <br />
        <small class="text-muted">
            * Role: We use only 'Default' for now. It covers all roles and library menu is handled
            by claims. (TBD)
        </small>
        <br />
        <small class="text-muted"> * Default language is 'en'. Default page is 'page1'. </small>
        <hr />
    </app-loading-component>
</div>
<app-loading-component
    *ngIf="registry_id && selectedSite && selectedLibrary && isInit"
    [loading]="loading"
>
    <div class="container-fluid panel-control">
        <div class="row">
            <div class="col col-md-5 col-lg-4 col-xl-3">
                <ul class="list-group">
                    <li class="list-group-item">
                        <h1 class="mt-2">
                            Library
                            <i
                                class="fas fa-info-circle h5 mb-0"
                                attr.aria-label="{{ 'Icons.Info' }}"
                                tooltip="{{ 'Library.LibraryTooltip' }}"
                                placement="bottom"
                                hideDelayTouchscreen="1"
                            ></i>
                        </h1>
                    </li>
                    <div *ngFor="let section of Categories; let c = index">
                        <li
                            *ngIf="showFor(section)"
                            class="list-group-item"
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            [attr.data-target]="['#' + section.catId]"
                            [attr.aria-controls]="section.catId"
                            [class.selected]="section === selectedCategory"
                            (click)="onSelect(section)"
                        >
                            <i
                                *ngIf="section === selectedCategory"
                                attr.aria-label="{{ 'Icons.Circle' }}"
                                class="fas fa-circle h6 mb-0 me-2"
                            ></i>
                            <app-preview-editor
                                class="d-inline-block"
                                placeholder="Category Title"
                                [(value)]="section.catTitle"
                                [required]="true"
                            >
                                <span
                                    [ngClass]="
                                        section === selectedCategory ? 'font-weight-bold' : ''
                                    "
                                >
                                    {{ section.catTitle }}
                                </span>
                            </app-preview-editor>

                            <i
                                class="far fa-minus-circle float-right"
                                (click)="removeCategory(section)"
                            ></i>
                        </li>
                    </div>
                    <li class="list-group-item">
                        <i
                            class="far fa-regular fa-plus-circle float-right"
                            (click)="addCategory()"
                        ></i>
                    </li>
                </ul>
            </div>
            <div
                *ngIf="selectedCategory"
                id="accordiongroup"
                class="col-12 col-md-7 col-lg-8 col-xl-9 mt-3 mt-md-0"
            >
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="border-right pe-3">
                                <i
                                    id="this-cat"
                                    onclick="document.getElementById('this-cat').classList.toggle('fa-caret-up');"
                                    data-toggle="collapse"
                                    href="#collapseDesc1"
                                    role="button"
                                    aria-expanded="true"
                                    aria-controls="collapseDesc1"
                                    attr.aria-label="{{ 'Icons.ExpandCollapse' }}"
                                    class="fas fa-caret-down fa-caret-up h4"
                                ></i>
                            </div>
                            <div class="col" *ngIf="selectedCategory">
                                <h2 class="mb-0">
                                    {{ selectedCategory.catTitle }}
                                    <i
                                        *ngIf="selectedCategory.catTooltip"
                                        class="fas fa-info-circle h5 mb-0"
                                        attr.aria-label="{{ 'Icons.Info' }}"
                                        tooltip="{{ selectedCategory.catTooltip }}"
                                        placement="bottom"
                                        hideDelayTouchscreen="1"
                                    ></i>
                                </h2>
                                <div class="collapse show" id="collapseDesc1">
                                    <small class="text-muted">
                                        <strong>LastModified</strong>
                                        <app-preview-editor
                                            class="d-inline-block mx-2"
                                            placeholder="Category DateModified"
                                            [(value)]="selectedCategory.catDateModified"
                                            [required]="true"
                                        >
                                            {{ selectedCategory.catDateModified }}
                                        </app-preview-editor>
                                    </small>
                                    <app-preview-editor
                                        placeholder="Category Description"
                                        [(value)]="selectedCategory.catDescription"
                                    >
                                        <p
                                            class="mb-0"
                                            [innerHtml]="selectedCategory.catDescription"
                                        ></p>
                                    </app-preview-editor>
                                </div>
                            </div>
                        </div>

                        <ul>
                            <div *ngFor="let e of categorySubCat; let ind = index">
                                <li
                                    *ngIf="showFor(e)"
                                    (click)="(null)"
                                    id="accordionContent"
                                    class="accordion list-group list-group-flush media px-2"
                                >
                                    <div
                                        style="cursor: pointer"
                                        data-toggle="collapse"
                                        [attr.data-target]="['#ID' + e.subId]"
                                        aria-expanded="false"
                                        [attr.aria-controls]="'ID' + e.subId"
                                    >
                                        <div class="float-left" style="display: grid">
                                            <app-preview-editor
                                                class="d-inline-block"
                                                placeholder="Subcategory Icon src path"
                                                [(value)]="e.imgThumb"
                                            >
                                                <img
                                                    *ngIf="e.imgThumb"
                                                    class="me-3 d-none d-sm-inline w-auto"
                                                    src="{{ e.imgThumb }}"
                                                    alt="{{ e.imgAlt }}"
                                                />
                                            </app-preview-editor>
                                            <app-preview-editor
                                                class="d-inline-block"
                                                placeholder="Subcategory Icon alt text"
                                                [(value)]="e.imgAlt"
                                            >
                                                <small>
                                                    img alt text:
                                                    {{ e.imgAlt }}
                                                </small>
                                            </app-preview-editor>

                                            <div
                                                *ngIf="!e.imgThumb"
                                                class="me-3 d-none d-sm-inline w-auto"
                                            >
                                                <i [class]="e.icon || 'fas fa-circle'"></i>
                                            </div>
                                        </div>
                                        <div class="media-body">
                                            <div class="subcat-details">
                                                <app-preview-editor
                                                    placeholder="Subcategory Title"
                                                    [(value)]="e.subTitle"
                                                    [required]="true"
                                                >
                                                    <h5 class="mb-0">
                                                        {{ e.subTitle }}
                                                        <i
                                                            *ngIf="e.linkExternalUrl"
                                                            attr.aria-label="{{
                                                                'Icons.ExternalLink'
                                                            }}"
                                                            class="fas fa-external-link-square-alt fa-2x ps-3 float-right"
                                                        ></i>
                                                    </h5>
                                                </app-preview-editor>

                                                <small class="text-danger">
                                                    If you choose to provide an external link,
                                                    clicking this element will open the link instead
                                                    of showing the accordion below
                                                </small>
                                                <app-preview-editor
                                                    class="d-inline-block mx-2"
                                                    placeholder="Subcategory linkExternalUrl"
                                                    [(value)]="e.linkExternalUrl"
                                                    ><small>
                                                        The link:
                                                        {{ e.linkExternalUrl }}
                                                    </small>
                                                </app-preview-editor>

                                                <small class="text-muted">
                                                    <app-preview-editor
                                                        placeholder="Subcategory Source"
                                                        [(value)]="e.libSource"
                                                    >
                                                        <span innerHTML="{{ e.libSource }}"></span>
                                                    </app-preview-editor>
                                                    <br />
                                                    <app-preview-editor
                                                        placeholder="Subcategory DateAdded"
                                                        [(value)]="e.subDateAdded"
                                                        [required]="true"
                                                    >
                                                        <strong>Date Added:</strong>
                                                        {{ e.subDateAdded }}
                                                    </app-preview-editor>

                                                    <br />
                                                    <strong>Library:</strong>
                                                    {{ selectedCategory.catTitle }}
                                                </small>
                                            </div>
                                            <i
                                                class="far fa-minus-circle float-right"
                                                (click)="removeSubcategory(categorySubCat, e)"
                                            >
                                                remove Subcategory
                                            </i>
                                        </div>
                                    </div>

                                    <div class="ps-4">
                                        <small class="text-danger">
                                            The following only renders if no external URL is
                                            provided
                                        </small>
                                        <div
                                            *ngIf="!e.linkExternalUrl"
                                            class="col px-0 mt-4"
                                            id="{{ 'ID' + e.subId }}"
                                            aria-labelledby="{{ 'lbl' + e.subId }}"
                                            data-parent="#accordionContent"
                                        >
                                            <!-- Subcategory Content -->
                                            <app-preview-editor
                                                placeholder="Subcategory Subheading"
                                                [(value)]="e.subHeading"
                                            >
                                                <h5 innerHTML="{{ e.subHeading }}"></h5>
                                            </app-preview-editor>

                                            <app-preview-editor
                                                placeholder="Subcategory Subcontent"
                                                [(value)]="e.subContent"
                                                [textarea]="true"
                                            >
                                                <div innerHTML="{{ e.subContent }}"></div>
                                            </app-preview-editor>

                                            <!-- Links Array -->
                                            <small class="text-danger">
                                                The following Links section only renders if provided
                                            </small>
                                            <div>
                                                <div
                                                    *ngFor="
                                                        let l of e.Links;
                                                        let i = index;
                                                        let first = first
                                                    "
                                                >
                                                    <div *ngIf="first">
                                                        <hr class="section-divider" />
                                                        <h5 class="pre-hr mb-3">
                                                            <i
                                                                class="fas fa-link"
                                                                attr.aria-label="{{
                                                                    'Icons.ExternalLink'
                                                                }}"
                                                            ></i>
                                                            Links:
                                                        </h5>
                                                    </div>
                                                    <div>
                                                        <hr *ngIf="i > 0" />
                                                        <h6 *ngIf="l.linkHeading">
                                                            <iframe
                                                                *ngIf="l.video"
                                                                width="560"
                                                                height="315"
                                                                [src]="urlLinks[ind][i]"
                                                                title="YouTube video player"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allow="fullscreen"
                                                            ></iframe>
                                                            <a
                                                                *ngIf="!l.video"
                                                                href="{{ l.linkUrl }}"
                                                                target="_blank"
                                                                >{{ l.linkHeading }}</a
                                                            >
                                                        </h6>
                                                        <div
                                                            *ngIf="l.linkTitle; else show_link_only"
                                                        >
                                                            <span
                                                                class="font-italic font-weight-bold"
                                                                *ngIf="l.linkUrlPrefix"
                                                                >{{ l.linkUrlPrefix }}
                                                            </span>
                                                            {{ l.linkTitle }}
                                                        </div>
                                                        <i
                                                            class="far fa-minus-circle float-right"
                                                            (click)="removeLink(e, l)"
                                                            >Remove link</i
                                                        >
                                                        <ng-template #show_link_only>
                                                            <span
                                                                class="font-italic"
                                                                *ngIf="l.linkUrlPrefix"
                                                                >{{ l.linkUrlPrefix }}
                                                            </span>
                                                            <a
                                                                href="{{ l.linkUrl }}"
                                                                target="_blank"
                                                                >{{ l.linkUrl }}</a
                                                            >
                                                        </ng-template>
                                                    </div>
                                                </div>
                                            </div>
                                            <app-modal
                                                [(visible)]="linksModal"
                                                [header]="'Add Link'"
                                                [saveFunc]="saveLink"
                                                [context]="this"
                                            >
                                                <small
                                                    >The heading is used as a header above the
                                                    link</small
                                                >
                                                <label class="d-block">
                                                    linkHeading:
                                                    <input
                                                        type="text"
                                                        [value]="linkTemplate.linkHeading"
                                                        (change)="
                                                            linkTemplate.linkHeading =
                                                                $event.target.value
                                                        "
                                                    />
                                                </label>
                                                <small>The title is used as Hyperlink text</small>
                                                <label class="d-block">
                                                    linkTitle:
                                                    <input
                                                        type="text"
                                                        [value]="linkTemplate.linkTitle"
                                                        (change)="
                                                            linkTemplate.linkTitle =
                                                                $event.target.value
                                                        "
                                                    />
                                                </label>
                                                <small
                                                    >The prefix is raw text to be shown before the
                                                    link</small
                                                >
                                                <label class="d-block">
                                                    linkUrlPrefix:
                                                    <input
                                                        type="text"
                                                        [value]="linkTemplate.linkUrlPrefix"
                                                        (change)="
                                                            linkTemplate.linkUrlPrefix =
                                                                $event.target.value
                                                        "
                                                    />
                                                </label>
                                                <small>This is the link or embed itself</small>
                                                <label class="d-block">
                                                    linkUrl:
                                                    <input
                                                        type="text"
                                                        [value]="linkTemplate.linkUrl"
                                                        (change)="
                                                            linkTemplate.linkUrl =
                                                                $event.target.value
                                                        "
                                                    />
                                                </label>
                                                <small
                                                    >check this option only if the link is an
                                                    embedded video</small
                                                >
                                                <label class="d-block">
                                                    is video:
                                                    <input
                                                        type="checkbox"
                                                        [value]="linkTemplate.video"
                                                        (change)="
                                                            linkTemplate.video =
                                                                $event.target.checked
                                                        "
                                                    />
                                                </label>
                                            </app-modal>
                                            <app-button
                                                class="d-block"
                                                (action)="addLink(e)"
                                                [buttonText]="'Registry.AddLink'"
                                            />

                                            <!-- Media Array-->
                                            <small class="text-danger">
                                                The following Media section only renders if provided
                                            </small>
                                            <div *ngIf="e.Media?.length">
                                                <div
                                                    class="mt-3 loop-ctrl"
                                                    *ngFor="
                                                        let m of e.Media;
                                                        let x = index;
                                                        let first = first
                                                    "
                                                >
                                                    <div *ngIf="m.mediaFile && first">
                                                        <hr class="section-divider" />
                                                        <h5 class="pre-hr mb-3">
                                                            <i
                                                                class="fas fa-images"
                                                                attr.aria-label="{{
                                                                    'Icons.Images'
                                                                }}"
                                                            ></i>
                                                            {{ 'Library.Media' }}
                                                        </h5>
                                                    </div>
                                                    <div *ngIf="m.mediaFile">
                                                        <hr *ngIf="x > 0" />
                                                        <div class="text-center">
                                                            <img
                                                                src="{{ m.mediaFile }}"
                                                                alt="{{ m.mediaDescript }}"
                                                            />
                                                            <i
                                                                class="far fa-minus-circle float-right"
                                                                (click)="removeMedia(e, m)"
                                                                >Remove media</i
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <app-modal
                                                [(visible)]="mediaModal"
                                                [header]="'Add Media'"
                                                [saveFunc]="saveMedia"
                                                [context]="this"
                                            >
                                                <small>Image description</small>
                                                <label class="d-block">
                                                    mediaDescript:
                                                    <input
                                                        type="text"
                                                        [value]="mediaTemplate.mediaDescript"
                                                        (change)="
                                                            mediaTemplate.mediaDescript =
                                                                $event.target.value
                                                        "
                                                    />
                                                </label>
                                                <small>Image path</small>
                                                <label class="d-block">
                                                    mediaFile:
                                                    <input
                                                        type="text"
                                                        [value]="mediaTemplate.mediaFile"
                                                        (change)="
                                                            mediaTemplate.mediaFile =
                                                                $event.target.value
                                                        "
                                                    />
                                                </label>
                                            </app-modal>
                                            <app-button
                                                class="d-block"
                                                (action)="addMedia(e)"
                                                [buttonText]="'Registry.AddMedia'"
                                            />

                                            <!-- Download Array-->
                                            <small class="text-danger">
                                                The following Download section only renders if
                                                provided
                                            </small>
                                            <div *ngIf="e.Download?.length">
                                                <div
                                                    class="mt-3"
                                                    *ngFor="
                                                        let d of e.Download;
                                                        let y = index;
                                                        let first = first
                                                    "
                                                >
                                                    <div *ngIf="d.downloadUrl && first">
                                                        <hr class="section-divider" />
                                                        <h5 class="pre-hr mb-3">
                                                            <i
                                                                class="fas fa-download"
                                                                attr.aria-label="{{
                                                                    'Icons.Download'
                                                                }}"
                                                            ></i>
                                                            {{ 'Library.Download' }}
                                                        </h5>
                                                    </div>
                                                    <div *ngIf="d.downloadUrl">
                                                        <hr *ngIf="y > 0" />
                                                        <div
                                                            *ngIf="
                                                                d.downloadTitle;
                                                                else show_downloadlink_only
                                                            "
                                                        >
                                                            <i
                                                                class="fas fa-file-download me-2 text-black-50"
                                                                attr.aria-label="{{
                                                                    'Icons.Download'
                                                                }}"
                                                            ></i>
                                                            <a
                                                                href="{{ d.downloadUrl }}"
                                                                target="_blank"
                                                                >{{ d.downloadTitle }}</a
                                                            >
                                                        </div>
                                                        <ng-template #show_downloadlink_only>
                                                            <i
                                                                class="fas fa-file-download me-2 text-black-50"
                                                                attr.aria-label="{{
                                                                    'Icons.Download'
                                                                }}"
                                                            ></i>
                                                            <a
                                                                href="{{ d.downloadUrl }}"
                                                                target="_blank"
                                                                >{{ d.downloadUrl }}</a
                                                            >
                                                        </ng-template>
                                                        <i
                                                            class="far fa-minus-circle float-right"
                                                            (click)="removeDownload(e, d)"
                                                            >Remove download</i
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                            <app-modal
                                                [(visible)]="downloadModal"
                                                [header]="'Add Download'"
                                                [saveFunc]="saveDownload"
                                                [context]="this"
                                            >
                                                <small>Download title</small>
                                                <label class="d-block">
                                                    <input
                                                        type="text"
                                                        [value]="downloadTemplate.downloadTitle"
                                                        (change)="
                                                            downloadTemplate.downloadTitle =
                                                                $event.target.value
                                                        "
                                                    />
                                                </label>
                                                <small>Download URL</small>
                                                <label class="d-block">
                                                    <input
                                                        type="text"
                                                        [value]="downloadTemplate.downloadUrl"
                                                        (change)="
                                                            downloadTemplate.downloadUrl =
                                                                $event.target.value
                                                        "
                                                    />
                                                </label>
                                            </app-modal>
                                            <app-button
                                                class="d-block"
                                                (action)="addDownload(e)"
                                                [buttonText]="'Registry.AddDownload'"
                                            />
                                        </div>
                                    </div>
                                </li>
                            </div>
                            <div>
                                <li class="list-group">
                                    <i
                                        class="far fa-regular fa-plus-circle float-right"
                                        (click)="addSubCategory(categorySubCat)"
                                    >
                                        add Subcategory
                                    </i>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-loading-component>
<div *ngIf="registry_id && selectedSite && !selectedLibrary && isInit">
    The selected Registry has no Library. Would you like to create one?
    <app-button (action)="createLibrary()" [buttonText]="'Registry.CreateNow'" />
</div>
