import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@h20-services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { UserStatus } from '@h20-services/models/status';
import { AuthService } from '@h20-services/auth.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    @Input() tableauData: any[];
    @Input() showTableau: boolean;
    @Input() hasDashboardTab: boolean;
    @Input() tableauBaseUrl: string;
    @Input() viewTabs: any[];
    @Input() showEarlyBird: boolean;
    @Input() earlyTitle: string;
    @Input() earlyMessage: string;
    @Input() earlyImage: string;
    @Input() showHighcharts: boolean;
    @Input() highchartsData: any[];
    isConsentRequired = true;

    constructor(
        private commonSvc: CommonService,
        public translate: TranslateService,
        private auth: AuthService
    ) {}

    ngOnInit() {
        this.auth.getPulseAuth().then((pulseAuth) => {
            const status = pulseAuth.status as UserStatus;
            // ToDo: isConsentRequired is used to show/hide the dashboard based on the logged user's status.
            // We will need to update this for the new consent flow.
            this.isConsentRequired = [
                UserStatus.Consent,
                UserStatus.MigratedConsent,
                UserStatus.Pending,
                UserStatus.Assent,
            ].includes(status)
                ? true
                : false;
            this.isConsentRequired =
                pulseAuth.registryKey === 'asmd' ? false : this.isConsentRequired;
        });
    }

    openViewTab(event, tabViewName) {
        this.showEarlyBird = false;
        this.showHighcharts = false;
        this.showTableau = false;

        this.tableauData = this.tableauData?.map((view) => {
            let tabTableauBtn = document.getElementById(`btn-${view.viewName}`);
            tabTableauBtn?.classList.remove('active');
            if (view.selected) {
                view.selected = false;
                let tabTableauView = document.getElementById(view.viewName);
                if (tabTableauView) tabTableauView.style.display = 'none';
            }
            return view;
        });
        let tabHighBtn = document.getElementById('btn-highcharts');
        tabHighBtn?.classList.remove('active');

        let tabEbBtn = document.getElementById('btn-early_bird');
        tabEbBtn?.classList.remove('active');

        event.target.classList.add('active');
        if (tabViewName === 'early_bird') {
            this.showEarlyBird = true;
        } else if (tabViewName === 'highcharts') {
            this.showHighcharts = true;
        } else {
            this.showTableau = true;
            const viewIndex = this.tableauData.findIndex((view) => view.viewName === tabViewName);
            this.tableauData[viewIndex].selected = true;
            let selectedTab = document.getElementById(this.tableauData[viewIndex].viewName);
            if (selectedTab) selectedTab.style.display = 'block';
        }
    }

    getText = this.commonSvc.getText;
}
