import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@h20-services/common.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { timeStamp } from 'console';
import { BaseTileComponent } from '../base-tile/base-tile.component';

@Component({
    selector: 'app-counter-tile',
    templateUrl: './counter-tile.component.html',
    styleUrls: ['./counter-tile.component.scss'],
})
export class CounterTileComponent extends BaseTileComponent implements OnInit {
    @Input() tile;

    count;

    constructor(
        com_svc: CommonService,
        private translate: TranslateService
    ) {
        super(com_svc);
    }

    ngOnInit(): void {
        this.count = this.tile.data[0][Object.keys(this.tile.data[0])[0]];
    }

    getText = this.com_svc.getText;
}
