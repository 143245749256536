import { Injectable } from '@angular/core';
import { environment as env } from '@environment/environment';
import { Observable, throwError } from 'rxjs';
import { IRegistryConfiguration } from './models/registry_configuration/IRegistryConfiguration';
import { IRegistryLibrary } from './models/registry_configuration/IRegistryLibrary';
import { RequestService } from './request.service';
import { CommonService } from './common.service';
import { TranslateService } from '@ngx-translate/core';
import { PathTypes } from './enums/PathType';
import { PulseAuth } from './models/PulseAuth';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private static currentTenantItem: Promise<any>;

    private static langugeNames = [
        { code: 'en', label: 'English' },
        { code: 'fr', label: 'Français' },
        { code: 'es', label: 'Español' },
        { code: 'ar', label: 'العربية' },
        { code: 'hi', label: 'हिन्दी' },
        { code: 'de', label: 'Deutsch' },
        { code: 'it', label: 'Italiano' },
        { code: 'pt', label: 'Português' },
        { code: 'nl', label: 'Nederlands' },
        { code: 'el', label: 'ελληνικά' },
        { code: 'zh-CN', label: '简体中文' },
        { code: 'zh-TW', label: '繁體中文' },
        { code: 'ja', label: '日本語' },
        { code: 'ru', label: 'русский' },
    ];

    constructor(
        private reqSvc: RequestService,
        private commonSvc: CommonService,
        private translate: TranslateService
    ) {
        this.getTenantConfigs();
    }

    getTenantConfigs() {
        if (!ConfigService.currentTenantItem) {
            const dataToSend = {
                registry: this.getTenant(),
            };
            ConfigService.currentTenantItem = this.reqSvc
                .post(env.API.writeToDBInfo, dataToSend)
                .toPromise()
                .then((resp) => {
                    return resp.Item;
                })
                .catch((e) => {
                    return throwError(e);
                });
        }
        return ConfigService.currentTenantItem;
    }

    // Get registry_config data
    getConfigurations(registry: string): Promise<any> {
        const dataToSend = {
            registry: registry,
        };
        return this.reqSvc
            .post(env.API.writeToDBInfo, dataToSend)
            .toPromise()
            .then((resp) => {
                return resp.Item;
            })
            .catch((e) => {
                return throwError(e);
            });
    }

    getBranding(): Promise<any> {
        return this.getTenantConfigs()
            .then((resp: any) => {
                if (resp.branding_platform) {
                    let brandingConfig = JSON.parse(resp.branding_platform);
                    return Promise.resolve(this.replacePath(brandingConfig));
                } else {
                    console.error('error in config load');
                    return Promise.resolve({});
                }
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    getSurveyConfirmSubmit(): Promise<any> {
        return this.getTenantConfigs()
            .then((resp: any) => {
                if (resp.survey_confirmSubmit) {
                    let survey_confirmSubmit = JSON.parse(resp.survey_confirmSubmit);
                    return Promise.resolve(survey_confirmSubmit);
                } else {
                    //return default to not show the dialog
                    return Promise.resolve({ showDialog: false });
                }
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    getEffectiveLogo(siteID: string): Promise<string> {
        return this.getBranding().then((resp: any) => {
            const logoDefault = resp.tenantLogo['default'];
            if (logoDefault) return Promise.resolve(logoDefault);
            else return Promise.resolve(resp.tenantLogo);
        });
    }

    getLibraryConfig(registry: string, organization: string, site_ID: string, role: string): any {
        const dataToSend = {
            registry: registry,
            organization: organization ? organization : 'default',
            site_ID: site_ID,
            role: role,
            operation: 'getLibraryConfig',
        };

        return this.reqSvc.post(env.API.writeToDBInfo, dataToSend);
    }

    getLibraryConfig2(pulseAuth: PulseAuth): any {
        const dataToSend = {
            registry: this.getTenant(),
            organization: pulseAuth.getOrganizationID() || 'default',
            site_ID: pulseAuth.getSiteID(),
            role: pulseAuth.getType(),
            operation: 'getLibraryConfig',
        };

        return this.reqSvc.post(env.API.writeToDBInfo, dataToSend);
    }

    getLibrary(): Promise<any> {
        return this.getTenantConfigs()
            .then((resp: any) => {
                if (resp.library) {
                    let libraryConfig = JSON.parse(resp.library);
                    return Promise.resolve(this.replacePath(libraryConfig));
                } else {
                    console.error('error in config load');
                    return Promise.resolve({});
                }
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    // getTenantRegistrationConfig is usde with webapp,
    // use getRegistrationConfig in Composer

    getTenantRegistrationConfig(): Promise<any> {
        return this.getTenantConfigs()
            .then((resp: any) => {
                if (resp.registrationDetails) {
                    let regConfig = JSON.parse(resp.registrationDetails);
                    return Promise.resolve(regConfig);
                } else {
                    console.error('error in config load');
                    return Promise.resolve({});
                }
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    // getRegistrationConfig(tenant, cache) is usde with composer.
    // FIXME: should require tenant parameter and not have cache paramater.

    getRegistrationConfig(tenant = this.getTenant(), cache = true): Promise<any> {
        if (!tenant || cache) throw new Error('use getTenantRegistrationConfig() instead');

        return this.getConfigurations(tenant)
            .then((resp: any) => {
                if (resp.registrationDetails) {
                    let regConfig = JSON.parse(resp.registrationDetails);
                    // return Promise.resolve(regConfig);
                    return regConfig;
                } else {
                    console.error('error in config load');
                    // return Promise.resolve({});
                    return {};
                }
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    getTheme(): string {
        return this.getTenant() + '.css';
    }

    getEffectiveTheme(siteID: string): Promise<string> {
        return this.getBranding().then((resp: any) => {
            const themeDefault = resp.tenantTheme['default'];
            const themeSite = resp.tenantTheme[siteID];
            let result = resp.tenantTheme;
            if (themeDefault) {
                result = themeSite || themeDefault;
            }
            return Promise.resolve(result);
        });
    }

    getTenant(): string {
        return location.hostname.split('.')[0];
    }

    getLanguages(site_ID: any = null): Promise<any> {
        return this.getTenantConfigs()
            .then((resp: any) => {
                if (resp.languages) {
                    let langConfig = JSON.parse(resp.languages);
                    if (site_ID && langConfig[site_ID]) {
                        return Promise.resolve(langConfig[site_ID]);
                    } else {
                        return Promise.resolve(langConfig);
                    }
                } else {
                    console.error('error in language load');
                    return Promise.resolve(['en']);
                }
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    getProfileMenus(): Promise<any> {
        return this.getTenantConfigs()
            .then((resp: any) => {
                if (resp.profile_menus) {
                    let profileMenus = resp.profile_menus;
                    return Promise.resolve(profileMenus);
                } else {
                    console.error('error in config load');
                    return Promise.resolve([]);
                }
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    spellOutLangugeName(code: any): any {
        return ConfigService.langugeNames.find((element) => element.code === code)?.label;
    }

    getWithdrawSurvey(): Promise<any> {
        return this.getTenantConfigs()
            .then((resp: any) => {
                if (resp.withdraw_survey) {
                    let withdrawSurvey = JSON.parse(resp.withdraw_survey);
                    return Promise.resolve(withdrawSurvey);
                } else {
                    console.error('error in config load');
                    return Promise.resolve({});
                }
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    getLayoutConfig(pulseAuth: PulseAuth): any {
        const dataToSend = {
            registry: this.getTenant(),
            organization: pulseAuth.getOrganizationID() || 'default',
            site_ID: pulseAuth.getSiteID(),
            role: pulseAuth.getType(),
            operation: 'getLayoutConfig',
        };

        return this.reqSvc.post(env.API.writeToDBInfo, dataToSend);
    }

    getLayoutConfig_XX(registry: string, organization: string, site_ID: string, role: string): any {
        const dataToSend = {
            registry: registry,
            organization: organization ? organization : 'default',
            site_ID: site_ID,
            role: role,
            operation: 'getLayoutConfig',
        };

        return this.reqSvc.post(env.API.writeToDBInfo, dataToSend);
    }

    evaluateSubmissionTriggers(
        survey_ID: string,
        user_ID: string,
        task_ID: string,
        data: any,
        submission_type: string = 'survey'
    ): Observable<any> {
        const req: any = {
            registry_key: this.getTenant(),
            id: survey_ID,
            user_ID: user_ID,
            task_ID: task_ID,
            url: location.hostname,
            data: JSON.stringify(data),
            submission_type: submission_type,
        };
        return this.reqSvc.post(env.API.submissionTriggers, req);
    }

    createRegistryConfig(registry_config: IRegistryConfiguration): Observable<any> {
        const req: any = {
            data: {
                registry_config: registry_config,
            },
            operation: 'create',
        };
        return this.reqSvc.post(env.API.registryConfiguration, req);
    }

    listRegistryConfig(envName?: any): Observable<IRegistryConfiguration[]> {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        return this.reqSvc.post(
            targetEnv.API.registryConfiguration,
            {
                operation: 'list',
            },
            targetEnv
        );
    }

    updateRegistryConfig(
        config: IRegistryConfiguration,
        fieldToUpdate: string,
        value
    ): Observable<any> {
        const req = {
            operation: 'update',
            data: {
                registry_config: {
                    ...config,
                    attributeName: fieldToUpdate,
                    attributeValue: value,
                },
            },
        };
        return this.reqSvc.post(env.API.registryConfiguration, req);
    }

    deleteRegistryConfig(registry_config: IRegistryConfiguration): Observable<any> {
        const req: any = {
            data: {
                registry_config: registry_config,
            },
            operation: 'delete',
        };
        return this.reqSvc.post(env.API.registryConfiguration, req);
    }

    updateWebAssets(config): Observable<any> {
        const req = {
            operation: 'updateWebasset',
            data: {
                registry_config: config,
            },
        };
        return this.reqSvc.post(env.API.registryConfiguration, req);
    }

    updateWebAssets_Deploy(config, site): Observable<any> {
        const req = {
            operation: 'updateWebasset',
            data: {
                registry_config: config,
            },
        };
        return this.reqSvc.post(site.API.registryConfiguration, req);
    }

    trackPageViews(pulseAuth: PulseAuth, page: any, menu: any, item: any, uuid: any) {
        const dataToSend = {
            uuid: uuid,
            page: page,
            menu: menu,
            item: item,
            user_ID: pulseAuth.getUserID(),
            registry: this.getTenant(),
            site_ID: pulseAuth.getSiteID(),
            operation: 'trackForAnalytics',
        };
        return this.reqSvc.post(env.API.writeToDBInfo, dataToSend);
    }

    trackDropoffSurvey(uuid: any, registry: any, question_key: any, survey_ID: any): any {
        const dataToSend = {
            uuid: uuid,
            registry: registry,
            question_key: question_key,
            survey_ID: survey_ID,
            operation: 'trackDropoffSurvey',
        };
        return this.reqSvc.post(env.API.writeToDBInfo, dataToSend);
    }

    // patchPath(src, isRevMode)
    // return a potentially new value by replacing any replacement tokens
    // (defined in PathTypes) with environment-specific value.

    private patchPath(src: string, isRevMode: boolean): string {
        let s = src;
        for (let repToken of PathTypes) {
            // we only  expect ONE replacement token match,
            // but iterare over all possibilities anyway.
            s = isRevMode
                ? s.replace(env.awsConfig.Path[repToken], repToken)
                : s.replace(repToken, env.awsConfig.Path[repToken]);
        }
        return s;
    }

    /**
     * Replace from the name of path in config to the right path in each environment
     * It covers string, object, object with language key, array type in config
     * @param config The json defition of the config loaded as an object
     * @param isRevMode The flag to decide on the direction to replace path
     * @return config itself from changing the name of path to the right path
     * NB: Source object is modifed!
     */

    replacePath(config: any, isRevMode: boolean = false): any {
        const lang = this.translate.currentLang;
        for (let key in config) {
            let item = config[key];
            if (typeof item === 'string') {
                config[key] = this.patchPath(item, isRevMode);
            } else if (typeof item === 'object' && !Array.isArray(item)) {
                if (item.hasOwnProperty(lang)) {
                    item[lang] = this.patchPath(item[lang], isRevMode);
                } else {
                    for (let subkey in config[key]) {
                        const subobj = item[subkey];
                        if (typeof subobj === 'string') {
                            item[subkey] = this.patchPath(subobj, isRevMode);
                        } else if (typeof subobj === 'object' && subobj.hasOwnProperty(lang)) {
                            item[subkey][lang] = this.patchPath(subobj[lang], isRevMode);
                        }
                    }
                }
            } else if (Array.isArray(config[key])) {
                for (let arrObj of config[key]) {
                    arrObj = this.replacePath(arrObj, isRevMode);
                }
            } else {
                // Add a different type if added
                console.error('invalid path type');
            }
        }
        return config;
    }

    listLibraryConfig(): Observable<IRegistryLibrary[]> {
        return this.reqSvc.post(env.API.layoutConfiguration, {
            operation: 'listLibraryConfig',
        });
    }

    createLibraryConfig(
        registry_ID: string,
        organization: string,
        site_ID: string,
        role: string,
        config: IRegistryLibrary
    ): Observable<any> {
        const req: any = {
            data: {
                registry_ID: registry_ID,
                organization: organization,
                site_ID: site_ID,
                role: role,
                library_config: config,
            },
            operation: 'createLibraryConfig',
        };
        return this.reqSvc.post(env.API.layoutConfiguration, req);
    }

    updateLibraryConfig(
        registry_ID: string,
        organization: string,
        site_ID: string,
        role: string,
        config: IRegistryLibrary,
        fieldToUpdate: string,
        value
    ): Observable<any> {
        const req = {
            operation: 'updateLibraryConfig',
            data: {
                registry_ID: registry_ID,
                organization: organization,
                site_ID: site_ID,
                role: role,
                library_config: {
                    ...config,
                    attributeName: fieldToUpdate,
                    attributeValue: value,
                },
            },
        };
        return this.reqSvc.post(env.API.layoutConfiguration, req);
    }

    deleteLibraryConfig(
        registry_ID: string,
        organization: string,
        site_ID: string,
        role: string
    ): Observable<any> {
        const req: any = {
            data: {
                registry_ID: registry_ID,
                organization: organization,
                site_ID: site_ID,
                role: role,
            },
            operation: 'deleteLibraryConfig',
        };
        return this.reqSvc.post(env.API.layoutConfiguration, req);
    }
}
