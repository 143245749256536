<div class="row mb-3 wrapper">
    <div class="left-column">
        <h4 class="desc-heading">
            {{ 'UserSettings.LanguagePreferences' | translate }}
        </h4>
        <div class="desc-sub-heading" [innerHTML]="languageSubHeading"></div>
    </div>
    <div class="right-column">
        <form>
            <div class="container">
                <label for="preferredlanguage" class="input-heading">{{
                    'UserSettings.SelectLanguage' | translate
                }}</label>
                <select
                    attr.aria-label="{{ 'Registry.ChangeLang' | translate }}"
                    class="form"
                    id="dropdown"
                    #langselect
                    (change)="onLanguageSelected(langselect.value)"
                    [(ngModel)]="selectedLanguage"
                    name="preferredLanguage"
                >
                    <option *ngFor="let lang of getLanguages()" [value]="lang">
                        {{ spellOutLangugeName(lang) }}
                    </option>
                </select>
            </div>
            <app-button
                class="ms-1"
                [type]="'submit'"
                (action)="handleLanguageClick()"
                [buttonText]="'UserSettings.SaveLanguageSettings'"
            />
        </form>
    </div>
</div>
