import { Component, Input, EventEmitter, Output } from '@angular/core';

//ensure each instance of this component has its own unique html ids
let uniqueIdCounter = 0;

@Component({
    selector: 'app-horizontal-tab',
    templateUrl: './horizontal-tab.component.html',
    styleUrls: ['./horizontal-tab.component.scss'],
})
export class HorizontalTabComponent {
    /**
     * Expects objects with the following properties:
     * * tabId: the ID of the tab. this will be fired from the tabSelected event later. Must be unique within a tab set.
     * * tabDisplay: the display 'title' for the tab. Should be a translation key.
     */
    @Input({ required: true }) tabs: { tabId: string; tabDisplay: string }[];
    /**
     * Use this to set the initial tab (if non-zero), or to change the tab.
     * * Should match a tabId from the tab definitions.
     * * Avoid changing the tab unless the user has requested it.
     * * You will need to handle changing the item this controls yourself.
     */
    @Input()
    get selectedTab(): string {
        return this.tabs[this.selectedIndex].tabId;
    }
    set selectedTab(tabId: string) {
        const idx = this.tabs.findIndex((i) => i.tabId == tabId);
        if (idx !== -1) {
            this.selectedIndex = idx;
        } else {
            console.error("HorizontalTabComponent - tried to set tab to tab that doesn't exist.");
        }
    }
    /**
     * Fires an event when a new tab is selected by the user.
     * Does not fire an event when selectedTab is changed through Angular.
     * Value returned will be the selected tab's tabId string.
     * You should NOT change the user's focus when receiving this event.
     */
    @Output() tabSelected = new EventEmitter<string>();

    selectedIndex: number = 0;

    elementIdPrefix = `horizontal-tab-${uniqueIdCounter++}`;

    handleTabEvent(event: Event, tabName: string, index: number) {
        const keyboardEvent = event as KeyboardEvent;
        if (event.type === 'click' || ['Enter', ' '].includes(keyboardEvent.key)) {
            this.selectTab(tabName, index);
            event.preventDefault();
        } else if (
            keyboardEvent.key === 'ArrowRight' ||
            keyboardEvent.key === 'ArrowLeft' ||
            keyboardEvent.key === 'Home'
        ) {
            this.navigateTabs(keyboardEvent.key);
        }
    }

    selectTab(tabName: string, index: number) {
        this.selectedIndex = index;
        this.tabSelected.emit(tabName);
        document.getElementById(`${this.elementIdPrefix}-${index}`)?.focus();
    }

    navigateTabs(key: string) {
        const lastIndex = this.tabs.length - 1;
        switch (key) {
            case 'ArrowRight':
                this.selectedIndex = this.selectedIndex === lastIndex ? 0 : this.selectedIndex + 1;
                break;
            case 'ArrowLeft':
                this.selectedIndex = this.selectedIndex === 0 ? lastIndex : this.selectedIndex - 1;
                break;
            case 'Home':
                this.selectedIndex = 0;
                break;
        }
        this.selectTab(this.tabs[this.selectedIndex].tabId, this.selectedIndex);
    }
}
