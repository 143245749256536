import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '@h20-services/config.service';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { AuthService } from '@h20-services/auth.service';
import { ToastService } from '@h20-shared/services/toast.service';
import { UserService } from '@h20-services/user.service';
import { fetchAuthSession } from '@aws-amplify/auth';

@Component({
    selector: 'app-language-setting',
    templateUrl: './language-setting.component.html',
    styleUrls: ['./language-setting.component.scss'],
})
export class LanguageSettingComponent {
    pulseAuth: PulseAuth = PulseAuth.NotLoggedIn;
    selectedLanguage;
    currentLanguage = this.configService.spellOutLangugeName(this.translate.currentLang);
    languageSubHeading = '';
    showLanguagesSection;
    languages: any = ['en'];

    constructor(
        public translate: TranslateService,
        private readonly configService: ConfigService,
        private readonly auth: AuthService,
        private readonly userSvc: UserService,
        private readonly toastService: ToastService
    ) {}

    ngOnInit(): void {
        this.auth.getPulseAuth().then((pulseAuth: PulseAuth) => {
            this.selectedLanguage = pulseAuth.getPreferredLang();
            this.translate.use(this.selectedLanguage);
            this.setSubHeading();
            this.configService.getLanguages().then((resp: any) => {
                this.languages = resp;
                this.showLanguagesSection = resp && this.languages.length > 1;
            });
        });
        this.setSubHeading();
    }

    translateTitle(): void {
        let currentLang = this.selectedLanguage;
        this.translate.setDefaultLang(currentLang);
        this.translate.get(['PageTitle']).subscribe((translations) => {
            let url = window.location.href.split('/');
            document.title = translations.PageTitle[url[3]];
        });
    }

    getLanguages(): any {
        return this.translate.getLangs().filter((item) => this.languages.includes(item));
    }

    spellOutLangugeName(code: any): any {
        return this.configService.spellOutLangugeName(code);
    }

    onLanguageSelected(newLang: string): void {
        this.selectedLanguage = newLang;
    }

    handleLanguageClick(): void {
        if (this.selectedLanguage === this.pulseAuth.getPreferredLang()) {
            this.toastService.show({
                type: 'error',
                body: this.translate.instant('UserSettings.NoLanguageChangeError'),
            });
            return;
        }
        this.auth.getPulseAuth().then((pulseAuth: PulseAuth) => {
            if (pulseAuth) {
                this.userSvc
                    .saveUserSettings(pulseAuth.getUserID(), {
                        lang: this.selectedLanguage,
                    })
                    .toPromise()
                    .then(async () => {
                        await fetchAuthSession({ forceRefresh: true });

                        this.translate.use(this.selectedLanguage);
                        this.translateTitle();

                        this.toastService.show({
                            type: 'success',
                            body: this.translate.instant('Toast.SettingsSuccessBody'),
                        });
                        this.setSubHeading();
                    })
                    .catch((error) => {
                        this.toastService.show({
                            type: 'error',
                            body: this.translate.instant('Toast.SettingsErrorBody'),
                        });
                    });
            }
        });
    }

    setSubHeading(): void {
        const languageMessage = this.translate.instant('UserSettings.LanguageSelectedMessage');
        this.languageSubHeading = `
      ${languageMessage} 
      <strong>${this.spellOutLangugeName(this.selectedLanguage)}</strong> 
      
    `;
    }
    showToast(toast) {
        this.toastService.show({
            type: toast.type,
            body: this.translate.instant(toast.body),
        });
    }
}
