<div>
    <ng-content></ng-content>
    <i
        *ngIf="!max || (max && model[field].length < max)"
        class="far fa-regular fa-plus-circle text-end"
        (click)="addField()"
    ></i>
    <div *ngFor="let item of model[field]">
        <div class="row-cols-1 card-body border rounded my-1">
            <i class="far fa-minus-circle text-end" (click)="removeField(item)"></i>
            <ng-container
                [ngTemplateOutlet]="scheduledIterations"
                [ngTemplateOutletContext]="{
                    $implicit: item,
                }"
            ></ng-container>
        </div>
    </div>
</div>

<ng-template #scheduledIterations let-it>
    <app-scheduled-interval-component [model]="it"></app-scheduled-interval-component>
</ng-template>
