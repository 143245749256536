import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, shareReplay } from 'rxjs';

const configTypeNames = [
    'common',
    'checkbox',
    'comment',
    'datepicker',
    'dropdown',
    'gesture-map-input',
    'gesture',
    'html',
    'link-generator',
    'multipletext',
    'paneldynamic',
    'partial-date',
    'radiogroup',
    'rating',
    'text',
    'file',
    'phone',
    'layout',
    'validators',
    'logic',
    'element_others',
] as const;

export type DesignerConfigTypes = (typeof configTypeNames)[number];

@Injectable({
    providedIn: 'root',
})

/**
 * This service class allows us to deduplicate requests for these assets, ensuring that surveybuilder only loads them once per session.
 * This reduces unnecessary duplicate http requests substantially when opening large surveys.
 */
export class ElementDesignerConfigService {
    dataObservables: Partial<Record<DesignerConfigTypes, Observable<any>>> = {};
    fullConfigObservables: Record<string, Observable<any>> = {};

    FormConfigSurveyDefs = {
        common: 'assets/survey-builder/element-forms/element.json',
        checkbox: 'assets/survey-builder/element-forms/checkbox.json',
        comment: 'assets/survey-builder/element-forms/comment.json',
        datepicker: 'assets/survey-builder/element-forms/datepicker.json',
        dropdown: 'assets/survey-builder/element-forms/dropdown.json',
        'gesture-map-input': 'assets/survey-builder/element-forms/gesture-map.json',
        gesture: 'assets/survey-builder/element-forms/gesture.json',
        html: 'assets/survey-builder/element-forms/html.json',
        'link-generator': 'assets/survey-builder/element-forms/link-generator.json',
        multipletext: 'assets/survey-builder/element-forms/multipletext.json',
        paneldynamic: 'assets/survey-builder/element-forms/paneldynamic.json',
        'partial-date': 'assets/survey-builder/element-forms/partial-date.json',
        radiogroup: 'assets/survey-builder/element-forms/radiogroup.json',
        rating: 'assets/survey-builder/element-forms/rating.json',
        text: 'assets/survey-builder/element-forms/text.json',
        file: 'assets/survey-builder/element-forms/fileupload.json',
        phone: 'assets/survey-builder/element-forms/phonenumber.json',
        layout: 'assets/survey-builder/element-forms/layout.json',
        validators: 'assets/survey-builder/element-forms/validators.json',
        logic: 'assets/survey-builder/element-forms/logic.json',
        element_others: 'assets/survey-builder/element-forms/element_others.json',
    };

    constructor(private http: HttpClient) {}

    getConfig(type: DesignerConfigTypes): Observable<any> {
        if (this.dataObservables[type]) {
            return this.dataObservables[type];
        } else {
            this.dataObservables[type] = this.http
                .get<any>(this.FormConfigSurveyDefs[type])
                .pipe(shareReplay());
            return this.dataObservables[type];
        }
    }

    getFullConfig(type: string): Observable<any> {
        if (this.fullConfigObservables[type]) {
            return this.fullConfigObservables[type];
        }
        let multiObservable: Array<Observable<any>> = [];
        if (type == 'html') {
            multiObservable.push(this.getConfig('html'));
            multiObservable.push(this.getConfig('layout'));
        } else {
            multiObservable.push(this.getConfig('common'));
            if (this.hasCustomization(type)) {
                multiObservable.push(this.getConfig(type));
            }
            multiObservable.push(this.getConfig('layout'));
            multiObservable.push(this.getConfig('validators'));
            multiObservable.push(this.getConfig('logic'));
            multiObservable.push(this.getConfig('element_others'));
        }
        this.fullConfigObservables[type] = forkJoin(multiObservable).pipe(shareReplay());
        return this.fullConfigObservables[type];
    }

    hasCustomization(elementType: string): elementType is DesignerConfigTypes {
        const testedType = configTypeNames.find((valid) => valid === elementType);
        if (testedType) return true;
        return false;
    }
}
