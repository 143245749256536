import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';

export interface IPartialDate {
    year: number;
    month: number;
    day: number;
}

export class PartialDate implements IPartialDate {
    public static readonly EMPTY: number = 0;
    public static readonly UNKNOWN: number = -1;

    year: number = PartialDate.EMPTY;
    month: number = PartialDate.EMPTY;
    day: number = PartialDate.EMPTY;

    constructor() {
        this.year = PartialDate.EMPTY;
        this.month = PartialDate.EMPTY;
        this.day = PartialDate.EMPTY;
    }

    public setDateFromDate(date: IPartialDate) {
        this.year = date.year;
        this.month = date.month;
        this.day = date.day;
    }

    public setDateFromAny(obj: any) {
        if (obj) {
            if (obj.hasOwnProperty('year')) this.year = Number(obj['year']);
            if (obj.hasOwnProperty('month')) this.month = Number(obj['month']);
            if (obj.hasOwnProperty('day')) this.day = Number(obj['day']);
        }
    }

    public compare(comp: PartialDate): number {
        if (this.year < comp.year) return -1;
        if (this.year > comp.year) return 1;
        // year == comp.year
        if (this.month < comp.month) return -1;
        if (this.month > comp.month) return 1;
        //month == comp.month
        if (this.day < comp.day) return -1;
        if (this.day > comp.day) return 1;
        //day == comp.day
        return 0;
    }

    public isBefore(comp: PartialDate): boolean {
        return this.compare(comp) < 0;
    }

    public isAfter(comp: PartialDate): boolean {
        return this.compare(comp) > 0;
    }

    public setYear(year: number) {
        this.year = year;
    }
    public setMonth(month: number) {
        this.month = month;
    }
    public setDay(day: number) {
        this.day = day;
    }

    public clearYear() {
        this.year = PartialDate.EMPTY;
    }
    public clearMonth() {
        this.month = PartialDate.EMPTY;
    }
    public clearDay() {
        this.day = PartialDate.EMPTY;
    }

    public setYearUnknown() {
        this.year = PartialDate.UNKNOWN;
    }
    public setMonthUnknown() {
        this.month = PartialDate.UNKNOWN;
    }
    public setDayUnknown() {
        this.day = PartialDate.UNKNOWN;
    }

    public isYearUnknown(): boolean {
        return this.year === PartialDate.UNKNOWN;
    }
    public isMonthUnknown(): boolean {
        return this.month === PartialDate.UNKNOWN;
    }
    public isDayUnknown(): boolean {
        return this.day === PartialDate.UNKNOWN;
    }
    public hasYearValue(): boolean {
        return this.year !== PartialDate.EMPTY;
    }
    public hasMonthValue(): boolean {
        return this.month !== PartialDate.EMPTY;
    }
    public hasDayValue(): boolean {
        return this.day !== PartialDate.EMPTY;
    }

    public toString(): string {
        let year =
            this.year === PartialDate.EMPTY
                ? 'NA'
                : this.year === PartialDate.UNKNOWN
                  ? 'Unk'
                  : this.year;
        let month =
            this.month === PartialDate.EMPTY
                ? 'NA'
                : this.month === PartialDate.UNKNOWN
                  ? 'Unk'
                  : this.month.toString().padStart(2, '0');
        let day =
            this.day === PartialDate.EMPTY
                ? 'NA'
                : this.day === PartialDate.UNKNOWN
                  ? 'Unk'
                  : this.day.toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    // Return 'yyyy-mm-dd'
    public toStringWithZero(): string {
        let year =
            this.year === PartialDate.EMPTY || this.year === PartialDate.UNKNOWN || null
                ? '0000'
                : this.year;
        let month =
            this.month === PartialDate.EMPTY || this.month === PartialDate.UNKNOWN || null
                ? '0'
                : this.month;
        month = month.toString().length > 1 ? month : '0' + month;
        let day =
            this.day === PartialDate.EMPTY || this.day === PartialDate.UNKNOWN || null
                ? '0'
                : this.day;
        day = day.toString().length > 1 ? day : '0' + day;
        return '' + year + '-' + month + '-' + day;
    }
}
