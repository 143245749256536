import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrySiteComponent } from './registry-site/registry-site.component';
import { TaskPrototypeComponent } from './task-prototype/task-prototype.component';
import { TaskSetComponent } from './task-set/task-set.component';

const routes: Routes = [
    {
        path: '',
        component: RegistrySiteComponent,
    },
    {
        path: 'taskprototype',
        component: TaskPrototypeComponent,
    },
    {
        path: 'taskset',
        component: TaskSetComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RegistrySiteManagementRoutingModule {}
