import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OpUnitType } from '@h20-services/enums/dateTypes/OpUnitType';

@Component({
    selector: 'app-scheduled-interval-component',
    templateUrl: './scheduled-interval-component.component.html',
    styleUrls: ['./scheduled-interval-component.component.scss'],
})
export class ScheduledIntervalComponentComponent implements OnInit {
    @Input() model: any;
    @Output() modelChange = new EventEmitter();

    s_intervalType: string[] = Object.values(OpUnitType);

    constructor() {}

    ngOnInit(): void {
        return;
    }

    modelChanged(field: string, value: any) {
        this.model[field] = value;
        this.modelChange.emit(this.model);
    }
}
