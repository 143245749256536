<div class="bg-white p-3 ps-0">
    <div class="d-flex w-100">
        <div class="border-end w-100">
            <ul class="nav flex-column nav-pills nav-justified w-100">
                <li *ngFor="let panel of surveyPanels; let i = index" class="nav-item my-1 w-100">
                    <div class="accordion accordion-flush" [att.id]="'panelAccordion' + i">
                        <div class="accordion-item border-0">
                            <div class="accordion-header" id="panelAccordion-heading">
                                <button
                                    class="accordion-button collapsed border border-1 primary"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    [attr.data-bs-target]="'#panelAccordion-collapse' + i"
                                    aria-expanded="false"
                                    aria-controls="panelAccordion-collapse"
                                    (click)="expandedControl(i, panel)"
                                >
                                    <div>
                                        <div class="pe-3">
                                            #{{ i + 1 }} {{ getText(panel.title) }}
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div
                                id="{{ 'panelAccordion-collapse' + i }}"
                                class="accordion-collapse collapse"
                                aria-labelledby="panelAccordion-heading"
                                data-bs-parent="#panelAccordion"
                            >
                                <div class="accordion-body w-100 px-0">
                                    <div>
                                        <div
                                            class="d-flex justify-content-end mb-2 w-100"
                                            style="width: 100%"
                                        >
                                            <ul
                                                id="element-nav-list"
                                                style="padding-left: 0; width: 100%"
                                            >
                                                <li
                                                    *ngFor="
                                                        let element of panel.elements;
                                                        let ind = index
                                                    "
                                                    class="nav-item d-flex w-100 align-items-center border border-1"
                                                >
                                                    <a
                                                        class="nav-link d-flex align-items-center w-100 px-3 py-1"
                                                        [ngClass]="
                                                            ind === currentEditor &&
                                                            i === panelIndex
                                                                ? 'active'
                                                                : ''
                                                        "
                                                        id="{{ 'nav-' + element.name + '-tab' }}"
                                                        role="tab"
                                                        (click)="changeEditor(ind, i)"
                                                    >
                                                        <span class="fa-stack tiny-text mx-1">
                                                            <i
                                                                class="fa-solid fa-square fa-stack-2x"
                                                                [ngClass]="getColor(element)"
                                                            ></i>
                                                            <i
                                                                class="fa-solid fa-stack-1x fa-inverse"
                                                                [ngClass]="getIcon(element)"
                                                            ></i>
                                                        </span>
                                                        <div class="font-monospace me-3">
                                                            #{{ ind + 1 }}
                                                        </div>
                                                        <span
                                                            *ngIf="navExpanded"
                                                            class="text-wrap w-100 text-start font-size-13"
                                                        >
                                                            <div class="displayed-characters">
                                                                {{ getText(element.title) }}
                                                            </div></span
                                                        >
                                                    </a>
                                                </li>
                                                <button
                                                    class="btn btn-light btn-md"
                                                    (click)="addAfter(panel.elements.length, i)"
                                                >
                                                    Add New Question<i class="fas fa-plus"></i>
                                                </button>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <button class="btn btn-outline-primary" title="Add a panel" (click)="addPanel()">
                    <div class="fs-6 fas fa-regular fa-plus">Add Panel</div>
                </button>
            </ul>
        </div>
    </div>
</div>
<app-modal
    *ngIf="adding"
    [(visible)]="adding"
    header="Add Element"
    [context]="this"
    [closeFunc]="clearAdding"
>
    <h3>Search the library</h3>
    <app-fragment-search (addElementEvent)="addPrefab($event)"></app-fragment-search>
    <hr />
    <h3>Create a new question</h3>
    <div class="row">
        <div *ngFor="let element of ELEMENT_TYPES" class="col-3 my-1">
            <div
                class="p-4 w-100 shadow-sm border border-1 rounded d-flex align-items-center justify-content-start"
                (click)="confirmAdd(element.text)"
            >
                <div class="fa-stack fs-4">
                    <i class="fa-solid fa-square fa-stack-2x" [ngClass]="element.color"></i>
                    <i class="fa-solid fa-stack-1x fa-inverse" [ngClass]="element.icon"></i>
                </div>
                <div>{{ getText(element.displayText) }}</div>
            </div>
        </div>
    </div>
</app-modal>
