<app-tray [visible]="toggleFilters" [trayTitle]="trayTitle" (innerToggle)="innerToggle($event)">
    <div class="trayInsert">
        <app-button
            class="col-8 mt-2"
            (action)="buildFormFiltersFromData()"
            [buttonText]="'Filters.ClearFilters'"
        />
        <form autocomplete="off" *ngIf="filterForm" [formGroup]="filterForm">
            <div
                class="state-filter-group row mt-2"
                formGroupName="matched_state"
                *ngIf="filterOption && filterOption.hasOwnProperty('matched_state')"
            >
                <label class="col-4">{{ 'Filters.MatchState' | translate }}</label>
                <label class="unmatched col-1 fa fa-circle filter-lbl">
                    <input
                        type="checkbox"
                        class="col-1 mt-1 filter-chks"
                        aria-label="{{ 'Filters.Unmatched' | translate }}"
                        title="{{ 'Filters.Unmatched' | translate }}"
                        formControlName="unmatched"
                    />
                </label>

                <label class="failed col-1 fa fa-circle filter-lbl">
                    <input
                        type="checkbox"
                        class="col-1 mt-1 filter-chks"
                        aria-label="{{ 'Filters.Failed' | translate }}"
                        title="{{ 'Filters.Failed' | translate }}"
                        formControlName="failed"
                    />
                </label>

                <label class="pending col-1 fa fa-circle filter-lbl">
                    <input
                        type="checkbox"
                        class="col-1 mt-1 filter-chks"
                        aria-label="{{ 'Filters.Pending' | translate }}"
                        title="{{ 'Filters.Pending' | translate }}"
                        formControlName="pending"
                    />
                </label>

                <label class="matched col-1 fa fa-circle filter-lbl">
                    <input
                        type="checkbox"
                        class="col-1 mt-1 filter-chks"
                        aria-label="{{ 'Filters.Matched' | translate }}"
                        title="{{ 'Filters.Matched' | translate }}"
                        formControlName="matched"
                    />
                </label>
            </div>
            <div
                class="row mt-2"
                *ngIf="filterOption && filterOption.hasOwnProperty('exclude_from_reporting')"
            >
                <div class="col">
                    <label class="exclude_from_reporting filter-lbl d-flex align-items-center">
                        <span class="me-3">{{ 'Filters.IncludeTesters' | translate }}</span>
                        <input
                            type="checkbox"
                            class="filter-chks m-3"
                            aria-label="{{ 'Filters.IncludeTesters' | translate }}"
                            title="{{ 'Filters.IncludeTesters' | translate }}"
                            formControlName="exclude_from_reporting"
                        />
                    </label>
                </div>
            </div>
            <div
                class="rg-filter-group row mt-2"
                formGroupName="status"
                *ngIf="filterOption && filterOption.hasOwnProperty('status')"
            >
                <!-- <i class="fa fa-times-circle col-1"></i> -->
                <label class="col-4">{{ 'Filters.RegistryStatus' | translate }}</label>
                <label class="new col-1 fa fa-square filter-lbl">
                    <input
                        type="checkbox"
                        class="col-1 mt-1 filter-chks"
                        aria-label="{{ 'Filters.New' | translate }}"
                        title="{{ 'Filters.New' | translate }}"
                        formControlName="new"
                    />
                </label>

                <label class="confirmingEligibility col-1 fa fa-square filter-lbl">
                    <input
                        type="checkbox"
                        class="col-1 mt-1 filter-chks"
                        aria-label="{{ 'Filters.ConfirmingEligibility' | translate }}"
                        title="{{ 'Filters.ConfirmingEligibility' | translate }}"
                        formControlName="confirming-eligibility"
                    />
                </label>

                <label class="consent col-1 fa fa-square filter-lbl">
                    <input
                        type="checkbox"
                        class="col-1 mt-1 filter-chks"
                        aria-label="{{ 'Filters.Consent' | translate }}"
                        title="{{ 'Filters.Consent' | translate }}"
                        formControlName="consent"
                    />
                </label>

                <label class="active col-1 fa fa-square filter-lbl">
                    <input
                        type="checkbox"
                        class="col-1 mt-1 filter-chks"
                        aria-label="{{ 'Filters.Active' | translate }}"
                        title="{{ 'Filters.Active' | translate }}"
                        formControlName="active"
                    />
                </label>

                <label class="withdrawn col-1 fa fa-square filter-lbl">
                    <input
                        type="checkbox"
                        class="col-1 mt-1 filter-chks"
                        aria-label="{{ 'Filters.Withdrawn' | translate }}"
                        title="{{ 'Filters.Withdrawn' | translate }}"
                        formControlName="withdrawn"
                    />
                </label>
            </div>
            <div class="row mt-2" *ngIf="filterOption && filterOption.hasOwnProperty('name')">
                <div class="col">
                    <label class="w-100">
                        {{ 'Filters.Name' | translate }}
                        <input
                            type="text"
                            formControlName="name"
                            class="form-control"
                            placeholder="Begin typing to filter by patient name"
                            maxlength="121"
                        />
                    </label>
                </div>
            </div>
            <div class="row mt-2" *ngIf="filterOption && filterOption.hasOwnProperty('proxyName')">
                <div class="col">
                    <label class="w-100">
                        Proxy Name
                        <input
                            type="text"
                            formControlName="proxyName"
                            class="form-control"
                            placeholder="Begin typing to filter by proxy name"
                            maxlength="121"
                        />
                    </label>
                </div>
            </div>
            <div class="row mt-2" *ngIf="filterOption && filterOption.hasOwnProperty('email')">
                <div class="col">
                    <label class="w-100">
                        {{ 'Filters.Email' | translate }}
                        <input
                            type="text"
                            formControlName="email"
                            class="form-control"
                            placeholder="Begin typing to filter by email"
                            maxlength="64"
                        />
                    </label>
                </div>
            </div>
            <div class="row mt-2" *ngIf="filterOption && filterOption.hasOwnProperty('type')">
                <div class="col">
                    <label class="w-100">
                        {{ 'Filters.Role' | translate }}
                        <input
                            type="text"
                            formControlName="type"
                            class="form-control"
                            placeholder="Begin typing to filter by role"
                            maxlength="36"
                        />
                    </label>
                </div>
            </div>
            <div
                class="row mt-2"
                *ngIf="filterOption && filterOption.hasOwnProperty('date_of_birth')"
            >
                <div class="col">
                    <label class="w-100">
                        {{ 'Filters.DateOfBirth' | translate }}
                        <input type="date" id="dob" formControlName="dob" class="form-control" />
                    </label>
                </div>
            </div>
            <div *ngIf="filterOption && filterOption.hasOwnProperty('created')" class="row mt-2">
                <div class="col">
                    <label class="w-100">
                        {{ 'Filters.Created' | translate }}
                        <input
                            id="createddate"
                            type="date"
                            formControlName="created"
                            class="form-control"
                        />
                    </label>
                </div>
            </div>
            <div *ngIf="filterOption && filterOption.hasOwnProperty('modified')" class="row mt-2">
                <div class="col">
                    <label class="w-100">
                        {{ 'Filters.UpdatedDate' | translate }}
                        <input
                            id="updateddate"
                            type="date"
                            formControlName="modified"
                            class="form-control"
                        />
                    </label>
                </div>
            </div>
            <div
                class="row mt-2"
                *ngIf="filterOption && filterOption.hasOwnProperty('external_identifier')"
            >
                <div class="col">
                    <label class="w-100">
                        {{ 'Filters.External_ID' | translate }}
                        <input
                            id="externalid"
                            type="text"
                            formControlName="externalID"
                            class="form-control"
                            maxlength="36"
                        />
                    </label>
                </div>
            </div>
            <div class="row mt-2" *ngIf="filterOption && filterOption.hasOwnProperty('id')">
                <div class="col">
                    <label class="w-100">
                        {{ 'Filters.Internal_ID' | translate }}
                        <input
                            id="internalid"
                            type="text"
                            formControlName="id"
                            class="w-100 form-control"
                            maxlength="36"
                    /></label>
                </div>
            </div>
            <div class="row mt-2" *ngIf="filterOption && filterOption.hasOwnProperty('minAge')">
                <div class="col">
                    <label class="w-100">
                        {{ 'Filters.MinimumAge' | translate }}
                        <input
                            id="min_age"
                            type="number"
                            formControlName="minAge"
                            class="w-100 form-control"
                    /></label>
                </div>
            </div>
            <div class="row mt-2" *ngIf="filterOption && filterOption.hasOwnProperty('maxAge')">
                <div class="col">
                    <label class="w-100">
                        {{ 'Filters.MaximumAge' | translate }}
                        <input
                            id="max_age"
                            type="number"
                            formControlName="maxAge"
                            class="w-100 form-control"
                    /></label>
                </div>
            </div>
            <div
                class="row mt-2"
                *ngIf="
                    filterOption &&
                    filterOption.hasOwnProperty('site_ID') &&
                    !['site-admin', 'clinical'].includes(pulseAuth.getType())
                "
            >
                <div class="col">
                    <label class="w-100">
                        {{ 'Filters.Site' | translate }}
                        <input
                            id="site_ID"
                            type="text"
                            formControlName="site_ID"
                            class="w-100 form-control"
                            maxlength="64"
                    /></label>
                </div>
            </div>
        </form>
    </div>
</app-tray>
