import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '@h20-shared/shared.module';
import { UserRoutingModule } from './user-management-routing.module';
import { UsersComponent } from './users/users.component';
import { DeletePatientsComponent } from './delete-patients/delete-patients.component';
import { AttributeDefComponent } from './attribute-def/attribute-def.component';

@NgModule({
    declarations: [UsersComponent, DeletePatientsComponent, AttributeDefComponent],
    imports: [
        CommonModule,
        UserRoutingModule,
        NgxDatatableModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
    ],
})
export class UserModule {}
