<div class="row mt-2 card w-100" [class.active]="active">
    <div class="card-body w-100" [class.disabled]="disabled">
        <div class="row justify-content-around">
            <div class="icon-style col-2 col-md-3 col-lg-2 mb-3 mb-sm-0">
                <app-featured-icon
                    class=""
                    [iconName]="iconClass"
                    [size]="iconSize"
                    [type]="type"
                    [rounded]="true"
                ></app-featured-icon>
            </div>
            <div class="col-10 col-sm-6 col-md-9 col-lg-10 text-left">
                <h6 *ngIf="isHeading" class="headingStyle">
                    <strong>{{ heading }}</strong>
                </h6>
                <div *ngIf="isSubHeading" class="subHeadingStyle" [innerHTML]="subHeading"></div>
                <div *ngIf="content; else contentFromParent" class="contentStyle">
                    {{ content }}
                </div>
                <ng-template #contentFromParent>
                    <ng-content></ng-content>
                </ng-template>
            </div>
        </div>
    </div>
</div>
