import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { CommonService } from '@h20-services/common.service';

@Component({
    selector: 'app-element-layout-designer',
    templateUrl: './element-layout-designer.component.html',
    styleUrls: ['./element-layout-designer.component.scss'],
})
export class ElementLayoutDesignerComponent implements OnInit, OnChanges {
    constructor(private common: CommonService) {}
    @Input() elements: any[] = [];

    @Output() elementsChange = new EventEmitter<any[]>();
    navExpanded: boolean = true;

    //adding process has started ( some + arrow button clicked)
    adding: boolean = false;
    //adding before or after (dep on + arrow button clicked)
    addingBefore: boolean = false;
    //index of the add
    addingAt: number = 0;
    addingForm: any;

    currentEditor: number = 0;

    // for the add element modal
    ELEMENT_TYPES = [
        {
            icon: 'fa-circle-dot',
            color: 'color-1',
            text: 'radiogroup',
            displayText: 'Radiogroup',
        },
        { icon: 'fa-check', color: 'color-1', text: 'checkbox', displayText: 'Checkbox' },
        { icon: 'fa-text', color: 'color-2', text: 'text', displayText: 'Text' },
        { icon: 'fa-9', color: 'color-2', text: 'number', displayText: 'Number' },
        { icon: 'fa-phone', color: 'color-2', text: 'phone', displayText: 'Phone' },

        {
            icon: 'fa-calendar-range',
            color: 'color-2',
            text: 'partial-date',
            displayText: 'Partial Date',
        },
        { icon: 'fa-calendar-day', color: 'color-2', text: 'date', displayText: 'Date' },
        {
            icon: 'fa-calendar-days',
            color: 'color-2',
            text: 'datepicker',
            displayText: 'Datepicker',
        },
        { icon: 'fa-timer', color: 'color-2', text: 'time', displayText: 'Time' },

        {
            icon: 'fa-chevron-down',
            color: 'color-1',
            text: 'dropdown',
            displayText: 'Dropdown',
        },
        { icon: 'fa-at', color: 'color-2', text: 'email', displayText: 'Email' },

        { icon: 'fa-comment', color: 'color-2', text: 'comment', displayText: 'Comment' },

        { icon: 'fa-code', color: 'color-5', text: 'html', displayText: 'HTML' },

        {
            icon: 'fa-ellipsis-stroke',
            color: 'color-2',
            text: 'multipletext',
            displayText: 'Multiple Text',
        },
        {
            icon: 'fa-map',
            color: 'color-3',
            text: 'gesture-map-input',
            displayText: 'Gesture Map',
        },
        { icon: 'fa-map-pin', color: 'color-3', text: 'gesture', displayText: 'Gesture' },
        {
            icon: 'fa-arrow-up-from-bracket',
            color: 'color-3',
            text: 'file',
            displayText: 'File Upload',
        },

        {
            icon: 'fa-link',
            color: 'color-5',
            text: 'link-generator',
            displayText: 'Survey Link Generator',
        },
        { icon: 'fa-star', color: 'color-1', text: 'rating', displayText: 'Rating' },
        { icon: 'fa-slider', color: 'color-2', text: 'slider', displayText: 'Slider' },
        {
            icon: 'fa-square-list',
            color: 'color-4',
            text: 'paneldynamic',
            displayText: 'Dynamic Panel',
        },
    ];

    ngOnInit(): void {
        this.addingForm;
    }

    changeEditor(newIndex: number) {
        if (this.elements.length > newIndex) {
            this.currentEditor = newIndex;
        } else {
            console.warn('Tried to edit a nonexistent question with index: ' + newIndex + '.');
        }
    }

    ngOnChanges(): void {
        if (!this.elements) this.elements = [];
    }

    moveUp(index) {
        if (index === 0) return;
        let temp = this.elements[index - 1];
        this.elements[index - 1] = this.elements[index];
        this.elements[index] = temp;
    }

    moveDown(index) {
        if (index + 1 == this.elements.length) return;
        let temp = this.elements[index + 1];
        this.elements[index + 1] = this.elements[index];
        this.elements[index] = temp;
    }

    deleteAt(index) {
        this.elements.splice(index, 1);
    }

    addBefore(index) {
        this.adding = true;
        this.addingAt = index;
        this.addingBefore = true;
    }

    addAfter(index) {
        this.adding = true;
        this.addingAt = index;
        this.addingBefore = false;
    }

    /**
     * Adds one or more questions to the survey
     * @param prefabElements one or more question objects
     */
    addPrefab(prefabElements: any[]) {
        let addIndex = this.addingBefore ? this.addingAt : this.addingAt + 1;
        this.elements.splice(addIndex, 0, ...prefabElements);
        this.clearAdding();
    }

    confirmAdd(type: string) {
        let newElement = { type: type };
        if (
            type === 'number' ||
            type === 'date' ||
            type === 'email' ||
            type === 'slider' ||
            type === 'time'
        ) {
            newElement.type = 'text';
            newElement['inputType'] = type;
        } else if (type === 'paneldynamic') {
            newElement['templateElements'] = [];
        } else if (type === 'checkbox') {
            newElement['choices'] = [];
        }

        let addIndex = this.addingBefore ? this.addingAt : this.addingAt + 1;

        this.elements.splice(addIndex, 0, newElement);
        this.clearAdding();
    }

    clearAdding() {
        this.adding = false;
        this.addingAt = 0;
        this.addingBefore = false;
        return true;
    }

    getColor(index) {
        let type = this.elements[index].inputType
            ? this.elements[index].inputType
            : this.elements[index].type;
        return this.ELEMENT_TYPES.find((element) => {
            return element.text === type;
        }).color;
    }
    getIcon(index) {
        let type = this.elements[index].inputType
            ? this.elements[index].inputType
            : this.elements[index].type;
        return this.ELEMENT_TYPES.find((element) => {
            return element.text === type;
        }).icon;
    }

    getTypeText(element) {
        if (element.inputType) return element.inputType;
        return element.type;
    }

    update(element, at: number) {
        this.elements[at] = element;
    }
    toggleNav() {
        this.navExpanded = !this.navExpanded;
    }

    getText = this.common.getText;
}
