export class utils {
    public static logMsg(m: string) {
        // eslint-disable-next-line no-console
        console.log(m);
    }

    public static stdFormatDateFunction(d: Date): string {
        return d.toISOString().slice(0, 19).replace('T', ' ');
    }

    public static strNow(): string {
        return utils.stdFormatDateFunction(new Date(Date.now()));
    }

    // Intended to produce a string in form of "2022-04-24" from
    // a formatted state time string where time components (hh:mm:ss TZ)
    // might be 0 and should be ignored.
    // Specifically used for consent_date string but should be generalizable.

    public static dateStrFromDateTimeStr(s: string): string {
        if (s) return s.slice(0, 10);
        return '';
    }

    // replacement for underscore _.pick()
    public static pick(src, keys) {
        return keys.reduce((obj, key) => {
            if (src && src.hasOwnProperty(key)) {
                obj[key] = src[key];
            }
            return obj;
        }, {});
    }
}
