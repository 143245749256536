import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment as env } from '@environment/environment';
import { environment as development } from '@environment/environment.development';
import { environment as devops } from '@environment/environment.devops';
import { environment as preprod } from '@environment/environment.preprod';
import { environment as production } from '@environment/environment.production';
import { Observable, Subject } from 'rxjs';

export enum EnvType {
    Development = 'development',
    Devops = 'devops',
    Preprod = 'preprod',
    Production = 'production',
}
@Injectable({
    providedIn: 'root',
})
export class CommonService {
    private subject = new Subject<any>();
    constructor(public translate: TranslateService) {}

    public getText(strOrLangs): string {
        if (!strOrLangs) return '';
        return (
            strOrLangs[this.translate?.currentLang] ||
            strOrLangs['default'] ||
            strOrLangs['en'] ||
            strOrLangs
        );
    }

    public static ENVS = [EnvType.Development, EnvType.Devops, EnvType.Preprod, EnvType.Production];

    listSourceEnvs(): string[] {
        if (env.development) {
            return [EnvType.Development, EnvType.Devops];
        }
        return CommonService.ENVS.filter((env) => env !== EnvType.Development);
    }

    listTargetEnvs(): string[] {
        if (env.development) {
            return [EnvType.Development, EnvType.Devops];
        }
        return CommonService.ENVS.filter((env) => env !== EnvType.Development);
    }

    getTargetEnv(envName): any {
        switch (envName) {
            case EnvType.Development:
                return development;
            case EnvType.Devops:
                return devops;
            case EnvType.Preprod:
                return preprod;
            case EnvType.Production:
                return production;
            default:
                return env;
        }
    }

    compareByOpText(opText, first, second): boolean {
        let flag = false;
        switch (opText) {
            case 'lessThan':
                flag = first < second;
                break;
            case 'greaterThan':
                flag = first > second;
                break;
            case 'equalTo':
                flag = first == second;
                break;
            case 'lessThanOrEqualTo':
                flag = first <= second;
                break;
            case 'greaterThanOrEqualTo':
                flag = first >= second;
                break;
            case 'notEqualTo':
                flag = first != second;
                break;
        }
        return flag;
    }

    translateCompareOpText(op): string {
        let opText = '';
        const displayStrs = this.translate.translations[this.translate.currentLang];
        switch (op) {
            case 'lessThan':
                opText = displayStrs.Surveys.LessThan;
                break;
            case 'greaterThan':
                opText = displayStrs.Surveys.GreaterThan;
                break;
            case 'equalTo':
                opText = displayStrs.Surveys.EqualTo;
                break;
            case 'lessThanOrEqualTo':
                opText = displayStrs.Surveys.LessThanOrEqualTo;
                break;
            case 'greaterThanOrEqualTo':
                opText = displayStrs.Surveys.GreaterThanOrEqualTo;
                break;
            case 'notEqualTo':
                opText = displayStrs.Surveys.NotEqualTo;
                break;
        }
        return opText;
    }

    sendPanelMessage(panelType: string, show: boolean) {
        this.subject.next({ text: panelType, value: show });
    }

    getPanelMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    compareEnvs(env1: string, env2: string): number {
        const order = [
            String(EnvType.Development),
            String(EnvType.Devops),
            String(EnvType.Preprod),
            String(EnvType.Production),
        ];

        const index1 = order.indexOf(env1);
        const index2 = order.indexOf(env2);

        if (index1 < index2) {
            return -1;
        } else if (index1 > index2) {
            return 1;
        } else {
            return 0;
        }
    }
}
