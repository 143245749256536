import { TemplateTypes } from './email_template_types';

export class Template {
    header: string;
    footer: string;
    subject: string;
    body: string;
    registry: string;
    'registry_id#organization#site_id#template#language': string;
    template_type: TemplateTypes;

    constructor(
        header: string,
        footer: string,
        body: string,
        subject: string,
        registry: string = '',
        primary_key: string,
        email_template?: TemplateTypes
    ) {
        this.header = header;
        this.footer = footer;
        this.body = body;
        this.subject = subject;
        this.registry = registry;
        this['registry_id#organization#site_id#template#language'] = primary_key;
        this.template_type = email_template;
    }
}
