<div class="p-5" style="background-color: #fff">
    <div class="d-flex justify-content-end">
        <app-button (action)="toggleLayout()" [buttonText]="'Registry.ToggleLayout'" />
    </div>
    <div class="border my-3">
        <app-pi-form
            *ngIf="survey && survey.pages"
            [formDef]="survey"
            (formChanges)="onFormChanges($event)"
            [layout]="currentLayout"
            [progressType]="currentLayout === 'one-by-one' ? 'progress-bar' : 'none'"
        ></app-pi-form>
        <h3 class="mt-2">Value:</h3>
        <json-editor [options]="jsonEditorOptions" [data]="currentSurveyData"></json-editor>
    </div>
</div>
