<!-- delete template modal -->
<app-modal
    [saveBtnText]="'Proceed'"
    [closeBtnText]="Close"
    *ngIf="edit_master_email_modal"
    [(visible)]="edit_master_email_modal"
    [header]="'IMPORTANT'"
    [context]="this"
    [saveFunc]="masterEmailProceed"
    [closeFunc]="closeDeleteEditMasterEmailModal"
    [modalWidth]="'40vw'"
    [modalHeight]="'40vh'"
>
    <strong>Are you sure you want to edit this master email template?</strong>
    <br />
    <strong
        >Editing a master email template could be very dangerous! Ensure any edits are done with
        good conscious!</strong
    >
</app-modal>

<!-- preview template modal -->
<app-modal
    [closeBtnText]="'Close'"
    *ngIf="preview_html_modal"
    [(visible)]="preview_html_modal"
    [header]="'Preview'"
    [context]="this"
    [closeFunc]="closePreviewModal"
    [modalWidth]="'60vw'"
    [modalHeight]="'90vh'"
>
    <app-loading-component [loading]="loading">
        <div class="inner-html-container">
            <div [innerHTML]="preview_html"></div>
        </div>
    </app-loading-component>
</app-modal>

<!-- edit master email template modal -->
<app-modal
    [saveBtnText]="'Proceed'"
    [closeBtnText]="Close"
    *ngIf="delete_confirmation_modal"
    [(visible)]="delete_confirmation_modal"
    [header]="'Delete'"
    [context]="this"
    [saveFunc]="deleteTemplate"
    [closeFunc]="closeDeleteConfirmationModal"
    [modalWidth]="'40vw'"
    [modalHeight]="'40vh'"
>
    Are you sure you want to permanently delete this template?<br />
    <strong>Deleting an email template cannot be undone!</strong>
</app-modal>

<!-- delete template alert -->
<div
    class="alert alert-success fade"
    style="display: none; position: absolute; bottom: 10px; left: 0; right: 0; margin: auto"
    role="alert"
>
    Template successfully deleted.
</div>

<!-- credentials error modal -->
<app-modal
    [saveBtnText]="'Reload'"
    *ngIf="credentials_error_modal"
    [(visible)]="credentials_error_modal"
    [header]="'Error'"
    [context]="this"
    [saveFunc]="window.location.reload"
    [closeFunc]="closeDeleteConfimationModal"
    [modalWidth]="'40vw'"
    [modalHeight]="'30vh'"
>
</app-modal>

<!-- duplicate as modal -->
<app-modal
    [saveBtnText]="'Next'"
    *ngIf="duplicate_as_modal"
    [(visible)]="duplicate_as_modal"
    [header]="'Duplicate template'"
    [context]="this"
    [saveFunc]="duplicateCtaAction"
    [closeFunc]="closeDuplicateAsModal"
    [modalWidth]="'40vw'"
    [modalHeight]="'60vh'"
>
    <div class="form-group">
        <div>
            Please enter the name based on the following parameters:
            <ul>
                <li>First word: Registry</li>
                <li>Second word: Organization id (typically 'default')</li>
                <li>Third word: Site id</li>
                <li>Fourth word: Template Type (forgotpassword, activate, etc)</li>
            </ul>
            <strong>All words are separated by a hashtag '#'</strong>
        </div>
        <br />
        <label>Duplicating template: '{{ selected_template.name }}'</label>
        <input
            type="text"
            class="form-control"
            placeholder="{{
                selected_template['registry_id#organization#site_id#template#language']
            }}"
            [formControl]="form.get('duplicate_template')"
        />
        <small id="templateNameHelp" class="form-text text-muted">
            This name can be changed within the next edit page if you so wish before you save your
            template.
        </small>
    </div>
</app-modal>

<!-- delete group modal -->
<app-modal
    [saveBtnText]="'Next'"
    *ngIf="delete_group_modal"
    [(visible)]="delete_group_modal"
    [header]="'Delete Template Group'"
    [context]="this"
    [saveFunc]="deleteGroup"
    [closeFunc]="closeDeleteGroupModal"
    [modalWidth]="'40vw'"
    [modalHeight]="'50vh'"
    footerText="Make sure you've filled out all fields correctly, having a mistake could result in hundreds of deleted objects"
>
    <div class="form-group">
        <label>Group to Delete:</label>
        <select
            class="form-control"
            [formControl]="form.get('selected_duplication_group')"
            (change)="filterTemplates($event.target.value)"
        >
            <option selected disabled>select</option>
            <option *ngFor="let group of prefix_filters">
                {{ group }}
            </option>
        </select>
    </div>
</app-modal>

<!-- send email modal -->
<app-modal
    [saveBtnText]="'Send Email'"
    *ngIf="send_test_email_modal"
    [(visible)]="send_test_email_modal"
    [header]="'Send Test Email'"
    [context]="this"
    [saveFunc]="sendEmailSubmission"
    [closeFunc]="closeSendTestEmailModal"
    [modalWidth]="'40vw'"
    [modalHeight]="'60vh'"
>
    <div *ngIf="user_type === 'composer-admin' || user_type === 'composer-super-admin'">
        <small class="text-muted">
            Send your email template "{{ selected_template.name }}" to a single recipient email
            address. <br />(For testing purposes only!)
        </small>
        <form [formGroup]="form" (ngSubmit)="sendEmailSubmission(selected_template)">
            <div class="modal-body">
                <div class="form-group">
                    <label for="templateName">Source email address</label>
                    <small class="form-text text-muted float-right">
                        The source (from) email address
                    </small>
                    <input
                        type="email"
                        class="form-control"
                        placeholder="source email address"
                        [formControl]="form.get('template_source_email')"
                    />
                    <small class="form-text text-muted">
                        The domain you're sending from must already be verified in AWS defined
                        identities.
                        <a
                            href="https://console.aws.amazon.com/ses/home?region=us-east-1#verified-identities"
                            >AWS SES verified identities</a
                        >
                    </small>
                </div>

                <div class="form-group">
                    <label for="templateName">Recipient email address</label>
                    <small class="form-text text-muted float-right">
                        A csv of email addresses you'd like to send this template to
                    </small>
                    <input
                        type="email"
                        class="form-control"
                        placeholder="recipient email address"
                        [formControl]="form.get('template_destination_emails')"
                    />
                </div>

                <form [formGroup]="dynamic_replacement_form_group">
                    <div *ngIf="selected_template">
                        <div class="my-3">
                            <p class="m-0">Template replacement tags</p>
                            <small>
                                Specify any of your
                                {{ dynamic_replacement_variables.length }} implemented replacement
                                tag values here:
                            </small>
                        </div>

                        <div
                            *ngIf="
                                dynamic_replacement_variables &&
                                dynamic_replacement_variables.length
                            "
                        >
                            <div
                                *ngFor="
                                    let variable of dynamic_replacement_variables;
                                    let i = index
                                "
                                class="form-group"
                            >
                                <label>{{ variable }}</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    [formControlName]="variable"
                                    placeholder="value"
                                />
                            </div>
                        </div>

                        <div
                            *ngIf="dynamic_replacement_variables.length === 0"
                            class="text-center text-muted"
                        >
                            No replacement variables found.
                        </div>

                        <div *ngIf="error" class="text-danger" id="errorOutput">
                            {{ error }}
                        </div>
                    </div>
                </form>
            </div>
        </form>
    </div>
    <div *ngIf="user_type === 'composer'">
        <small class="text-muted"> This email will be sent to you for testing purposes </small>
    </div>
</app-modal>

<!-- Main code -->
<div class="text-center mt-3 mb-3">
    <h1>AWS Email Template Manager</h1>
</div>
<app-loading-component [loading]="loading">
    <div class="container reviewTemplates" *ngIf="!loading">
        <div class="row">
            <div class="col d-flex justify-content-start">
                <app-button
                    routerLink="modify-template"
                    class="mt-4 mx-3"
                    [buttonText]="'Registry.CreateNew'"
                />
            </div>
            <div class="col d-flex justify-content-end">
                <app-button
                    (action)="triggerDeleteGroupModal()"
                    class="mt-4 mx-3"
                    [buttonText]="'Registry.DeleteTemplateGroup'"
                />
            </div>
        </div>
        <div *ngIf="templates.length" class="ag-theme-quartz" style="width: 100%; margin-top: 15px">
            <app-table-component
                [rowData]="display_data"
                [columnDefs]="col_defs"
                [pagination]="true"
                [paginationPageSize]="50"
                (selectionChanged)="onSelectionChanged($event)"
            ></app-table-component>
        </div>
    </div>
</app-loading-component>
