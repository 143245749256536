import { Component, OnInit } from '@angular/core';
import { RegistryService } from '@h20-services/registry.service';
import { TaskService } from '@h20-services/task.service';
import { ITaskSet } from '@h20-services/models/tasks/ITaskSet';

@Component({
    selector: 'app-task-set',
    templateUrl: './task-set.component.html',
    styleUrls: ['./task-set.component.scss'],
})
export class TaskSetComponent implements OnInit {
    lstTaskActions = ['assignTask'];
    lstRegSites = [];

    lstRegistries = [];
    selectedRegistry: string;

    lstSites = [];
    selectedSite: string;

    lstTaskPrototypes = [];
    lstFilteredPrototypes = [];
    selectedPrototype: string;

    lstTaskSets = [];
    taskSetModel: ITaskSet;

    loadingRegistries = true;
    loadingTasks = true;

    createTaskSetToggle = false;
    viewTaskSetToggle = false;
    colDefs = [
        {
            headerName: 'ID',
            field: 'registry_id#site_id#id',
        },
        {
            headerName: 'Task Set Prototypes',
            field: 'tasks',
        },
        {
            headerName: 'Process Action',
            field: 'action',
        },
        {
            headerName: 'Process Data Prototypes',
            field: 'data',
        },
        {
            headerName: 'Actions',
            field: 'id',
            cellDataType: 'iconBtns',
            cellRendererParams: (params) => {
                return {
                    actions: [
                        {
                            isVisible: true,
                            label: 'View icon',
                            iconClass: 'fad fa-eye',
                            onClick: () => this.toggleEditMode(params.data),
                        },
                    ],
                };
            },
        },
    ];

    constructor(
        private reg_svc: RegistryService,
        private task_svc: TaskService
    ) {}

    ngOnInit(): void {
        const promises = [
            this.reg_svc
                .listRegistryAndSite()
                .toPromise()
                .then((res) => {
                    this.lstRegSites = res;
                    this.lstRegistries = [...new Set(res.map((reg) => reg.registry_key))];
                }),
            this.task_svc
                .listTaskPrototypes()
                .toPromise()
                .then((res) => {
                    for (const proto of res) {
                        try {
                            proto['nice_label'] = this.getText(JSON.parse(proto.label));
                        } catch (err) {
                            proto['nice_label'] = proto.label;
                        }
                    }
                    this.lstTaskPrototypes = res;
                }),
        ];

        Promise.all(promises).then((res) => {
            this.resetTaskSetModel();
            this.loadingRegistries = false;
        });
    }

    getText(strOrLangs): string {
        if (!strOrLangs) return '';
        return strOrLangs['default'] || strOrLangs['en'] || strOrLangs;
    }

    registryChanged(value: string) {
        this.selectedRegistry = value;
        this.lstSites = this.lstRegSites
            .filter((reg) => reg.registry_key === value)
            .map((reg) => reg.site_name);
    }

    siteChanged(value: string) {
        this.selectedSite = value;
        this.lstFilteredPrototypes = this.lstTaskPrototypes.filter((t) => t.site_ID === value);
    }

    resetTaskSetModel() {
        this.taskSetModel = {
            'registry_id#site_id#id': '',
            action: '',
            tasks: [],
            data: [],
        };
        this.selectedRegistry = this.selectedSite = undefined;
    }

    setActionChanged(value: string) {
        this.taskSetModel.action = value;
    }

    selectedTaskChanged(value: string) {
        this.selectedPrototype = value;
    }

    addTask() {
        this.taskSetModel.tasks.push(this.selectedPrototype);
    }

    removeTask(proto: string) {
        this.taskSetModel.tasks.splice(this.taskSetModel.tasks.indexOf(proto), 1);
    }

    addData() {
        this.taskSetModel.data.push(this.selectedPrototype);
    }

    removeData(proto: string) {
        this.taskSetModel.data.splice(this.taskSetModel.data.indexOf(proto), 1);
    }

    getProtoLabelByID(id: string): string {
        const proto = this.lstFilteredPrototypes.find((p) => p.id === id);
        return `${proto.site_ID} - ${proto.nice_label} - ${proto.type}`;
    }

    splitIDs(field: string) {
        return field.split('#');
    }

    toggleEditMode(row) {
        this.taskSetModel = {
            'registry_id#site_id#id': row['registry_id#site_id#id'],
            action: row.action,
            tasks: row.tasks,
            data: row.data,
        };

        const split = this.splitIDs(row['registry_id#site_id#id']);
        this.registryChanged(split[0]);
        this.siteChanged(split[1]);

        this.viewTaskSetToggle = true;
    }
}
