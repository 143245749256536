<div class="row mb-3">
    <div class="left-column">
        <h4 class="desc-heading">
            {{ 'UserSettings.NotificationPref' | translate }}
        </h4>
        <div class="desc-sub-heading">
            {{ 'UserSettings.NotificationPrefDesc' | translate }}
        </div>
    </div>
    <div class="right-column"></div>
</div>
