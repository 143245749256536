import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@h20-services/common.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { BaseTileComponent } from '../base-tile/base-tile.component';

@Component({
    selector: 'app-bar-tile',
    templateUrl: './bar-tile.component.html',
    styleUrls: ['./bar-tile.component.scss'],
})
export class BarTileComponent extends BaseTileComponent implements OnInit {
    @Input() tile;

    highcharts;
    chartOptions;

    constructor(
        com_svc: CommonService,
        private translate: TranslateService
    ) {
        super(com_svc);
    }

    ngOnInit(): void {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.reloadChart();
        });
        this.highcharts = Highcharts;
        this.reloadChart();
    }

    getText = this.com_svc.getText;

    reloadChart() {
        this.chartOptions = this.highcharts.Options = {
            chart: {
                type: 'bar',
            },
            title: {
                text: this.getText(this.tile.title),
                useHTML: true,
            },
            subtitle: {
                text: this.getText(this.tile.subTitle),
                useHTML: true,
            },
            colors: this.tile.colors || this.baseColours,
            xAxis: {
                categories: this.getCategories(this.tile.data),
                title: {
                    text: this.getText(this.tile.xAxisTitle),
                },
                labels: {
                    useHTML: true,
                    allowOverlap: true,
                    style: {
                        'word-wrap': 'normal',
                        textOverflow: 'allow',
                        width: '150',
                    },
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: this.getText(this.tile.yAxisTitle),
                },
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: this.tile.enableDataLabels,
                    },
                },
                series: {
                    stacking: 'normal',
                },
            },
            legend: {
                enabled: this.tile.legendEnabled,
            },
            tooltip: {
                pointFormat: this.getText(this.tile.yAxisTitle) + ': <b>{point.y}</b>',
                backgroundColor: '#FCFCFC',
                style: {
                    'z-index': 2, // This is having some problems with the axis labels showing up over the tooltip
                    position: 'relative',
                },
            },
            series: [{ colorByPoint: true, data: this.tile.data }],
            credits: {
                enabled: false,
            },
        };
    }

    getCategories(data) {
        return data.map((d) => {
            return d[0];
        });
    }
}
