<ng-template #logicTreeNode let-node>
    <div *ngIf="node">
        <div *ngIf="node.type === LogicNodeType.EXPRESSION">
            <button
                class="tiny-text btn btn-light p-1"
                (click)="logicTree.addTestWithLogic(ClauseLocation.BEFORE, node)"
            >
                <i class="fa-solid fa-regular fa-plus"></i>&nbsp;&nbsp;&nbsp;<i
                    class="fa-solid fa-dash"
                ></i
                ><i class="fa-solid fa-dash"></i>
            </button>
            <div class="row align-items-center">
                <div class="col-4">
                    <ng-select
                        [items]="parentList"
                        bindLabel="name"
                        [formControl]="node.elementControl"
                        class="pi-form-white p-1"
                        [searchFn]="elementSearchFn"
                    >
                        <ng-template ng-option-tmp let-item="item">
                            {{ item.name }} : <small>{{ item.title }}</small>
                        </ng-template>
                    </ng-select>
                    <app-validator [control]="node.elementControl"></app-validator>
                </div>
                <div class="col-2">
                    <app-pi-control-switch
                        [element]="compareOperatorFormElement"
                        [theFormControl]="node.operatorControl"
                        mode="edit"
                    >
                    </app-pi-control-switch>
                </div>
                <div class="col-6 d-flex">
                    <div class="pi-form-white p-1 w-100">
                        Value(s) of:
                        <app-pi-control-switch
                            *ngIf="node.elementControl.value"
                            [element]="node.elementControl.value"
                            [theFormControl]="node.valueControl"
                            [options]="node.options"
                            mode="edit"
                        >
                        </app-pi-control-switch>
                    </div>
                    <button
                        class="tiny-text btn btn-outline-danger p-1"
                        (click)="logicTree.removeClause(node)"
                    >
                        <i class="fa-solid fa-times"></i>
                    </button>
                </div>
            </div>

            <button
                class="tiny-text btn btn-light p-1"
                (click)="logicTree.addTestWithLogic(ClauseLocation.AFTER, node)"
            >
                <i class="fa-solid fa-regular fa-plus"></i>&nbsp;&nbsp;&nbsp;<i
                    class="fa-solid fa-dash"
                ></i
                ><i class="fa-solid fa-dash"></i>
            </button>
        </div>
        <div *ngIf="node.type === LogicNodeType.LOGIC">
            <ng-template
                [ngTemplateOutlet]="logicTreeNode"
                [ngTemplateOutletContext]="{ $implicit: node.left }"
            >
            </ng-template>

            <app-pi-control-switch
                [element]="logicOperatorFormElement"
                [theFormControl]="node.logicControl"
                mode="edit"
            >
            </app-pi-control-switch>

            <ng-template
                [ngTemplateOutlet]="logicTreeNode"
                [ngTemplateOutletContext]="{ $implicit: node.right }"
            >
            </ng-template>
        </div>
    </div>
</ng-template>

<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate
    ><div *ngIf="!disabled" class="row pi-control-form-mode">
        <div class="col-12 d-flex flex-row align-items-center">
            <app-element-title [element]="element"></app-element-title>
            <div class="pi-form-white ms-2 px-3">
                {{ stringValue }}
            </div>
            <button class="btn btn-light ms-2 tw-bold fs-4" (click)="onEdit()">
                <i class="fa-solid fa-pencil"></i>
            </button>
        </div>
        <app-modal
            [context]="this"
            [(visible)]="editingModal"
            header="Logic Editor"
            [saveFunc]="onSave"
            [closeFunc]="onClose"
            [saveBtnText]="'OK'"
        >
            <form *ngIf="logicForm" [formGroup]="logicForm">
                <ng-template
                    *ngIf="logicTree"
                    [ngTemplateOutlet]="logicTreeNode"
                    [ngTemplateOutletContext]="{ $implicit: logicTree.root }"
                >
                </ng-template>
                <div *ngIf="logicTree && logicTree.isEmpty()">
                    <button class="tiny-text btn btn-light p-1" (click)="logicTree.addFirstTest()">
                        <i class="fa-solid fa-regular fa-plus"></i>&nbsp;&nbsp;&nbsp;<i
                            class="fa-solid fa-dash"
                        ></i
                        ><i class="fa-solid fa-dash"></i>
                    </button>
                </div>
            </form>
        </app-modal>
    </div>
</ng-template>
