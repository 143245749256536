<div class="d-flex flex-row w-100 pb-3 border-bottom pi-form-progress-bar">
    <div
        *ngFor="let panel of progressBar"
        class="d-flex flex-column flex-grow-1 align-items-center px-2"
    >
        <div class="d-flex flex-row w-100 pi-form-progress-bar">
            <ng-container *ngFor="let el of panel.elements; first as isFirst; last as isLast">
                <div *ngIf="el.enabled" class="flex-grow-1">
                    <div
                        class="progress-indicator d-flex justify-content-center"
                        aria-label="Progress indicator"
                    >
                        <div
                            *ngIf="activeElement && el.name === activeElement.name"
                            class="progress-here"
                            aria-label="Current Active Question"
                        ></div>
                    </div>
                    <div
                        [ngClass]="{
                            'pi-form-progress-bar-error': el.progressState === 'error',
                            'pi-form-progress-bar-info': el.progressState === 'info',
                            'pi-form-progress-bar-empty': el.progressState === 'empty',
                            'pi-form-progress-bar-complete': el.progressState === 'complete',
                        }"
                        class="pi-form-progress-bar-unit w-100 d-flex justify-content-center align-items-center"
                        [title]="el.text"
                        (click)="setActiveElement(el.name)"
                        [id]="'progress-' + el.name"
                    >
                        <span
                            *ngIf="el.progressState === 'info'"
                            class="fas fa-info text-white"
                        ></span>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="section-title">{{ getText(panel.name) }}</div>
    </div>
</div>
