import { Component, Injector, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { IFormNodeman } from '@h20-services/models/forms/types/IFormNodeman';
import { INodeman } from '@h20-services/models/INodeman';
import { TranslateService } from '@ngx-translate/core';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { UuidService } from '@h20-services/uuid.service';

@Component({
    selector: 'app-pi-control-nodeperson',
    templateUrl: './pi-control-nodeperson.component.html',
    styleUrls: ['./pi-control-nodeperson.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlNodepersonComponent,
        },
    ],
})
export class PiControlNodepersonComponent
    extends AbstractPiControlComponent<IFormNodeman, any>
    implements OnInit
{
    currentElement: any;

    highlightedColour = '#2e3192';
    highlightedStroke = '6';
    _Sites: any;
    _SiteFindingsJson: any;
    nodeManInit = false;
    region = '';
    id: any;
    imgSrc = 'https://h20-development-web-assets.s3.amazonaws.com/files/lymph_node_sites_V9.svg';
    SVG = null;
    nodeModal;
    _Sites_coloursArr = [];
    modalForm: any;
    showModal;
    currentNode: INodeman;

    //TODO BROKEN CLASS

    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        protected translate: TranslateService,
        private renderer2: Renderer2,
        private fb: UntypedFormBuilder
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    ngOnInit(): void {
        this.modalForm = this.fb.group({
            size: [0],
            fdgAv: [0],
            found: [false],
            assessed: [false],
        });
    }

    svgLoaded() {
        //this.loadSvgCallback();
    }

    loadSvgCallback() {
        let nodemanSVG = <HTMLObjectElement>document.getElementById('nodeperson');

        this.SVG = nodemanSVG.contentDocument;

        var siteGroup = this.SVG.getElementById('sites');
        this._Sites = siteGroup.getElementsByTagName('path');

        for (let index = 0; index < this._Sites.length; index) {
            var pathFillColor = this._Sites[index].style.fill;

            var site_color_obj = {
                id: this._Sites[index].id,
                colour: pathFillColor,
            };

            this._Sites_coloursArr.push(site_color_obj);
            this._Sites[index].style.fill = 'transparent';
            this.renderer2.listen(this._Sites[index], 'click', (event) => {
                this.region = event.srcElement.id;
                this.nodeClick(event.srcElement.id);
            });
        }

        // if (_SiteFindingsJson.length) {
        //     //Load all previous findings from DB in json format
        //     //loadSiteFindings(_SiteFindingsJson);
        // }

        // Update for TGT DH Updates July 2017 # 362
        //showBodyRegion();

        //activate and deactivate the regions based on the selected option
        //from region_of_the_body_where_the_imaging_was_performed
        // $('[name=region_of_the_body_where_the_imaging_was_performed]').on('change', function () {
        //reset NodeMand to initial state
        //resetNodeMan(_Sites);
        //showBodyRegion();
        // });
    }

    nodeClick(id) {
        if (this.currentElement) {
            this.currentElement.style.strokeWidth = 0;
        }
        // Get values from svg elements
        let element = this.SVG.getElementById(id);
        this.currentElement = element;

        var currentFillColor = element.style.fill;

        if (currentFillColor === 'rgba(0, 0, 0, 0)' || currentFillColor === 'transparent') {
            let colour = this.getColourById(id);
            element.style.fill = colour;
            element.style.stroke = this.highlightedColour;
            element.style.strokeWidth = this.highlightedStroke;
            //this.addFinding(id);
            this.showModal = true;
            this.modalForm = this.fb.group({
                size: [0],
                fdgAv: [0],
                found: [false],
                assessed: [false],
            });
        } else {
            // Highlight this li and associated site, remove all other highlighting
            //highlightSites(id);
            element.style.strokeWidth = this.highlightedStroke;
            return;
        }
    }

    getText = this.com_svc.getText;

    getColourById(id) {
        return this._Sites_coloursArr.find((element) => element.id === id)?.colour;
    }

    addFinding(id) {
        let newFinding: INodeman = { id: id, size: 0, fdgav: 0, found: false, assessed: false };

        this.element.nodes.push(newFinding);
    }

    closeModal() {
        this.showModal = false;
    }
}
