import { UserRole } from './role';
import { UserStatus } from './status';

// To Do: Make pulseAuth a singleton;
// The constructor is called more than once, meaning there could be more than one instance of pulseAuth in a session.
// User claims are occasionally updated during a session.
// If the class is not a singleton, the instances of pulseAuth created before the update will be unchanged.
// If claims are checked against the unchanged instances, they could be incorrect.
export class PulseAuth {
    user_id: string = '';
    site_ID: string = '';
    emailAddr: string = '';
    tableau_username = '';
    isLoggedIn: boolean = false;
    mfa_required: boolean = false;
    mfa_status = '';
    authenticationID = '';
    preferredName = '';
    preferred_language = '';
    preferred_time_format = '';
    preferred_date_format = '';
    organizationID = '';
    type: string = '';
    status: string = '';
    isPatientProxy: boolean = false;
    greenlight_patient_ID: string = '';
    registryKey: string = '';
    claims: any;
    participants: any[] = [];
    first_name: string;
    last_name: string;
    mobile_phone: number;
    street_address: string;
    date_of_birth: string;
    postal_code: string;
    gender: string;

    constructor(attributes, session) {
        if (session) {
            this.isLoggedIn = true;
            const userData = JSON.parse(session.tokens.idToken.payload.user_data);
            if (userData) {
                this.user_id = userData.id || '';
                this.site_ID = userData.site_ID || '';
                this.emailAddr = userData.email || userData.username || '';
                this.type = userData.type || '';
                this.authenticationID = userData.authentication_ID || '';
                this.organizationID = userData.organization_ID || '';
                this.status = userData.status || '';
                this.mfa_required = userData.mfa_required;
                this.mfa_status = userData.mfa_status || '';
                this.preferredName = userData.preferred_name;
                this.preferred_language = userData.preferred_language || '';
                this.preferred_date_format = userData?.preferred_date_format || '';
                this.preferred_time_format = userData?.preferred_time_format || '';
                this.isPatientProxy = userData.isPatientProxy;
                this.greenlight_patient_ID = userData.greenlight_patient_ID;
                this.registryKey = userData.registry_key;
                this.first_name = userData.first_name;
                this.last_name = userData.last_name;
                this.mobile_phone = userData.mobile_phone;
                this.street_address = userData.street_address;
                this.date_of_birth = userData.date_of_birth;
                this.postal_code = userData.postal_code;
                this.gender = userData.gender;
            }

            if (!this.preferredName) this.preferredName = session.token.idToken.payload?.nickname;
            if (attributes) this.tableau_username = attributes?.['custom:tableau_username'] || '';
            this.claims = JSON.parse(session.tokens.idToken.payload.user_claims);
            this.participants = JSON.parse(session.tokens.idToken.payload.user_participants);
        }
    }

    public getUserID(): string {
        return this.user_id;
    }

    public getSiteID(): string {
        return this.site_ID;
    }

    public getEmailAddr(): string {
        return this.emailAddr;
    }

    public setEmailAddr(addr): PulseAuth {
        this.emailAddr = addr || '';
        return this;
    }

    public getTableauUsername(): string {
        return this.tableau_username;
    }

    public doesSiteMatch(s: string): boolean {
        return s == this.site_ID;
    }

    public getType(): any {
        return this.type;
    }

    public isType(t: UserRole): boolean {
        return this.type == t;
    }

    public getAuthenticationID(): string {
        return this.authenticationID;
    }

    public getPreferredName(): string {
        return this.preferredName;
    }

    public getOrganizationID(): string {
        return this.organizationID;
    }

    public getStatus(): string {
        return this.status;
    }

    public isStatusInArray(ary: UserStatus[]): boolean {
        if (!ary) return false;
        for (const us of ary) {
            if (this.status == us) return true;
        }
        return false;
    }

    public isTypeInArray(ary: UserRole[]): boolean {
        if (!ary) return false;
        for (const urs of ary) {
            if (this.type == urs) return true;
        }
        return false;
    }

    public isBothPatientAndProxy(): boolean {
        return this.isPatientProxy;
    }

    public getMFARequired(): boolean {
        return this.mfa_required;
    }

    public getMFAStatus(): string {
        return this.mfa_status;
    }

    public getGreenlightPatientId(): string {
        return this.greenlight_patient_ID;
    }

    public getRegistryKey(): string {
        return this.registryKey;
    }

    public getParticipants(): any[] {
        return this.participants;
    }

    public getClaims(): any {
        return this.claims;
    }

    public getPreferredTimeFormat() {
        return this.preferred_time_format;
    }
    public getPreferredDateFormat() {
        return this.preferred_date_format;
    }

    public static NotLoggedIn: PulseAuth = new PulseAuth(null, null);
    public getUserDetails() {
        return {
            firstName: this.first_name,
            lastName: this.last_name,
            mobile: this.mobile_phone,
            streetAddress: this.street_address,
            postalCode: this.postal_code,
            dob: this.date_of_birth,
            gender: this.gender,
        };
    }

    public getPreferredLang() {
        return this.preferred_language;
    }
}
