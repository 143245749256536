import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { IFormChoice } from '@h20-services/models/forms/IFormChoice';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { RequestService } from '@h20-services/request.service';
import { TranslateService } from '@ngx-translate/core';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { UuidService } from '@h20-services/uuid.service';

@Component({
    selector: 'app-pi-control-radiogroup',
    templateUrl: './pi-control-radiogroup.component.html',
    styleUrls: ['./pi-control-radiogroup.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlRadiogroupComponent,
        },
    ],
})
export class PiControlRadiogroupComponent
    extends AbstractPiControlComponent<IFormElement, any>
    implements OnInit
{
    loading = false;

    tooltipOptions = {
        pointerEvents: 'auto',
        placement: 'right',
        'show-delay': 500,
        'hide-delay': 500,
        maxWidth: '500px', // Default max width is 200px
        zIndex: 10021, // Nav bar is 1020, so just need to be over that //modal is at 10000
    };
    getText = this.com_svc.getText;

    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        protected translate: TranslateService,
        protected req_svc: RequestService
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.element.hasOwnProperty('noChoicesError')) {
            this.formControl.addValidators(this.noChoicesValidator);
            this.formControl.markAsTouched();
        }
    }

    setStringValue() {
        const choice = this.element.choices?.find((c) => c.value == this._value);
        this.stringValue = this.getText(choice?.text);
    }

    selectionMade(e: Event, choice: IFormChoice) {
        //prevents normal updates from a radio selection (was calling this method twice)
        //  e.preventDefault();

        this.markAsTouched();
        // if (this._value === choice.value) this.writeValue(null);
        // else this.writeValue(choice.value);
        this.writeValue(choice.value);
        this.notifyValueChange();
    }

    noChoicesValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            //always returns
            return { noChoices: { value: this.translate.instant('Surveys.PleaseCloseForm') } };
        };
    }
}
