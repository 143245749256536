import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { CommonService } from './common.service';
import { ConfigService } from './config.service';
import { environment as env } from '@environment/environment';
import { Observable } from 'rxjs';
import { PulseAuth } from './models/PulseAuth';
import {
    IGetDashboardConfigParams,
    IGetDashboardConfigRequest,
    IDashboardConfigData,
} from './models/config/IDashboardConfig';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    constructor(
        private reqSvc: RequestService,
        private commonSvc: CommonService,
        private configSvc: ConfigService
    ) {}

    listDashboards(envName?: any): any {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        const req: any = {
            operation: 'listDashboards',
        };
        return this.reqSvc.post(targetEnv.API.writeToDBDashboard, req, targetEnv);
    }

    listHighchartsDashboardsByRole(
        registryId: string,
        siteId: string,
        role: string,
        organizationId: string = 'default',
        envName?: any
    ): any {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        const req: any = {
            registryId: registryId,
            siteId: siteId,
            role: role,
            organizationId: organizationId || 'default',
            operation: 'listHighchartsDashboardsByRole',
        };
        return this.reqSvc.post(targetEnv.API.writeToDBDashboard, req, targetEnv);
    }

    saveHighchartsDashboard(dashboard, envName): any {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        const req: any = {
            operation: 'saveDashboardConfig',
            dashboard: dashboard,
        };
        return this.reqSvc.post(targetEnv.API.writeToDBDashboard, req, targetEnv);
    }

    getTableauConfig(pulseAuth: PulseAuth): Observable<any> {
        var dataToSend = {
            registryId: this.configSvc.getTenant(),
            siteId: pulseAuth.getSiteID(),
            role: pulseAuth.getType(),
            organizationId: pulseAuth.getOrganizationID() || 'default',
            email: pulseAuth.getTableauUsername(),
            operation: 'getTableauConfig',
        };
        return this.reqSvc.post(env.API.writeToDBDashboard, dataToSend);
    }

    getTableauDashboardsByRole(
        registryId: string,
        siteId: string,
        role: string,
        organizationId: string = 'default'
    ): Observable<any> {
        const dataToSend = {
            registryId: registryId,
            siteId: siteId,
            role: role,
            organizationId: organizationId || 'default',
            operation: 'listTableauDashboardsByRole',
        };
        return this.reqSvc.post(env.API.writeToDBDashboard, dataToSend);
    }

    saveTableauConfig(tableauConfig, envName?: any) {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        const dataToSend = {
            tableauConfig: tableauConfig,
            operation: 'saveTableauConfig',
        };
        return this.reqSvc.post(targetEnv.API.dashboardConfiguration, dataToSend, targetEnv);
    }

    saveHighchartsConfig(highchartsConfig, envName?: any): any {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        const dataToSend = {
            highchartsConfig: highchartsConfig,
            operation: 'saveHighchartsConfig',
        };
        return this.reqSvc.post(targetEnv.API.dashboardConfiguration, dataToSend, targetEnv);
    }

    getDashboardConfig(
        { registry, site_ID, type, organization_ID, email }: IGetDashboardConfigParams,
        envName?: any
    ): Observable<IDashboardConfigData> {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        const dataToSend: IGetDashboardConfigRequest = {
            operation: 'getDashboardConfig',
            registry: registry,
            site_ID: site_ID,
            role: type,
            organization: organization_ID || 'default',
            email: email,
        };

        return this.reqSvc.post(targetEnv.API.dashboardConfiguration, dataToSend, targetEnv);
    }

    listDashboardConfigs(envName?: any): Observable<IDashboardConfigData[]> {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        const dataToSend: any = {
            operation: 'listDashboardConfigs',
        };
        return this.reqSvc.post(targetEnv.API.dashboardConfiguration, dataToSend, targetEnv);
    }
}
