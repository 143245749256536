<div class="dropdown-wrapper">
    <label for="dropdown-input" class="dropdown-label">Team member</label>
    <div class="dropdown-container" (click)="toggleDropdown()">
        <!-- Dropdown input area -->
        <div class="dropdown-input" [ngClass]="{ active: dropdownOpen }">
            <!-- Placeholder for when no items are selected -->
            <span *ngIf="selectedItems.length === 0" class="placeholder">Select items</span>

            <!-- Selected items as tags (if any) -->
            <ng-container *ngIf="selectedItems.length > 0">
                <span *ngFor="let selectedItem of selectedItems" class="selected-tag">
                    {{ selectedItem.label }}
                    <span
                        class="remove-tag"
                        (click)="removeItem(selectedItem); $event.stopPropagation()"
                        >x</span
                    >
                </span>
            </ng-container>

            <!-- Arrow icon positioned on the right -->
            <i class="arrow" [ngClass]="{ up: dropdownOpen, down: !dropdownOpen }"></i>
        </div>

        <!-- Dropdown list (displayed when open) -->
        <div class="dropdown-list" *ngIf="dropdownOpen" [ngClass]="{ scrollable: scrollable }">
            <input
                *ngIf="enableSearch"
                class="search-input"
                type="text"
                [(ngModel)]="searchQuery"
                placeholder="Search..."
                (click)="$event.stopPropagation()"
            />
            <div
                *ngFor="let item of filteredItems"
                class="dropdown-item"
                (click)="toggleSelection(item); $event.stopPropagation()"
            >
                <span>{{ item.label }} @{{ item.username }}</span>
                <i *ngIf="selectedItems.includes(item)" class="checkmark">&#10003;</i>
            </div>
        </div>
    </div>
</div>
