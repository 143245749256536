import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CommonService } from '@h20-services/common.service';

@Component({
    selector: 'app-rtv-management-section',
    templateUrl: './rtv-management-section.component.html',
    styleUrls: ['./rtv-management-section.component.scss'],
})
export class RtvManagementSectionComponent implements OnInit, OnChanges {
    constructor(
        private http: HttpClient,
        private common: CommonService
    ) {}
    @Output() elementChange = new EventEmitter<{}>();

    @Input() survey: any;
    surveyPanels: any;
    formForm: any;
    panel: any;
    panelIndex: any = 0;
    adding: boolean = false;
    addingBefore: boolean = false;
    addingAt: number = 0;
    addingForm: any;
    currentEditor: number = 0;
    ELEMENT_TYPES = [
        {
            icon: 'fa-circle-dot',
            color: 'color-1',
            text: 'radiogroup',
            displayText: 'Radiogroup',
        },
        { icon: 'fa-check', color: 'color-1', text: 'checkbox', displayText: 'Checkbox' },
        { icon: 'fa-text', color: 'color-2', text: 'text', displayText: 'Text' },
        { icon: 'fa-9', color: 'color-2', text: 'number', displayText: 'Number' },
        { icon: 'fa-phone', color: 'color-2', text: 'phone', displayText: 'Phone' },

        {
            icon: 'fa-calendar-range',
            color: 'color-2',
            text: 'partial-date',
            displayText: 'Partial Date',
        },
        { icon: 'fa-calendar-day', color: 'color-2', text: 'date', displayText: 'Date' },
        {
            icon: 'fa-calendar-days',
            color: 'color-2',
            text: 'datepicker',
            displayText: 'Datepicker',
        },
        { icon: 'fa-timer', color: 'color-2', text: 'time', displayText: 'Time' },

        {
            icon: 'fa-chevron-down',
            color: 'color-1',
            text: 'dropdown',
            displayText: 'Dropdown',
        },
        { icon: 'fa-at', color: 'color-2', text: 'email', displayText: 'Email' },

        { icon: 'fa-comment', color: 'color-2', text: 'comment', displayText: 'Comment' },

        { icon: 'fa-code', color: 'color-5', text: 'html', displayText: 'HTML' },

        {
            icon: 'fa-ellipsis-stroke',
            color: 'color-2',
            text: 'multipletext',
            displayText: 'Multiple Text',
        },
        {
            icon: 'fa-map',
            color: 'color-3',
            text: 'gesture-map-input',
            displayText: 'Gesture Map',
        },
        { icon: 'fa-map-pin', color: 'color-3', text: 'gesture', displayText: 'Gesture' },
        {
            icon: 'fa-arrow-up-from-bracket',
            color: 'color-3',
            text: 'file',
            displayText: 'File Upload',
        },

        {
            icon: 'fa-link',
            color: 'color-5',
            text: 'link-generator',
            displayText: 'Survey Link Generator',
        },
        { icon: 'fa-star', color: 'color-1', text: 'rating', displayText: 'Rating' },
        { icon: 'fa-slider', color: 'color-2', text: 'slider', displayText: 'Slider' },
        {
            icon: 'fa-square-list',
            color: 'color-4',
            text: 'paneldynamic',
            displayText: 'Dynamic Panel',
        },
    ];
    navExpanded: boolean = true;
    ngOnInit(): void {
        this.http.get('assets/survey-builder/element-forms/form.json').subscribe((data) => {
            this.formForm = data;
        });
    }

    ngOnChanges(): void {
        if (this.survey && this.survey.pages) this.surveyPanels = this.survey.pages[0].elements;
    }

    onFormChanges(event: any) {
        this.survey = Object.assign(this.survey, event.value);
    }

    addPanel() {
        let newPanel = {
            type: 'panel',
            elements: [],
        };
        this.survey.pages[0].elements.push(newPanel);
    }

    getColor(element) {
        let type = element.inputType ? element.inputType : element.type;
        return this.ELEMENT_TYPES.find((el) => {
            return el.text === type;
        })?.color;
    }

    getIcon(element) {
        let type = element.inputType ? element.inputType : element.type;
        return this.ELEMENT_TYPES.find((el) => {
            return el.text === type;
        })?.icon;
    }

    addAfter(index, panelIndex) {
        this.panelIndex = panelIndex;
        this.adding = true;
        this.addingAt = index;
        this.addingBefore = false;
    }

    clearAdding() {
        this.adding = false;
        this.addingAt = 0;
        this.addingBefore = false;
        return true;
    }

    getText = this.common.getText;

    changeEditor(newIndex: number, panelIndex) {
        this.currentEditor = newIndex;
        this.panelIndex = panelIndex;
        const panelInd = this.panelIndex;
        this.elementChange.emit({ newIndex, panelInd });
    }

    expandedControl(panelInd, panel) {
        this.panelIndex = panelInd;
    }

    confirmAdd(type: string) {
        let newElement = { type: type };
        if (
            type === 'number' ||
            type === 'date' ||
            type === 'email' ||
            type === 'slider' ||
            type === 'time'
        ) {
            newElement.type = 'text';
            newElement['inputType'] = type;
        } else if (type === 'paneldynamic') {
            newElement['templateElements'] = [];
        } else if (type === 'checkbox') {
            newElement['choices'] = [];
        }
        newElement['name'] = '';
        newElement['title'] = '';
        let addIndex = this.addingBefore ? this.addingAt : this.addingAt;

        this.survey.pages[0].elements[this.panelIndex].elements.splice(addIndex, 0, newElement);
        const panelInd = this.panelIndex;
        const newIndex = addIndex;
        this.elementChange.emit({ newIndex, panelInd });
        this.clearAdding();
    }
}
